//Components
import { encryptionAPI } from '../../utils/Encryption';
import { APIURL } from './ApiEndPoints';
import { IsEncryption } from '../../utils/AppSetting'
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { isAuthorized, getTokenProps } from "../../utils/AuthenticationLibrary";
import { axiosInstance, axiosInstanceWithoutEnrypt } from '../../utils/AxiosInterceptor';

export function axiosPost(url, request) {
    var data = { data: (IsEncryption) ? encryptionAPI(request, 1) : request };
    return axiosInstance.post(APIURL + url, data);
}

// API functions

export function axiosPostAuthorize(url, request, isFormData) {
    if (isAuthorized()) {
        var data = { data: (IsEncryption) ? encryptionAPI(request, 1) : request };
        let loginUser = getAuthProps();
        if (isFormData) {
            let headers = {
                Authorization: `Bearer ${loginUser.token.token}`,

                'content-type': 'multipart/form-data',
            };
            return axiosInstance.post(APIURL + url, request, { headers });
        }
        let headers = { Authorization: `Bearer ${loginUser.token.token}` };

        return axiosInstance.post(APIURL + url, data, { headers });
    }
    else {
        window.location.href = "/login";
    }
}

export function axiosGetAuthorize(url, param) {
    let loginUser = getAuthProps();
    if (loginUser) {
        let headers = { Authorization: `Bearer ${loginUser.token.token}` };
        return axiosInstance.get(APIURL + url.replace("{0}", (IsEncryption) ? encryptionAPI(param, 0) : param), { headers });
    }
    else {
        window.location.href = "/";
    }
}

export function axiosGet(url, param) {
    return axiosInstance.get(APIURL + url.replace("{0}", (IsEncryption) ? encryptionAPI(param, 0) : param));
}

export function axiosGetMultiParams(url, params) {
    params.forEach((value, key) => {
        url = url.replace("{" + key + "}", (IsEncryption) ? encryptionAPI(value, 0) : value)
    });
    return axiosInstance.get(APIURL + url);
}
export function axiosPostFileAuthorizeblob(url, request, isFormData) {
    let loginUser = getTokenProps();
    if (loginUser) {

        var data = { data: (IsEncryption) ? encryptionAPI(request, 1) : request };
        // let tokenProp = getTokenProps();
        // let abc = 'Bearer ' + tokenProp.data;
        let headers = { Authorization: `Bearer ${loginUser.token.token}` };

        return axiosInstanceWithoutEnrypt.post(APIURL + url, data, { headers, responseType: 'blob' });
    } else {
        window.location.href = "/";
    }
}

export function axiosGetMultiParamsFileAuthorizeblob(url, params) {
    if (isAuthorized()) {
        let tokenProp = getTokenProps();
        let headers = { Authorization: `Bearer ${tokenProp.token}` };
        params.forEach((value, key) => {
            url = url.replace("{" + key + "}", (IsEncryption) ? encryptionAPI(value, 0) : value);
        });
        return axiosInstanceWithoutEnrypt.get(APIURL + url, { headers, responseType: 'blob' });
    }
    else {
        window.location.href = "/";
    }
}


export function axiosGetMultiParamsAuthorize(url, params) {
    if (isAuthorized()) {
        let tokenProp = getTokenProps();
        let headers = { Authorization: `Bearer ${tokenProp.token}` };
        params.forEach((value, key) => {
            url = url.replace("{" + key + "}", (IsEncryption) ? encryptionAPI(value, 0) : value);
        });
        return axiosInstance.get(APIURL + url, { headers });
    }
    else {
        window.location.href = "/";
    }
}
