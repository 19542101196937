//Components
import { axiosPost } from '../AxiosRequests';
import { AdminLogin } from '../ApiEndPoints';

export default class AuthenticationServices {
    // API function
    async adminLogin(request) {
        return axiosPost(AdminLogin, request);
    }
}
