import BaseDomainService from "./baseDomainServices";
import moment from 'moment';
import { DateFromat } from "../../utils/dateFormat";

export default class AdminuserService extends BaseDomainService {

    getAdminList = this.apiService.manageUserServices.admin_getAdminUsers;

    AdminListgridColmns = [
        { name: "userName", title: "Email Address" },
        { name: "userActivationDate", title: "  User Activation Date " },
        { name: "userLastLoginTime", title: "User Last Login Time" },
    ]

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetAdminList.bind(this);
    }


    //** API Calls

    onGetAdminList = (requst) => {
        this.apiService.onAPICall("getAdminrequest", this.getAdminList, requst, this.onAPIResponse, this.onUpdateState);
    };

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "getAdminrequest":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.userActivationDate = i.userActivationDate ? DateFromat(i.userActivationDate) : "-";
                        i.userLastLoginTime = i.userLastLoginTime ? DateFromat(i.userLastLoginTime) : "-";
                    });
                }
                this.onUpdateState({
                    getAdminUsers: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false,
                });
                break;
        }

    };


















}

