import SwalServices from '../services/swalServices/SwalServices';

//**

// 
export default class SererviceManager {

    swalServices = new SwalServices();

    constructor() { }

}

