/* Component  */
import React, { Component } from 'react';
import ContactusResponse from '../../components/Contactus/ContactusResponse'
import Button from '../../components/common/Button';
import { Navigate } from 'react-router-dom';

/* Library  */
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import { ButtonTypes } from '../../utils/Constants';
import { ErrorMessage } from '../../utils/Messages';

/* Services  */
import ContactUsServices from '../../services/axiosServices/apiServices/ContactUsServices';
import SwalServices from '../../services/swalServices/SwalServices';

export class ContactUsResponse extends Component {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        this.getContactUsServices = new ContactUsServices();
        this.swalServices = new SwalServices();
        this.state = {
            setSidebarOpen: false,
            setSelectedItems: [],
            setContactUsResponseModel: false,
            getContactus: [],
            searchingText: "",
            totalCount: [],
            isLoading: false,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            contactUsDetails: {
                responseBody: "", toEmail: "", ccEmail: ""
            },
            isBtnLoading: false,
            contactResponse: {
                responseEmailBody: ""
            },
            contactResponseTwo: {
                responseEmailBodyTwo: "responseEmailBodyTwo"
            }
        }
        this.onChange = this.onChange.bind(this)
    }
    // API fuction 
    getContactUsById = (contactUsid) => {
        this.setState({ isLoading: true });
        this.getContactUsServices.admin_GetContactUsById(contactUsid).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {

                this.setState({ contactResponse: response.responseItem.responseContent });
                this.setState({ contactUsDetails: response.responseItem.responseContent });
                let newResponse = { ...this.state.contactResponse, responseEmailBody: "", toEmail: "", ccEmail: "" };
                this.setState({ contactResponse: newResponse });
            }
            else {
                this.swalServices.Toaster(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
        })
    }

    addContactUsResponse = () => {

        this.setState({ isLoading: true });
        this.getContactUsServices.admin_AddContactUsResponse(this.state.contactResponse).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ contactUsDetails: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Toaster(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
        })
    }


    // Other function 

    validateField = (key) => {
        const newValidState = validate(key, this.state.importFile, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.importFile, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    componentDidMount = () => {
        let params = new URLSearchParams(window.location.search);
        let contactUsId = params.get("");
        this.getContactUsById(contactUsId);
        document.title = 'Contact-Us Details';
    }

    handleContactModal = (value) => {
        this.setState({ setContactUsResponseModel: value });
    }

    back = () => {
        this.setState({ redirect: "/ContactUs" });
    }

    // Event Function 
    handleEmailIdsResponse = (event) => {
        let newDetails = this.state.contactResponse;
        let { name, value } = event.target;
        newDetails[name] = value;
        this.setState({ contactResponse: newDetails });
    }

    handleMessageResponse = (event) => {
        let newDetails = this.state.contactResponse;
        var newContent = event.editor.getData();
        newDetails["responseEmailBody"] = newContent;
        this.setState({ contactResponse: { ...newDetails } });
    }
    onChange(event) {
    }


    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div>
                <div className="flex h-screen overflow-hidden">
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
                        <main>
                            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                <Button type={ButtonTypes.Cancel} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.back()} />
                                <ContactusResponse contactUsDetails={this.state.contactUsDetails}
                                    getContactUsById={this.getContactUsById.bind(this)}
                                    handleContactModal={this.handleContactModal.bind(this)}
                                    addContactUsResponse={this.addContactUsResponse.bind(this)}
                                    isBtnLoading={this.state.isBtnLoading}
                                    contactResponse={this.state.contactResponse}
                                    contactResponseTwo={this.state.contactResponseTwo}
                                    handleEmailIdsResponse={this.handleEmailIdsResponse.bind(this)}
                                    handleMessageResponse={this.handleMessageResponse.bind(this)}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactUsResponse