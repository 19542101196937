//** Componeent  */
import React from 'react';

//** Services */
import DataImportService from '../../services/domainServices/dataImportService';

//*** LIb */
import DataImportDetails from './DataImportDetails';
import BaseComponent from '../../shared/baseComponent';

export default class DataImport extends BaseComponent {

  //** Constructor  and Component method */
  constructor(props) {
    super(props)
    this.dataImportService = new DataImportService();
    this.state = {
      importDetails: {},
      setIsLoad: false
    }
    this.dataImportService.onUpdateState = this.updateState.bind(this);
  }
  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let ImportId = params.get("")
    this.dataImportService.onGetDetails(ImportId);
  }

  render() {
    
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
              <DataImportDetails
                importDetails={this.state.importDetails}
                isLoading={this.state.setIsLoad} />
            </div>
          </main>
        </div>
      </div>
    )
  }
}