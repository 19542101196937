import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



function PieChart(props) {

  const labels = props.data?.map(entry => entry.label) ?? [];
  const dataValues = props.data?.map(entry => entry.data) ?? [];

  const options = {
    responsive: true,
    elements: {
        bar: {
            borderWidth: 3,
        },
        color:"black",
    },

    plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              size: 15,
            },
            color: "black",
          },
        },
        title: {
          display: true,
          text: "Pie Chart",
          font: {
            size: 15,
          },
          color: "black",
        },
      },
    // canvas: {
    //   // height: props.height || null, // Set canvas height dynamically or use null for default
    //   // width: props.size || null,   // Set canvas width dynamically or use null for default
    // },
};

  const data = {
    labels,
    datasets: [
      {
        label: props.title,
        data: dataValues,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (
    <div className="radar-chart-sec">
      <Pie data={data} options={options}
      // width={props.size}

      />
    </div>
  );
}

export default PieChart;
