import React, { useState } from "react";
import ComponentWizardServices from "../../../src/services/axiosServices/apiServices/ComponetWizardServices";
import moment from "moment";
import { saveAs } from "file-saver";

const Table = ({
  data: { data },
  size,
  height,
  title,
  selectedDate,
  configurationId,
  componentWizardId,
}) => {
  // Parse the incoming data prop
  const parsedData = JSON.parse(data);
  // Extracting unique keys without duplicates from all objects in the data array
  const label = Array.from(new Set(parsedData.flatMap(Object.keys)));

  let colSpan;

  switch (size) {
    case 1:
      colSpan = "4";
      break;
    case 2:
      colSpan = "6";
      break;
    case 3:
      colSpan = "12";
      break;
    default:
      colSpan = "4"; // Default value if size doesn't match any case
  }

  // State to control the tooltip visibility
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const downloadlist = new ComponentWizardServices();

  const exportFile = () => {
    let req = [
      configurationId,
      moment(new Date(selectedDate)).format("YYYY-MM-DD hh:mm:ss"),
      componentWizardId,
    ];
    downloadlist.exportGetComponentWizardsList(req).then((res) => {
      var formattedDate = moment(new Date()).format("YYMMDD");
      var filename = "Export Data" + formattedDate;
      var blob = new Blob([res.data]);
      saveAs(blob, filename + ".xlsx");
    });
  };

  return (
    <div
      className={`col-span-${colSpan} ${colSpan === "4" ? "table-4-height" : ""
        } shadow rounded-lg center-div Dashboard-card-count table-height-fix`}
    // style={{ height: "403px", overflowY: "auto" }}
    >
      <caption className="bg-sidebar p-2 bg-[#0f1e35]">
        <span className="text-[#fff] flex justify-start items-center tab-title">
          {title}
          <i
            className="fas fa-download ml-2"
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            onClick={() => exportFile()}
          >
            {tooltipVisible && (
              <div className="absolute bg-black text-white p-1 rounded-md mt-3 xlsx-hover">
                Download
              </div>
            )}
          </i>
        </span>
      </caption>
      <div className="table-sec">
        {parsedData.length > 0 ? (
          <table className="min-w-full bg-white overflow-hidden">
            <thead className="min-w-full bg-white overflow-hidden">
              <tr>
                {label.map((item, index) => (
                  <th
                    key={index}
                    className="py-2 px-4 text-left font-bold text-gray-700 text-[15px]"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-y-auto">
              {parsedData.map((item, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={rowIndex % 2 === 0 ? "bg-gray-100" : ""}
                >
                  {label.map((key, colIndex) => (
                    <td
                      key={colIndex}
                      className="py-2 px-4 text-gray-800 text-[14px]"
                    >
                      {item[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="col-span-12 text-center mt-5">
            <h1 style={{ fontSize: "28px" }}>No Record Found.</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
