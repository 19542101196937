import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChart(props) {
  const options = {
    responsive: true,
    elements: {
        bar: {
            borderWidth: 2,
        },
        color:"black",
    },
    plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              size: 15,
            },
            color: "black",
          },
        },
        title: {
          display: true,
          text: "Line Chart",
          font: {
            size: 15,
          },
          color: "black",
        },
      },
    // canvas: {
    //   // height: props.height || null, // Set canvas height dynamically or use null for default
    //   // width: props.size || null,   // Set canvas width dynamically or use null for default
    // },
};

  const labels = props.data?.map(entry => entry.label) ?? [];
  const dataValues = props.data?.map(entry => entry.data) ?? [];

  const data = {
    labels,
    datasets: [
      {
        label: props.title,
        data: dataValues,
        borderColor: 'rgba(255, 99, 132, 0.5)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderWidth: 1,
      },
    ],
  };
  return (
    <Line data={data} options={options}
    //  width={props.size}

     />
  );
}

export default LineChart;
