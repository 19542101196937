import React from 'react';
import { ButtonTypes } from '../../utils/Constants';
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import Button from '../../components/common/Button';
import { checkParameters } from "../../utils/Encryption";
import TinyEceEditor from '../../components/TinyEceEditor';
import { isValidForm } from '../../utils/validation/CommonValidator';
import ValidationText from '../../utils/validation/ValidationText';
import emailServices from '../../services/domainServices/emailServices';
import BaseComponent from '../../shared/baseComponent';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import ButtonGroup from '../../components/common/ButtonGroup';

export default class EmailDetail extends BaseComponent {
    constructor(props) {
        super(props)
        this.emailService = new emailServices();
        this.state = {
            ...this.state,
            resetValidationRule: {},
            emailDetails: [],
            isBtnLoading: false,
            setIsLoad: false,
            redirect: null,
        }
        this.emailService.onUpdateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChangeDefault = this.handleChangeDefault.bind(this);
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.emailDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    handleChange(name, value) {
        let detail = this.state.emailDetails;
        detail[name] = value;
        this.setState({ emailDetails: { ...detail } });
    }

    handleChangeDefault = (e) => {
        const { name, checked } = e.target;
        let detail = this.state.emailDetails;
        detail[name] = checked;
        this.setState({ emailDetails: { ...detail } });
    }

    onUpdateEmailDetailsById = () => {
        if (this.emailService.isValidSubmit(this.state.emailDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                emailTemplateId: this.state.emailDetails.emailTemplateId,
                emailTemplateName: this.state.emailDetails.emailTemplateName,
                subject: this.state.emailDetails.subject,
                emailBody: this.state.emailDetails.emailBody,
                isActive: this.state.emailDetails.isActive
            }
            this.emailService.onUpdateEmailDetails(request);
        }
    }

    onChange(event) {
        var newContent = event.editor.getData();
        let detail = this.state.emailDetails;
        detail['emailBody'] = newContent;
        this.setState({ emailDetails: { ...detail } });
    }
    componentDidMount() {
        this.emailService.onInitService();
        let params = new URLSearchParams(window.location.search);
        let emailTemplateId = params.get("");
        if (emailTemplateId && (emailTemplateId = checkParameters(emailTemplateId, "Number"))) {
            this.emailService.onGetEmailDetailsById(emailTemplateId)
            document.title = 'Email Details';
        }
    }



    setDescription = (data) => {
        if (data) {
            let detail = this.state.emailDetails;
            detail.emailBody = data;
            this.setState({ emailDetails: { ...detail } });
        }
    };

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        {
                            (this.state.setIsLoad) ?
                                <Bars
                                    className="row"
                                    wrapperStyle={{ marginTop: '150px' }}
                                    color="#3c4b64"
                                    height={55}
                                    width="100%"
                                    visible={this.state.setIsLoad}
                                />
                                :
                                <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                                    <div className="sm:flex sm:justify-between sm:items-center mb-3">
                                        <div className="mb-4 sm:mb-0">
                                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Edit Email</h1>
                                        </div>
                                        <Button type={ButtonTypes.Primary} buttonClick={() => this.emailService.onRedirectoList()} title={<i className="fas fa-arrow-left"></i>} />
                                    </div>
                                    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm mb-3 ">
                                        <div className="grid mb-3">
                                            <div className="col-span-full xl:col-span-8  rounded-sm  ">
                                                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                                    <div className="space-y-8 mt-8">
                                                        <div>
                                                            <div className="grid gap-5 md:grid-cols-3">
                                                                {this.emailService.formFields.map(
                                                                    (Item, index) => {
                                                                        return (
                                                                            <>
                                                                                {Item.isUpdate === true ? (
                                                                                    <FormFieldGroup
                                                                                        column={Item}
                                                                                        value={this.state.emailDetails[Item.fieldName]}
                                                                                        error={this.state.validState.error[Item.fieldName]}
                                                                                        onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                                        onBlur={() => this.emailService.validateField(Item.fieldName, this.state.emailDetails, this.state.validationRules, this.state.validState)}
                                                                                        key={index}
                                                                                    />
                                                                                ) : null}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
                                                    <TinyEceEditor
                                                        key={"fullPageCKEditor"}
                                                        onEditorChange={(data) => {
                                                            this.setDescription(data);
                                                        }}
                                                        data={this.state.emailDetails.emailBody}
                                                        onBlur={(event, editor) => {
                                                            this.validateField("emailBody", this.state.emailDetails.emailBody, this.state.validationRules, this.state.validState);
                                                            // this.validateField("emailBody");
                                                        }}
                                                    />
                                                    {
                                                        this.state.emailDetails.emailBody ?
                                                            null
                                                            :
                                                            <ValidationText error={this.state.validState.error.emailBody} />
                                                    }
                                                </div>
                                                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                                    <div className="mr-1">
                                                        <label className="flex items-center">
                                                            {this.emailService.formFields.map((Item, index) => {
                                                                return (
                                                                    <>
                                                                        {Item.checked ? (
                                                                            <FormFieldGroup
                                                                                column={Item}
                                                                                value={this.state.emailDetails[Item.fieldName]}
                                                                                onChange={(event) => this.handleChange(Item.fieldName, event.target.checked)}
                                                                                checked={this.state.emailDetails[Item.fieldName]}
                                                                                onBlur={() => this.emailService.validateField(Item.fieldName, this.state.emailDetails, this.state.validationRules, this.state.validState)}
                                                                                key={index}
                                                                            />
                                                                        ) : null}
                                                                    </>
                                                                );
                                                            }
                                                            )}
                                                            <span className="text-sm ml-2"><b>IsActive </b></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap float-right m-3">
                                                    <ButtonGroup
                                                        onCancelClick={() => this.emailService.onRedirectoList()}
                                                        onUpdateClick={(e) => this.onUpdateEmailDetailsById(e)}
                                                        updateState={1}
                                                        isLoading={this.state.setIsLoad}
                                                    />

                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </main>
                </div>
            </div>
        )
    }
}


