// Components
import React from 'react';
import Table from '../../components/tables/Table';
import BaseComponent from "../../shared/baseComponent";
// Library
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router';
import FormFieldGroup from "../../components/FormGroup/FormFieldGroup";
// Services
import SearchHistoryService from '../../services/domainServices/searchHistoryServices';
import Date_Range_Pickers from "../../components/DateRanges";
import { encryptAES } from '../../utils/Encryption';
import { ErrorMessage } from '../../utils/Messages';


export default class SearchHistoryListGrid extends BaseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.searchHistoryServices = new SearchHistoryService();
    this.state = {
      setIsLoad: false,
      dataList: [],
      searchList: this.searchHistoryServices.dataItem,
      pagination: {
        ...this.searchHistoryServices.pagination,
        toDate: "",
        fromDate: ""
      },
      changeDateValue: {
        startDate: "",
        endDate: ""
      }
    };
    this.searchHistoryServices.onUpdateState = this.updateState.bind(this);
    this.searchHistoryServices.onActionCall = this.onActionCall.bind(this);
  }
  componentDidMount() {
    this.searchHistoryServices.onGetSearch(this.state.pagination);
    document.title = 'Search History List';
  }


  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  }

  handleLanguage = (changeValue) => {

    this.setState({ changeDateValue: changeValue });
    let request = {
      ...this.state.pagination,
      fromDate: changeValue.startDate,
      toDate: changeValue.endDate
    }
    this.setState({ pagination: request });
    this.searchHistoryServices.onGetSearch(request);

  }
  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.searchHistoryServices.onGetSearch(data);
      this.setState({ pagination: { ...data } });

    }
    this.setState({ searchList: { ...detail } });
  }
  onActionCall = (type, data) => {
    let det = this.state.dataList;
    switch (type) {
      case "userIPAddress":
        let ipAddressDetails = det.find(x => x.userIPAddress === data);
        if (ipAddressDetails && ipAddressDetails.ipAddressesId !== null) {
          window.open(`/IpAddressDetails/ipAddressesId?=${encryptAES(ipAddressDetails.ipAddressesId)}`, '_blank');
        } else {
          this.searchHistoryServices.apiService.swalServices.Alert(ErrorMessage.ipAddressDetailNotExists);
        }
        break;
    }
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Search History </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3 w-[670px]">
                  <div className="flex items-center dashboard-date-range">
                    <Date_Range_Pickers onSelectDate={this.handleLanguage} />
                  </div>
                  <div className="relative w-[300px]">
                    <label htmlFor="action-search" className="sr-only">Search</label>
                    {this.searchHistoryServices.formFields.map((Item, index) => {
                      return (
                        <FormFieldGroup className='ms-auto relative search-bar-input'
                          column={Item}
                          value={
                            this.state.searchList[Item.fieldName]
                          }
                          onChange={(event) =>
                            this.handleChangeEvent(Item.fieldName, event.target.value)
                          }
                          onKeyPress={(e) =>
                            e.key === "Enter" &&
                            this.handleChange(Item.fieldName, e.target.value)
                          }
                          onBlur={Item.onBlur ? Item.onBlur : null}
                          key={index} />
                      );
                    })}
                  </div>
                  <button className="btn commanColorbtn text-white w-[100px]" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                    <span className="xs:block">Search</span>

                  </button>
                </div>
              </div>
              <div className='table-tr-search table-tr-search2 table-tr-search1 table-tr-rfq table-tr-width search-history-new'>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <Table
                      columns={this.searchHistoryServices.gridColmns}
                      rows={this.state.dataList}
                      sortingColumns={[]}
                      pagination={this.state.pagination}
                      totalCount={this.state.TotalCount}
                      totalResultes={this.state.TotalCount}
                      setPagination={this.searchHistoryServices.onPageChange.bind(this)}
                      customScope={[
                        {
                          column: 'userIPAddress',
                          renderTableData: this.searchHistoryServices.ipAddressAction.bind(this)
                        },
                        {
                          column: "isCrawler",
                          renderTableData: this.searchHistoryServices.gridCheckBoxCol.bind(this),
                        },
                      ]}
                    />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }

}
