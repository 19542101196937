import React from "react";
import { Bars } from "react-loader-spinner";
import BaseComponent from "../../shared/baseComponent";
import componetWizardServices from "../../services/domainServices/componetWizardServices";
import CountCard from "../../components/Informatics/CountCard";
import Table from "../../components/Informatics/Table";
import RanderCharts from "../../components/Informatics/RanderCharts";
import DatePicker from "react-datepicker";
import moment from "moment";

class Monthly extends BaseComponent {
  constructor(props) {
    super(props);
    this.componetWizardServices = new componetWizardServices();
    this.state = {
      ...this.state,
      setIsLoad: false,
      monthlydataList: [],
      pagination: this.componetWizardServices.pagination,
      selectedDate: new Date(),
      startDate: moment(new Date()).startOf("month").toDate(),
      endDate: moment(new Date()).endOf("month").toDate(),
      configurationId: 3,
    };
    this.componetWizardServices.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.onApiCall();
  }

  onApiCall = () => {
    let req = [3, moment(this.state.selectedDate).format("YYYY-MM-DD")];
    this.componetWizardServices.onGetComponentWizardsList(req);
  };

  handleDateChange = (date) => {
    const startDate = moment(date).startOf("month").toDate();
    const endDate = moment(date).endOf("month").toDate();

    this.setState(
      {
        selectedDate: date,
        startDate,
        endDate,
      },
      () => {
        this.onApiCall();
      }
    );
  };

  capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  render() {
    const { setIsLoad, monthlydataList, pagination } = this.state;

    return (
      <>
        <div className="grid justify-end">
          <div className="flex Charts-date items-center">
          <span className="text-[15px]">
              Start Date:{" "}
              <b className="text-[15px]">{moment(this.state.startDate).format("MM-DD-YYYY")}</b>
            </span>
            <span className="ml-2 text-[15px]">
              End Date: <b className="text-[15px]">{moment(this.state.endDate).format("MM-DD-YYYY")}</b>
            </span>
            <DatePicker
              selected={this.state.selectedDate}
              onChange={this.handleDateChange}
              className="custom-datepicker ml-2"
            />
          </div>
        </div>
        <div className="widgets-section sticky-container">
          <div className="grid grid-cols-12 gap-6 pt-2 pb-3 p-3">
            {setIsLoad ? (
              <div className="col-span-12 mt-5">
                <Bars
                  className="row"
                  wrapperStyle={{ marginTop: "0px" }}
                  color="#3c4b64"
                  height={55}
                  width="100%"
                  visible={setIsLoad}
                />
              </div>
            ) : monthlydataList && monthlydataList.length > 0 ? (
              <>
                {monthlydataList.map((list, index) => {
                  const capitalizedTitle = this.capitalizeFirstLetter(
                    list.title
                  );
                  return list.componentWizardType === "Counts" ? (
                    <CountCard data={list.dataList} size={list.size} />
                  ) : list.componentWizardType === "Table" ? (
                    <Table
                      title={capitalizedTitle}
                      key={index}
                      data={list.dataList[0]}
                      size={list.size}
                      selectedDate={this.state.selectedDate}
                      configurationId={this.state.configurationId}
                      componentWizardId={
                        this.state.monthlydataList[index].componentWizardId
                      }
                      // height={list.height}
                    />
                  ) : (
                    <>
                      <div
                        className={`center-div col-span-${
                          list.size == 1
                            ? 4
                            : list.size == 2
                            ? 6
                            : list.size == 3
                            ? 12
                            : list.size
                        }`}
                        key={index}
                      >
                        <div className="Dashboard-card-count card-bg-light">
                        <div className="card-title">
                                {capitalizedTitle}
                              </div>
                          <RanderCharts
                            chartType={list.chartType}
                            data={list.dataList}
                            title={capitalizedTitle}
                            // height={list.height}
                            size={list.size}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <div className="col-span-12 text-center mt-5">
                <h1 style={{ fontSize: "28px" }}>No Record Found.</h1>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Monthly;
