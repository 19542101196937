import React from 'react';

//** Lib */
import Table from '../../tables/Table';
import PropertyModal from './PropertyModal';
import { Bars } from 'react-loader-spinner';
import BaseComponent from '../../../shared/baseComponent';
import { checkParameters } from '../../../utils/Encryption';

//** Service */
import ProductPropertyDomainService from '../../../services/domainServices/productPropertyService';

export class ProductPropertyList extends BaseComponent {
    constructor(props) {
        super(props)
        this.productPropertyService = new ProductPropertyDomainService();
        this.state = {
            ...this.state,
            setOpenModal: false,
            productPropertiesData: [],
            propertyIndex: "",
            isUpdate: false,
            setIsLoad: false,
            productId: 0,
            product: this.productPropertyService.dataPropertyItem,
            updatePS: {
                mf: "",
                mw: "",
                rotBonds: "",
                hdonor: "",
                hacceptor: "",
                polarSA: ""
            }
        }
        this.productPropertyService.onUpdateState = this.updateState.bind(this);
        this.productPropertyService.onActionCall = this.onActionCall.bind(this);
    }
    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let pdId = params.get("");
        if (pdId && (pdId = checkParameters(pdId, "String"))) {
            this.productPropertyService.onGetProperty(pdId);
            this.setState({ productId: pdId })
        }
        this.productPropertyService.onInitPropertyService();
    }
    openModal = (event, index) => {
        event.stopPropagation();
        if (index !== '') {
            this.setState({
                setOpenModal: true,
                isUpdate: true,
                propertyIndex: index
            });
        }
        else {
            this.setState({
                setOpenModal: true,
                isUpdate: false,
                propertyIndex: ''
            });
        }
    }
    // *** handleChange function **//
    handleChangeupdate(name, value, index) {
        let detail = this.state.updatePS
        detail[name] = value;
        this.setState({ updatePS: detail });
    }
    handleChange(name, value) {
        let details = this.state.product;
        details[name] = value;
        this.setState({ product: { ...details } });
    }
    //** API Function  */
    addPropertyDetails = (e) => {
        let request = {
            productId: this.state.productId,
            mf: this.state.product.mf,
            mw: this.state.product.mw,
            rotBonds: this.state.product.rotBonds,
            hdonor: this.state.product.hdonor,
            hacceptor: this.state.product.hacceptor,
            polarSA: this.state.product.polarSA,
            createdBy: this.productPropertyService.authUser.adminUserId,
        }
        if (this.productPropertyService.isValidSubmit(this.state.product, this.state.validationRules, this.state.validState)) {
            this.productPropertyService.onAddProductProperties(request, this.state.productId);
        }
    }
    updatePropertyDetails = () => {
        this.productPropertyService.onUpdateProductProperties(this.state.updatePS, this.state.productId);
    };
    onActionCall = (type, data, index, ppId) => {
        switch (type) {
            case "Edit":
                let update = this.state.productPropertiesData.find(c => c.productPropertiesId === ppId);
                const updatePS = {
                    productPropertiesId: update.productPropertiesId,
                    productId: update.productId,
                    mf: update.mf,
                    mw: update.mw,
                    rotBonds: update.rotBonds,
                    hdonor: update.hdonor,
                    hacceptor: update.hacceptor,
                    polarSA: update.polarSA,
                    updatedBy: this.productPropertyService.authUser.adminUserId
                };
                this.setState({ updatePS: updatePS }, () => {
                    this.openModal(data, index);
                });
                break;
        }
    }
    onCloseModal = (event) => {
        this.setState({ setOpenModal: false });
        this.productPropertyService.onResetData();
    }


    render() {
        return (
            <>
                <div className='flex flex-wrap float-right2 mt-3 mb-4'>
                    {this.state.productPropertiesData.length === 0 ?
                        <button className='btn buttonLeft bg-[#00769f] hover:bg-[#11769f] transition-all text-white' onClick={(event) => this.openModal(event, '')} >Add</button>
                        : null}
                </div>
                <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                    {(this.state.setIsLoad) ?
                        <div className='Bars_details'>
                            <Bars
                                className="row"
                                wrapperStyle={{ marginTop: '150px' }}
                                color="#3c4b64"
                                height={55}
                                width="100%"
                                visible={this.state.setIsLoad}
                            />
                        </div>
                        :
                        <Table
                            columns={this.productPropertyService.gridColmnsForProperty}
                            rows={this.state.productPropertiesData}
                            sortingColumns={'null'}
                            pagination={'null'}
                            totalCount={'null'}
                            totalResultes={'null'}
                            setPagination={'null'}
                            customScope={[
                                {
                                    column: 'productPropertiesId',
                                    column1: false,
                                    renderTableData: this.productPropertyService.gridAction.bind(this)
                                },
                            ]} />
                    }
                </div>
                <PropertyModal
                    openStateModal={this.state.setOpenModal}
                    updatePS={this.state.updatePS}
                    propertyIndexList={this.state.propertyIndex}
                    productPropertiesData={this.state.productPropertiesData}
                    product={this.state.product}
                    productPropertyService={this.productPropertyService}
                    updateProperty={this.state.isUpdate}
                    updatePropertyDetails={this.updatePropertyDetails.bind(this)}
                    addPropertyDetails={this.addPropertyDetails.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    handleChangeupdate={this.handleChangeupdate.bind(this)}
                    productDetails={this.props.productsDetails}
                    onCloseModal={this.onCloseModal.bind(this)}
                    modalOpen={(e) => { this.setState({ setOpenModal: false }) }}
                    onClose={(e) => { e.stopPropagation(); this.setState({ setOpenModal: false }) }}
                    validState={this.state.validState}
                    validationRules={this.state.validationRules}
                    validateField={this.productPropertyService.validateField.bind(this)} />
            </>
        )
    }
}


export default ProductPropertyList;