import React from 'react'
import BaseComponent from "../../shared/baseComponent";
import ProductDomainService from '../../services/domainServices/productDomainService';
// import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import ProductServices from '../../services/axiosServices/apiServices/ProductServices';
// import moment from 'moment';
// import { saveAs } from 'file-saver';
// import { ErrorMessage } from '../../utils/Messages';
import SwalServices from '../../services/swalServices/SwalServices';
export default class ProductCode extends BaseComponent {
    constructor(props) {
        super(props)
        this.productService = new ProductDomainService();
        this.swalServices = new SwalServices();
        this.state = {
            setIsLoad: false,
            productCodeCount: '',
            exportData: this.productService.dataExport,
        }
        this.productService.onUpdateState = this.updateState.bind(this);
    }

    exportLink = new ProductServices()

    productCode() {
        this.productService.onUpdateRandonNumberForProductCode()
    }
    componentDidMount() {
        this.productService.onInitExportService();
        this.productService.onAdminGetAllEmptyProductCodeCounts()
    }

    handleChange(name, value) {
        let detail = { ...this.state.exportData };
        detail[name] = value;
        this.setState({ exportData: { ...detail } });
    }

    // Export = () => {
    //     if (this.state.exportData.supplierName === "") {
    //         this.swalServices.Toaster(ErrorMessage.SelectFieldRequired.replace("{0}", "Export Link"));
    //     } else {
    //         let req = [
    //             this.state.exportData.supplierName
    //         ]
    //         this.setState({ setIsLoad: true })
    //         this.exportLink.exportSupplierDirectLink(req).then((res) => {
    //             var formattedDate = moment(new Date()).format("YYMMDD")
    //             var filename = 'Export Data' + formattedDate;
    //             var blob = new Blob([res.data])
    //             saveAs(blob, filename + ".xlsx");
    //         })
    //         this.setState({ setIsLoad: false })
    //     }
    // };

    render() {
        return (
            <div className="flex">
                <div className="relative flex flex-col flex-1">
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8  py-2 w-full max-w-9xl mx-auto">
                            {/* <div className="sm:flex sm:justify-between sm:items-center mb-2">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-3">Product Code</h1>
                                </div>
                            </div> */}
                            <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200 activity_details">
                                <div className="p-5">
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 gap-6'>
                                        <div className='d-flex p-6 gap-3 useractivitydetails sm:flex sm:justify-between'>
                                            <button className=" btn-primary  gap-2">
                                                <span><b>Remaning Product Code To Generate : </b><span className='sync_count'>{this.state.productCodeCount}</span></span>
                                            </button>
                                            {this.state.productCodeCount === 0 ?
                                                <button className="btn btn-primary sync-button gap-2 not-allowed" disabled>
                                                    <><span>Product Code</span>
                                                        <i className='fas fa-file-excel'></i></>
                                                </button>
                                                :
                                                <button className="btn btn-primary sync-button gap-2" onClick={() => this.productCode()}>
                                                    {this.state.setIsLoad ? <div className="loader"></div> : <><span>Product Code</span>
                                                        <i className='fas fa-file-excel'></i></>}
                                                </button>
                                            }
                                        </div>

                                        {/* <div className='d-flex p-6 gap-3 useractivitydetails sm:flex sm:justify-between'>
                                            <div className='contents'>
                                                {this.productService.exportformFields.map((Item, index) => {
                                                    return (
                                                        <>
                                                            <FormFieldGroup
                                                                column={Item}
                                                                value={this.state.exportData[Item.fieldName]}
                                                                options={Item.Options ? Item.Options : null}
                                                                onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                                            />
                                                        </>
                                                    );
                                                })}
                                                <div className=''>
                                                    <button className="btn btn-primary sync-button gap-2" onClick={() => this.Export()}>
                                                        {this.state.setIsLoad ? <div className="loader"></div> : <><span>Export</span>
                                                            <i className='fas fa-file-excel'></i></>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
