//Components
import { axiosPost } from '../AxiosRequests';
import { Admin_GetUserSearchHistory, Admin_GetAllSearchHistoryList, Admin_GetAllSearchHistoryListByUser } from '../ApiEndPoints';

export default class SearchHistoryServices {

    // API function
    async admin_GetUserSearchHistory(request) {
        return axiosPost(Admin_GetUserSearchHistory, request);
    }
    async admin_GetAllSearchHistoryList(request) {
        return axiosPost(Admin_GetAllSearchHistoryList, request);
    }
    async admin_GetAllSearchHistoryListByUser(request) {
        return axiosPost(Admin_GetAllSearchHistoryListByUser, request);
    }
}