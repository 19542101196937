//** Component  */
import React from "react";
import Validation from '../../utils/validation/ValidationText';
import InputField from "./InputField";
import SearchField from "./SearchField";
import FileField from "./FileField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectField";
import TextAreaField from "./TextAreaField";


const FormFieldGroup = (props) => {
  return (
    <>
      {props.column.inputType === "text" || props.column.inputType === "number" || props.column.inputType === "password" ? (
        <div className={`form-group   ${props.column.className} ${props.className}`}>
          <label className={`block text-sm font-medium mb-1`} hidden={props.column.tittle === ""}
            style={{ display: props.column.labelStyleName }} htmlFor={props.column.fieldName}>
            {props.column.tittle}
          </label>
          <InputField request={props} />
          <Validation error={props.error} />
        </div>
      ) : null}
      {props.column.inputType === "search" ? (
        <div className={`form-group`}>

          <SearchField request={props} />
          <Validation error={props.error} />
        </div>
      ) : null}
      {props.column.inputType === "file" ? (
        <div className={`form-group  ${props.column.className}`}>
          <label className="block text-sm font-medium mb-1" htmlFor={props.column.fieldName}>
            {props.column.tittle}
          </label>

          <FileField request={props} />
          <Validation error={props.error} />
        </div>
      ) : null}
      {props.column.inputType === "checkbox" ? (
        <div className={`form-group  ${props.column.className}`}>

          <CheckboxField request={props} />
          <label
            className={`text-sm font-medium mb-1 ${props.column.checkLabelClass}`}
            htmlFor={props.column.fieldName}>
            {props.column.tittle}
          </label>
        </div>
      ) : null}
      {props.column.inputType === "select" ? (
        <div className={`${props.column.className}`}>
          <label className="block text-sm font-medium mb-1">{props.column.tittle} </label>

          <SelectField request={props} />
          <Validation error={props.error} />
        </div>
      ) : null}
      {props.column.inputType === "textarea" ? (
        <div className={`${props.column.className}`}>
          <label className="block text-sm font-medium mb-1">{props.column.tittle} </label>

          <TextAreaField request={props} />
          <Validation error={props.error} />
        </div>
      ) : null}
      {props.column.inputType === "label" ?
        <div className={`mb-2 label-section-main ${props.column.className}`}>
          <label className={`fw-bold ${props.column.labelClass}`} style={{ fontSize: '15px' }} hidden={props.column.tittle === ""}>{props.column.tittle}  </label>
          {props.column.valueClass === 'brake_word' ?
            <label className={`ml-1`} style={{ fontSize: '15px', wordBreak: 'break-word' }} hidden={props.column.tittle === ""}>{props.value ? props.value : '-'} </label>
            : <label className={`ml-1 ${props.column.valueClass}`} style={{ fontSize: '15px' }} hidden={props.column.tittle === ""}>{props.value ? props.value : '-'} </label>}
        </div>
        : null}
      {props.column.inputType === "card" ?
        <div className="flex items-center p-8 bg-white shadow rounded-lg center-div">
          <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-6 icon-color-new">
            {props.column.svg ?
              <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                {props.column.svg}
              </svg> : null}
            {props.column.icon ? <i className={`far text-3xl ${props.column.icon}`} > </i> : null}
          </div>
          <div>
            <span className="block text-2xl font-bold">{props.value}</span>
            <span className="block text-gray-500">{props.column.tittle}</span>
          </div>
        </div>
        : null}
      {props.column.inputType === "tabs" ?
        <div className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 last:mr-0 flex-auto text-center chapter-e-sec `}>
          <a className={props.state === props.column.activeTab ?
            "bg-[#11769f] tab-background2 inner-title-font-small-button -button font-bold 2xl:p-4 lg:p-3 p-2 py-0 flex items-center justify-center h-full"
            : " inner-title-font-small-button font-bold py-2 text-[#1e293b]  flex items-center justify-center h-full"}
            onClick={props.onClick} data-toggle="tab" href="#." role="tabdivst">
            {props.column.tittle}
          </a>
        </div>
        : null}
    </>
  );
};

export default FormFieldGroup;
