export const MenuType = {
    SuperMenu: 1,
    SubMenu: 2
};

export const MenuOptions = [
    {
        type: MenuType.SubMenu,
        title: "Dashboard",
        icon: "far fa-chart-line",
        link: "/"
    },
    {
        type: MenuType.SubMenu,
        title: "Informatics",
        icon: "far fa-chart-line",
        link: "/Informatics"
    },
    {
        type: MenuType.SuperMenu,
        title: "Analytics",
        icon: "far fa-analytics",
        subMenus: [
            {
                title: "Search History",
                link: "/SearchHistoryListGrid"
            },
            {
                title: "View products",
                link: "/ViewProducts"
            },
            {
                type: MenuType.SubMenu,
                title: "Shopping Cart",
                icon: "far fa-industry",
                link: "/ShoppingCart"
            },
            {
                type: MenuType.SubMenu,
                title: "Exception",
                icon: "far fa-industry",
                link: "/Exception"
            },
            {
                type: MenuType.SubMenu,
                title: "User Activity",
                icon: "far fa-industry",
                link: "/UserActivity"
            },
        ]
    },
    {
        type: MenuType.SubMenu,
        title: "Review RFQ",
        icon: "far fa-shopping-cart",
        link: "/RFQList"

    },

    {
        type: MenuType.SubMenu,
        title: "Manage Orders",
        icon: "far fa-clipboard",
        svg: "M25.27,86.92c-1.81,0-3.26-1.46-3.26-3.26s1.47-3.26,3.26-3.26h21.49c1.81,0,3.26,1.46,3.26,3.26s-1.46,3.26-3.26,3.26 H25.27L25.27,86.92L25.27,86.92z M61.1,77.47c-0.96,0-1.78-0.82-1.78-1.82c0-0.96,0.82-1.78,1.78-1.78h4.65c0.04,0,0.14,0,0.18,0 c1.64,0.04,3.1,0.36,4.33,1.14c1.37,0.87,2.37,2.19,2.92,4.15c0,0.04,0,0.09,0.05,0.14l0.46,1.82h39.89c1,0,1.78,0.82,1.78,1.78 c0,0.18-0.05,0.36-0.09,0.55l-4.65,18.74c-0.18,0.82-0.91,1.37-1.73,1.37l0,0l-29.18,0c0.64,2.37,1.28,3.65,2.14,4.24 c1.05,0.68,2.87,0.73,5.93,0.68h0.04l0,0h20.61c1,0,1.78,0.82,1.78,1.78c0,1-0.82,1.78-1.78,1.78H87.81l0,0 c-3.79,0.04-6.11-0.05-7.98-1.28c-1.92-1.28-2.92-3.46-3.92-7.43l0,0L69.8,80.2c0-0.05,0-0.05-0.04-0.09 c-0.27-1-0.73-1.69-1.37-2.05c-0.64-0.41-1.5-0.59-2.51-0.59c-0.05,0-0.09,0-0.14,0H61.1L61.1,77.47L61.1,77.47z M103.09,114.13 c2.42,0,4.38,1.96,4.38,4.38s-1.96,4.38-4.38,4.38s-4.38-1.96-4.38-4.38S100.67,114.13,103.09,114.13L103.09,114.13L103.09,114.13z M83.89,114.13c2.42,0,4.38,1.96,4.38,4.38s-1.96,4.38-4.38,4.38c-2.42,0-4.38-1.96-4.38-4.38S81.48,114.13,83.89,114.13 L83.89,114.13L83.89,114.13z M25.27,33.58c-1.81,0-3.26-1.47-3.26-3.26c0-1.8,1.47-3.26,3.26-3.26h50.52 c1.81,0,3.26,1.46,3.26,3.26c0,1.8-1.46,3.26-3.26,3.26H25.27L25.27,33.58L25.27,33.58z M7.57,0h85.63c2.09,0,3.99,0.85,5.35,2.21 s2.21,3.26,2.21,5.35v59.98h-6.5V7.59c0-0.29-0.12-0.56-0.31-0.76c-0.2-0.19-0.47-0.31-0.76-0.31l0,0H7.57 c-0.29,0-0.56,0.12-0.76,0.31S6.51,7.3,6.51,7.59v98.67c0,0.29,0.12,0.56,0.31,0.76s0.46,0.31,0.76,0.31h55.05 c0.61,2.39,1.3,4.48,2.23,6.47H7.57c-2.09,0-3.99-0.85-5.35-2.21C0.85,110.24,0,108.34,0,106.25V7.57c0-2.09,0.85-4,2.21-5.36 S5.48,0,7.57,0L7.57,0L7.57,0z M25.27,60.25c-1.81,0-3.26-1.46-3.26-3.26s1.47-3.26,3.26-3.26h50.52c1.81,0,3.26,1.46,3.26,3.26 s-1.46,3.26-3.26,3.26H25.27L25.27,60.25L25.27,60.25z",
        link: "/OrderList"

    },
    {
        type: MenuType.SuperMenu,
        title: "Manage Products",
        icon: "far fa-envelope",
        subMenus: [
            {
                title: "Product Dashboard",
                link: "/ProductDashboard"
            },
            {
                title: "Search Products",
                link: "/ProductList"
            },
            {
                title: "Add New Products",
                link: "/AddNewProducts"
            },
        ]
    },




    {
        type: MenuType.SuperMenu,
        title: "Master Data Set",
        icon: "far fa-chart-line",
        subMenus: [
            {
                title: "User Dashboard",
                link: "/UserDashboard"
            },
            {
                title: "Users",
                link: "/UserList"
            },
            {
                title: "Admin Users",
                link: "/AdminUserList"
            },
            {
                type: MenuType.SubMenu,
                title: "Contact Us",
                icon: "far fa-address-book",
                link: "/ContactUsList"
            },

            {
                title: "Audit logs",
                link: "/AuditLogs"
            },
            {
                type: MenuType.SubMenu,
                title: "Companies",
                icon: "far fa-industry",
                link: "/CompaniesList"

            },
            {
                type: MenuType.SubMenu,
                title: "View Ip Address",
                icon: "far fa-shopping-cart",
                link: "/ViewIpAddress"
            },
            {
                type: MenuType.SubMenu,
                title: "Block Ip Address",
                icon: "far fa-shopping-cart",
                link: "/blockIp"
            },
        ]
    },

    {
        type: MenuType.SuperMenu,
        title: "Configuration",
        icon: "far fa-chart-line",
        subMenus: [
            {
                title: "Setting Configuration",
                link: "/Setting"
            },
            {
                title: "Email Template",
                link: "/EmailHistory"
            },
            {
                title: "Digest Email",
                link: "/DigestEmailHistory"
            },
            {
                title: "Informatics Configuration",
                link: "/InformaticsConfigurationList"
            },
            {
                title: "Database Health",
                link: "/DatabaseHealth"
            },
            
        ]
    },

    {
        type: MenuType.SuperMenu,
        title: "Data Import",
        icon: "far fa-file-import",
        subMenus: [
            {
                title: "Data Import",
                link: "/DataImport"
            },
            {
                title: "Data Import List",
                link: "/DataImportList"
            },
        ]
    },

    {
        type: MenuType.SuperMenu,
        title: "Manage Reports",
        icon: "far fa-flag",
        subMenus: [
            {
                title: "Product Search Reports",
                link: "/ProductReports"
            },
            {
                title: "Product Reports",
                link: "/ViewReports"
            }
        ]
    },
];