//Components
import React from 'react';
import Button from '../../components/common/Button';
//Library
import { Navigate } from 'react-router-dom';
import { ErrorMessage } from '../../utils/Messages';
import { ButtonTypes } from '../../utils/Constants';
import BaseComponent from '../../shared/baseComponent';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';

//Services
import DataImportService from '../../services/domainServices/dataImportService';
import ButtonGroup from '../../components/common/ButtonGroup';

export class DataImportList extends BaseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.dataImportService = new DataImportService();
    this.state = {
      ...this.state,
      isBtnLoading: false,
      setIsLoad: false,
      importFile: {
        importTypeId: "",
        importTitle: "",
        importFileName: "",
        createdBy: this.dataImportService.authUser.adminUserId
      },
      redirect: null
    }
    this.dataImportService.onUpdateState = this.updateState.bind(this)
  }

  componentDidMount() {
    this.dataImportService.onInitService();
  }

  /* Event function */

  handleChange(event) {
    if (event.target.name === 'importTypeId') {
      let newImport = { ...this.state.importFile };
      newImport.importTypeId = event.target.value;
      this.setState({ importFile: newImport });
    }
    else if (event.target.name === 'importTitle') {
      const value = event.target.value;
      const name = event.target.name;
      let detail = this.state.importFile;
      detail[name] = value;
      this.setState({ importFile: { ...detail } });
    }
    else if (event.target.name === 'importFileName') {
      const file = event.target.files[0];
      if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        let Importfile = { ...this.state.importFile };
        Importfile.importFileName = file.name;
        Importfile["file"] = file;
        this.setState({ importFile: { ...Importfile } });
      }
      else {
        this.dataImportService.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileType);
      }
    }
  }

  onReset = () => {
    let reset = {
      ...this.state.importFile,
      importTypeId: "",
      importTitle: "",
      importFileName: "",
      createdBy: this.dataImportService.authUser.adminUserId
    }
    this.setState({ importFile: reset })
  };

  setFile = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      let Importfile = { ...this.state.importFile };
      Importfile.importFileName = file.name;
      Importfile["file"] = file;
      this.setState({ importFile: { ...Importfile } });
    }
    else {
      this.dataImportService.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileType);
    }
  }

  // API function
  addImport = () => {
    if (this.dataImportService.isValidSubmit(this.state.importFile, this.state.validationRules, this.state.validState)) {
      let request = { ...this.state.importFile }
      this.dataImportService.onAdd(request);
    }
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
        <div className="grow flex justify-between mb-4">
          <div className="self-center">
            <h2 className="text-2xl md:text-3xl text-slate-800 font-bold">Add New Import File</h2></div>
        </div>
        <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200 mb-3 ">
          <div className="grid mb-3">
            <div className="col-span-full xl:col-span-8  rounded-sm  border-slate-200">
              <div className="px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto">
                <div className="border-t border-slate-200">
                  <div className="space-y-8 mt-5">
                    <div className="grid gap-5 md:grid-cols-3">
                      {this.dataImportService.formFields.map((Item, index) => {
                        return (
                          Item.detailsField != true ?
                            <FormFieldGroup
                              column={Item}
                              value={this.state.importFile[Item.fieldName]}
                              onChange={(event) => this.handleChange(event)}
                              options={Item.options ? Item.options : null}
                              error={this.state.validState.error[Item.fieldName]}
                              onBlur={() => this.dataImportService.validateField(Item.fieldName, this.state.importFile, this.state.validationRules, this.state.validState)}
                              key={index} /> : null
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap my-5 mb-2 sm:justify-end justify-center ">
                  <ButtonGroup
                    onCancelClick={() => this.onReset()}
                    onAddClick={() => this.addImport()}
                    isLoading={this.state.setIsLoad}
                  />

                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default DataImportList