import baseDomainServices from "./baseDomainServices"

export default class CompanyService extends baseDomainServices {

    //** API Get
    getCompanyList = this.apiService.companyservice.getAllCompaniesList;


    //** Table Grid columns
    companylistgrid = [
        { name: "companyName", title: "company Name" },
        { name: "userCount", title: "user Count" },
    ]

    //** Constructor 
    constructor() {
        super();
        this.onPaginationUpdate = this.OngetCompanylist.bind(this)
    }

    //** API Calls
    OngetCompanylist = (requst) => {
        this.apiService.onAPICall("companylist", this.getCompanyList, requst, this.onAPIResponse, this.onUpdateState)
    }

    //** Response Method
    onAPIResponse = (key, iSuccess, responseData) => {
        if (!iSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "companylist":
                if (responseData !== null) {
                    this.onUpdateState({
                        getCompanies: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    })
                }
                break;
        }
    }
}
