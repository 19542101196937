//Components
import { axiosPost } from '../AxiosRequests';
import { Admin_GetSiteTrackerIPList } from '../ApiEndPoints';

export default class SiteTrackerIPList {

    // API function
    async admin_GetSiteTrackerIPList(request) {
        return axiosPost(Admin_GetSiteTrackerIPList, request);
    }
}