//Component
import React, { Component } from 'react';
import Table from '../../components/tables/Table';
import Form from '../../components/form/Form'
import ModalBasic from '../../components/ModalBasic';
//Library
import { Bars } from 'react-loader-spinner';
import { FormFieldTypes } from '../../utils/Constants';
import { ErrorMessage } from '../../utils/Messages';
import { SuccessMessage } from '../../utils/Messages';
//Services
import ManageBlockIPServices from '../../services/axiosServices/apiServices/ManageBlockIPServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class BlockIpAddress extends Component {
    // Constructor  and Component method
    constructor(props) {
        super(props)
        this.getManageBlockIPServices = new ManageBlockIPServices();
        this.swalServices = new SwalServices();
        this.state = {
            getBlockIp: [],
            searchingText: "",
            setSidebarOpen: false,
            isLoading: false,
            totalResultes: '',
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedDate",
                orderFlag: 1,
                searchText: "",
            },
            setBloackIdModel: false,
            form: {
                formTitle: "Form",
                formFields: [
                    {
                        type: FormFieldTypes.Input,
                        title: "IP Address",
                        isRequired: true,
                        isDisabled: false,
                        placeholder: "IP Address",
                        key: "ipAddress",
                        validation: [{ type: "require", message: "Please enter IP Address" }]
                    },
                ]
            },
            formObject: {
                ipAddress: "",
            },
            userDetail: {
                blockIpId: "",
                isBlackList: ""
            },
        }
    }


    // API function 

    addBlockIpAddress = () => {
        this.setState({ isLoading: true });
        this.getManageBlockIPServices.admin_AddBlockIpAddress(this.state.formObject).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Toaster(SuccessMessage.Insert_Success.replace("{0}", "Your Block IP Address"));
                this.getBlockIpAddress();
                this.cancel()
            }
            else {
                this.swalServices.Toaster(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
        })
    }
    GetSearch = (searching) => {
        let request = {
            pageNo: 1,
            pageSize: 10,
            orderByColumn: "CreatedDate",
            orderFlag: 1,
            searchText: searching
        }
        this.setState({ isLoading: true });
        this.getManageBlockIPServices.admin_GetBlockIpAddressList(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ getBlockIp: response.responseItem.responseContent.itemList });
            }
            else {
                this.swalServices.Toaster(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
        })
    }
    updateBlockIpStatus = (blockIpId, isBlackList) => {
        let request = { blockIpId: blockIpId, isBlackList: isBlackList }
        this.getManageBlockIPServices.admin_UpdateIsBlacklist(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Toaster(SuccessMessage.Update_Success.replace("{0}", "Your Block IP Address"));
                this.getBlockIpAddress();
            } else {
                this.swalServices.Toaster(ErrorMessage.DefaultMessage);
            }
        })
    }
    getBlockIpAddress = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.getManageBlockIPServices.admin_GetBlockIpAddressList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ getBlockIp: response.responseItem.responseContent.itemList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Toaster(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
        })
    }

    // Event function 

    handlePageChange = (pageNumber) => {
        if (this.state.pagination.pageNo !== pageNumber) {
            this.state.pagination.pageNo = pageNumber;
            this.getBlockIpAddress(this.state.pagination);
        }
    };
    submitForm = () => {
        this.addBlockIpAddress()
    }

    cancel = () => {
        this.setState({ setBloackIdModel: false })
    }

    handleChangeEvent = (value) => {
        this.setState({ isLoading: true });
        this.setState({ searchingText: value })
        this.handleSearching(value)
        if (value === '') {
            this.setState({ isLoading: false });
            this.getBlockIpAddress();
        }
    }


    handleChanges(key, value, index) {
        let user = this.state.getBlockIp;
        user[index][key] = value;
        this.setState({ getBlockIp: user })
        this.updateBlockIpStatus(this.state.getBlockIp[index].blockIpId, this.state.getBlockIp[index].isBlackList);
    }

    activechkbox = (element, index, value) => {
        return (element !== "isBlackList") ? null :
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
                <input
                    className="form-checkbox cursor-pointer ..."
                    type="checkbox"
                    id="chkisActive"
                    name="checkbox1"
                    checked={value}
                    onChange={(e) => this.handleChanges("isBlackList", e.target.checked, index)}
                />
            </td>
    }


    handleSearching = (searchQuery) => {
        if (searchQuery.trim() !== "") {
            this.GetSearch(searchQuery);
        }
        else if (searchQuery.trim() === "") {
            this.getBlockIpAddress(this.state.pagination)
        }
    }


    // Other function 

    actions = (element, index, value) => {
        return (element !== "blockIpId") ? null
            : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
                <i className="fas fa-eye  cursor-pointer ..." ></i>
                <i className="fas fa-trash pl-3  cursor-pointer ..."></i>
            </td>
    }
    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getBlockIpAddress(this.state.pagination)
    }

    setFormValues = (key, value) => {
        let newFormvalues = { ...this.state.formObject };
        newFormvalues[key] = value;
        this.setState({ formObject: newFormvalues });
    }
    componentDidMount() {
        this.getBlockIpAddress();
    }

    render() {
        return (
            <div className="flex h-screen overflow-hidden  bg-gray-100 min-h-screen">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="sm:flex sm:justify-between sm:items-center ">
                                <div className=" sm:mb-0 ">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-4">Blocked IP Address List </h1>
                                </div>
                            </div>
                            <div className="sm:flex sm:justify-between sm:items-center ">
                                <div className="mb-4 sm:mb-0">
                                    <ModalBasic id="basic-modal" modalOpen={this.state.setBloackIdModel} setModalOpen={() => this.setState({ setBloackIdModel: false })} title="Block IP Address">
                                        <div className="col-span-full xl:col-span-8  rounded-sm  border-slate-200">
                                            <div>
                                                <Form form={this.state.form}
                                                    formValues={this.state.formObject}
                                                    setFormValues={this.setFormValues.bind(this)}
                                                    submitForm={this.submitForm.bind(this)}
                                                    cancel={this.cancel.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </ModalBasic>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3 mb-3">
                                    <div className="relative">
                                        <label htmlFor="action-search" className="sr-only">Search</label>
                                        <input id="action-search" className="form-input pl-9 focus:border-slate-300" type="search" placeholder="Search..."
                                            value={this.state.searchingText}
                                            onChange={(e) => this.handleChangeEvent(e.target.value)}
                                            onKeyPress={(e) => e.key === 'Enter' && this.handleSearching(this.state.searchingText)} />
                                        <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search"  >
                                            <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                                <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <button className="btn bg-[#00769f] hover:bg-[#11769f] text-white" onClick={(e) => { e.stopPropagation(); this.handleSearching(this.state.searchingText) }}>
                                        <span className="hidden xs:block ml-2">Search</span>
                                    </button>
                                    <button className="btn bg-[#00769f] hover:bg-[#11769f] text-white" onClick={(e) => { e.stopPropagation(); this.setState({ setBloackIdModel: true }) }}>
                                        <span className="hidden xs:block ml-2">Add IP Address</span>
                                    </button>
                                </div>
                            </div>
                            {
                                (this.state.isLoading) ?
                                    <Bars
                                        className="row"
                                        wrapperStyle={{ marginTop: '150px' }}
                                        color="#3c4b64"
                                        height={55}
                                        width="100%"
                                        visible={this.state.isLoading}
                                    />
                                    :
                                    <Table
                                        columns={[{ name: 'ipAddress', title: 'ip Address' }, { name: 'isBlackList', title: 'isBlackList' }, { name: 'blockIpId', title: 'Actions' }]}
                                        rows={this.state.getBlockIp}
                                        sortingColumns={["ipAddress"]}
                                        pagination={this.state.pagination}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: 'blockIpId',
                                                renderTableData: this.actions.bind(this)
                                            },
                                            {
                                                column: 'isBlackList',
                                                renderTableData: this.activechkbox.bind(this),
                                            },

                                        ]}
                                    />
                            }
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
