//Components
import { encryptionData, decryptionData } from '../Encryption';
import { IsCookieEncryption } from '../AppSetting';
//Library
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Cookies functions 
export const createCookie = (props) => {
    let cookieValue = props.cookieValue;
    let cookieName = props.cookieName;
    if (IsCookieEncryption) {
        cookieValue = encryptionData(cookieValue, 1);
    }
    cookies.set(cookieName, cookieValue,
        {
            path: props.path,
            maxAge: props.expirationTime * 60,
        });
};

export const setCookie = (props) => {
    let cookieValue = props.cookieValue;
    let cookieName = props.cookieName;
    if (IsCookieEncryption) {
        cookieValue = encryptionData(cookieValue, 1);
    }
    cookies.set(cookieName, cookieValue);
}

export function getCookie(cookieName, doNotParse = false) {
    if (IsCookieEncryption) {
        const cookieValue = cookies.get(cookieName, { doNotParse: doNotParse })
        if (cookieValue)
            return decryptionData(cookieValue, true);
    }
    else {
        return cookies.get(cookieName, { doNotParse: doNotParse })
    }
    return undefined;
}

export const getAllCookies = (doNotParse = false) => {
    const cookieValue = cookies.getAll({ doNotParse: doNotParse });
    if (IsCookieEncryption) {
        return decryptionData(cookieValue, true);
    }
    return cookieValue;
};

export const removeCookie = (cookieName, options = {}) => {
    cookies.remove(cookieName, options);
};

export const removeAllCookies = () => {
    Object.keys(cookies.cookies).forEach(element => {
        cookies.remove(element, {});
    });
    localStorage.clear();
};

export const isCookieExist = (cookieName) => {
    const cookieDetail = getCookie(cookieName);
    if (cookieDetail) {
        return true;
    }
    return false;
}