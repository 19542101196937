// Component
import React from 'react';
import Table from '../../components/tables/Table';
// Library
import { Navigate } from 'react-router';
import { Bars } from 'react-loader-spinner';
// Services
import baseComponent from "../../shared/baseComponent"
import Companyservice from "../../services/domainServices/CompanyService"

export default class CompaniesList extends baseComponent {

  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.CompanyServices = new Companyservice();
    this.state = {
      pagination: this.CompanyServices.pagination,
      getCompanies: [],
      setIsLoad: false,
    }
    this.CompanyServices.onUpdateState = this.updateState.bind(this)
  }

  componentDidMount() {
    this.CompanyServices.OngetCompanylist(this.state.pagination)
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold"> Companies</h1>
                </div>
              </div>
              {(this.state.setIsLoad) ?
                <Bars
                  className="row"
                  wrapperStyle={{ marginTop: '150px' }}
                  color="#3c4b64"
                  height={55}
                  width="100%"
                  visible={this.state.setIsLoad} />
                :
                <Table
                  columns={this.CompanyServices.companylistgrid}
                  rows={this.state.getCompanies}
                  sortingColumns={[]}
                  pagination={this.state.pagination}
                  totalCount={this.state.TotalCount}
                  totalResultes={this.state.TotalCount}
                  setPagination={this.CompanyServices.onPageChange.bind(this)} />
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}