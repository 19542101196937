//Components
import { APIURL } from '../services/axiosServices/ApiEndPoints';
import { decryptionAPI } from './Encryption';
import { redirect } from 'react-router-dom';
const axios = require('axios');

// Other function

export const axiosInstance = axios.create({
    baseURL: APIURL
})

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
        false : true
}

const requestHandler = (request) => {
    if (isHandlerEnabled(request)) {
    }
    return request
}

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
)

const errorHandler = (error) => {
    if (isHandlerEnabled(error.config)) {
        if (error.response.status === 403) {
            window.location.href = "/UnAuthorized";
        }
    }
    return Promise.reject({ ...error })
}
const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        response = decryptionAPI(response.data);
    }
    return response
}

export const axiosInstanceWithoutEnrypt = axios.create({
    baseURL: APIURL
})