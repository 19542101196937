import moment from "moment";
import BaseDomainService from "./baseDomainServices";
import { ErrorMessage } from "../../utils/Messages";
import { getValidationRuleList } from '../../utils/validation/generateValidationRule';
import { DateFromat } from "../../utils/dateFormat";


export default class DataImportService extends BaseDomainService {

    //** API Get
    add = this.apiService.importService.admin_AddImport;
    getList = this.apiService.importService.admin_GetImportList;
    getDetails = this.apiService.importService.admin_GetImportDetailsById;


    constructor() {
        super();
        this.actionKey = "importId";
        this.onPaginationUpdate = this.onGetList.bind(this);
    }

    //** Table Grid columns
    gridColmns = [
        { name: 'importTitle', title: 'Import Title' },
        { name: 'ImportFileName', title: 'Import File Name' },
        { name: 'ImportTypeId', title: 'Import TypeId' },
        { name: 'status', title: 'Status' },
        { name: 'startDate', title: 'Start Date ' },
        { name: 'importId', title: 'Actions ' }
    ]

    //** Define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Title",
            fieldName: "importTitle",
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Import Type",
            placeholder: "Select",
            fieldName: "importTypeId",
            isDefault: true,
            onBlur: true,
            options: [
                { name: "Product", id: "1" },
                { name: "Stock", id: "2" },
                { name: "Price", id: "3" },
                { name: "MolProduct", id: "4" }
            ],
            validation: [{ type: "require" }]
        },
        {
            inputType: "file",
            tittle: "Import File",
            fieldName: "importFileName",
            defaultValue: true,
            validation: [{ type: "require" }]
        },
        //** Details Form fields
        {
            inputType: "label",
            tittle: "Finish Date:",
            fieldName: "finishDate",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold'
        },
        {
            inputType: "label",
            tittle: "Import File Name:",
            fieldName: "importFileName",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Import Id:",
            fieldName: "importId",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Import Start Date:",
            fieldName: "importStartDate",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Import Status Type:",
            fieldName: "importStatusType",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Import Type:",
            fieldName: "importType",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Import TypeId:",
            fieldName: "importTypeId",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Total Time to Finish:",
            fieldName: "totalTimeToFinish",
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "No of Error In Records:",
            fieldName: "noOfErrorInRecords",
            formType: true,
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "No of New Records:",
            fieldName: "noOfNewRecords",
            formType: true,
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },
        {
            inputType: "label",
            tittle: "Total Records:",
            fieldName: "totalRecords",
            formType: true,
            detailsField: true,
            labelClass: 'text-x leading-snug text-slate-500 font-bold',
        },



    ]

    //** API Calls
    onAdd = (request) => {
        this.apiService.onAPICall("add", this.add, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetList = (request) => {
        this.apiService.onAPICall("getList", this.getList, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetDetails = (request) => {
        this.apiService.onAPICall("getDetail", this.getDetails, request, this.onAPIResponse, this.onUpdateState);
    }



    //** Service Event */
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }


    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "add":
                if (responseData.errorType === "EXISTS") {
                    this.onUpdateState({
                        "isBtnLoading": false,
                        "setIsLoad": false,
                    });
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.Already_Exist.replace("{0}", "Excel Sheet"));
                }
                else if (responseData.errorType === "Invalid Column") {
                    this.onUpdateState({
                        "isBtnLoading": false,
                        "setIsLoad": false,
                    });
                    this.apiService.serviceManager.swalServices.Toaster("The column are invalid");
                }
                else {
                    this.onUpdateState({
                        "dataList": responseData,
                        "isBtnLoading": false,
                        "setIsLoad": false,
                    });
                    this.apiService.serviceManager.swalServices.Success();
                }
                break;
            case "getList":
                const itemList = responseData.itemList;
                itemList.forEach(function (i) {
                    i.importTitle = i.importTitle ? i.importTitle : "-"
                    i.importFileName = i.importFileName ? i.importFileName : "-"
                    i.importTypeId = i.importTypeId ? i.importTypeId : "-"
                    i.status = i.importStatusType ? i.importStatusType : "-";
                    i.startDate = i.startDate ? DateFromat(i.startDate) : "-"
                })
                this.onUpdateState({
                    "getImport": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "setIsLoad": false
                });
                break;
            case "getDetail":
                if (responseData == null) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.NoRecordFound.replace("{0}", "Import Data"));
                }
                responseData.finishDate = responseData.finishDate ? DateFromat(responseData.finishDate) : "-"    ;
                responseData.totalTimeToFinish = responseData.totalTimeToFinish ? DateFromat(responseData.totalTimeToFinish) : "-"    ;
                responseData.importStartDate = responseData.importStartDate ? DateFromat(responseData.importStartDate) : "-"    ;
                this.onUpdateState({
                    "importDetails": responseData,
                    "setIsLoad": false
                });
                break;
        }
    }
}
