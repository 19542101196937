import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);




function BubbleChart(props) {

  const options = {
    elements: {
      bar: {
        borderWidth: 3,
      },
      color: "black",
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 15,
          },
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Bubble Chart",
        font: {
          size: 15,
        },
        color: "black",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },

    // canvas: {
    //   // height: props.height || null, // Set canvas height dynamically or use null for default
    //   // width: props.size || null,   // Set canvas width dynamically or use null for default
    // },
  };

  const labels = props.data?.map(entry => entry.label) ?? [];
  const dataValues = props.data?.map(entry => entry.data) ?? [];

  const data = {
    datasets: [
      {
        label: props.title,
        data: [
          dataValues
        ],
        borderColor: "rgba(255, 99, 132, 0.5)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 1,
      }
    ],
  };

  return <Bubble  data={data} options={options}
  // width={props.size}

  />;
}

export default BubbleChart;