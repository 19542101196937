//Component
import React from 'react';
import BaseComponent from "../../shared/baseComponent";

//Library
import { Navigate } from 'react-router';
import FormFieldGroup from "../../components/FormGroup/FormFieldGroup";

//Services
import authenticationServices from "../../services/domainServices/authenticationServices";

//Images
import AuthImage from '../../images/9.jpg';
import AuthDecoration from '../../images/auth-decoration.png';

class Login extends BaseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props);
    this.authenticatinService = new authenticationServices();
    this.authenticatinService.onUpdateState = this.updateState.bind(this);
    this.state = {
      ...this.state,
      redirect: "",
      setIsLoad : false,
      userDetails: this.authenticatinService.dataItem,
    };
  }
  componentDidMount() {
    this.authenticatinService.onInitService();
    document.title = "Login";
  }

  redirectToHome = () => {
    this.setState({ redirect: "/" });
  };

  // API function
  login = () => {
    if (
      this.authenticatinService.isValidSubmit(
        this.state.userDetails,
        this.state.validationRules,
        this.state.validState
      )
    ) {
      this.authenticatinService.onAdd(this.state.userDetails);
    }
  };

  // Event function
  loginOnEnter = (e) => {
    if (e.charCode === 13) {
      this.login();
    }
  };

  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetails };
    newUserDetails[key] = value;
    this.setState({ userDetails: newUserDetails });
  };

  render() {
    if (this.props.authDetails) {
      return <Navigate to={"/Dashboard"} />
    }
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <main className="bg-white">
        <div className="relative md:flex">
          <div className="md:w-1/2">
            <div className="min-h-screen h-full flex items-center login-back-color">
              <div className='margin-left-25 px-4 table-login ui-width w-full login-card pad-login-add'>
                <div className="logo-img text-center">
                  <img className="mx-auto mb-4" src="/ONECLICK.png" alt="Logo"></img>
                </div>
                <h1 className="text-3xl text-slate-800 font-bold mb-6 text-center">
                  Login to 1C Admin!
                </h1>
                <div className="space-y-4">
                  {this.authenticatinService.formFields.map((Item, index) => {
                    return (
                      <>
                        <FormFieldGroup
                          column={Item}
                          value={this.state.userDetails[Item.fieldName]}
                          error={this.state.validState.error[Item.fieldName]}
                          onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                          onKeyPress={this.loginOnEnter}
                          onBlur={() => this.authenticatinService.validateField(Item.fieldName, this.state.userDetails, this.state.validationRules, this.state.validState)} />
                      </>
                    );
                  })}
                </div>
                <div className="flex items-center justify-center mt-10">
                  <button className="btn commanColorbtn text-white ml-3" onClick={() => this.login()}>
                  {this.state.setIsLoad ? <div class="loader"></div> : <><span> Login</span></>}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
            <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
            <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
          </div>
        </div>
      </main>
    )
  }
}

export default Login;
