import React from "react";

const FileField = (request) => {
    let props = request.request;
    let column = props.column;
    return (<>
        <input
          className="form-input w-full file"
          type={props.column.inputType}
          onChange={props.onChange}
          onBlur={props.onBlur}
          id={props.column.fieldName}
          name={props.column.fieldName}
          ref={props.ref}
          defaultValue={props.defaultValue} />
        </>)
}


export default FileField;
