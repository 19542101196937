import React, { Component } from "react";
import DataNotFound from "../../components/DataNotFound";
import Daily from "./Daily";
import Monthly from "./Monthly";
import Weekly from "./Weekly";

export default class NewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: 1,
    };
  }

  setOpenTab = (tab) => {
    this.setState({ openTab: tab });
  };

  render() {
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-5  w-full max-w-9xl mx-auto bg-white  rounded-sm border border-slate-200 mb-5">
              <div className="custom-tab-section">
                  <div className="flex flex-wrap w-full border-b border-[#1e293b] mt-2">
                    <div className="flex 2xl:justify-center lg:justify-center w-full ">
                      <div
                        className="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-12 gap-2 flex-row xl:w-full lg:w-full w-full mobile-view-tab-part"
                        role="tablist"
                      >
                        <div
                          className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 last:mr-0 flex-auto text-center chapter-e-sec `}
                        >
                          <a
                            className={
                              this.state.openTab === 1
                                ? "bg-[#091934] tab-background2 inner-title-font-small-button font-bold p-2 py-0 flex items-center justify-center h-full"
                                : " inner-title-font-small-button font-bold py-2 text-[#1e293b]  flex items-center justify-center h-full"
                            }
                            onClick={(e) => {
                              this.setOpenTab(1);
                            }}
                            data-toggle="tab"
                            //   href="#"
                            role="tabdivst"
                          >
                            Daily
                          </a>
                        </div>
                        <div
                          className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 mx-2 mr-2 last:mr-0 flex-auto text-center chapter-e-sec`}
                        >
                          <a
                            className={
                              this.state.openTab === 2
                                ? "bg-[#091934] tab-background2 inner-title-font-small-button  font-bold p-2 flex items-center justify-center h-full"
                                : " inner-title-font-small-button font-bold  py-2 text-[#1e293b]  flex items-center justify-center h-full"
                            }
                            onClick={(e) => {
                              this.setOpenTab(2);
                            }}
                            data-toggle="tab"
                            //   href="#"
                            role="tabdivst"
                          >
                            Weekly
                          </a>
                        </div>
                        <div
                          className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 mx-2 mr-2 last:mr-0 flex-auto text-center chapter-e-sec`}
                        >
                          <a
                            className={
                              this.state.openTab === 3
                                ? "bg-[#091934]  tab-background2 inner-title-font-small-button  font-bold p-2 flex items-center justify-center h-full"
                                : " inner-title-font-small-button font-bold py-2 text-[#1e293b]  flex items-center justify-center h-full"
                            }
                            onClick={(e) => {
                              this.setOpenTab(3);
                            }}
                            data-toggle="tab"
                            //   href="#"
                            role="tabdivst"
                          >
                            Monthly
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.openTab === 1
                        ? "block"
                        : this.state.openTab === 2
                        ? "block"
                        : this.state.openTab === 3
                        ? "block"
                        : "hidden"
                    }
                    id="link1"
                  >
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 ">
                        <div className="user-details-section pad-0-new">
                          <div className="pt-3 relative h-full w-full min-h-[100px]">
                            {this.state.openTab === 1 ? (
                              <Daily />
                            ) : this.state.openTab === 2 ? (
                              <Weekly />
                            ) : this.state.openTab === 3 ? (
                              <Monthly />
                            ) : (
                              <div className="py-12">
                                <DataNotFound />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
