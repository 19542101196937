//Components
import { axiosPostAuthorize, axiosGetAuthorize, axiosPost , axiosGet, axiosGetMultiParamsFileAuthorizeblob } from '../AxiosRequests';
import {
    Admin_GetAllProductsList, Admin_UpdateProductDetailsById,
    Admin_GetDeliveryAvailibilityByProductId, Admin_UpdateProductDeliveryTime,
    Admin_GetProductsDetailsById, GetAllAvailability, Admin_UpdateProductPriceById,
    GetSafetyDetailsById, Admin_UpdateProductSafetyDetailsById, GetProductPropertyById, DeleteSafetyDetailsById, DeleteStockById,
    Admin_UpdateProductPropertiesById, Admin_AddProductPropertiesById, Admin_AddProductSafetyDetailsById, Admin_GetPriceDetailsByProductId,
    GetAllCurrencies, GetAllSizes, Admin_AddPriceById, Admin_AddStockById, Admin_GetStockAvailibilityByProductId, Admin_UpdateProductStockById, 
    GetAllStockLocation, DeleteProductPriceById, UpdateProductPriceDetails , AdminGetAllEmptyProductCodeCounts , UpdateRandonNumberForProductCode, ExportSupplierDirectLink
} from '../ApiEndPoints';

export default class ProductServices {

    // API function
    async get_Admin_GetAllProductsList(request) {
        return axiosPostAuthorize(Admin_GetAllProductsList, request);
    }

    async admin_UpdateProductDetailsById(request) {
        return axiosPostAuthorize(Admin_UpdateProductDetailsById, request);
    }

    async admin_GetDeliveryAvailibilityByProductId(request) {
        return axiosGetAuthorize(Admin_GetDeliveryAvailibilityByProductId, request);
    }

    async admin_updateProductDeliveryTime(request) {
        return axiosPostAuthorize(Admin_UpdateProductDeliveryTime, request);
    }

    async admin_UpdateProductPriceById(request) {
        return axiosPostAuthorize(Admin_UpdateProductPriceById, request);
    }

    async admin_GetProductsDetailsById(request) {
        return axiosGetAuthorize(Admin_GetProductsDetailsById, request);
    }

    async getAllAvailability(request) {
        return await axiosGetAuthorize(GetAllAvailability, request);
    }
    async getSafetyDetailsById(request) {
        return axiosGetAuthorize(GetSafetyDetailsById, request)
    }
    async Admin_UpdateProductSafetyDetailsById(request) {
        return axiosPostAuthorize(Admin_UpdateProductSafetyDetailsById, request);
    }
    async getProductPropertyById(request) {
        return axiosGetAuthorize(GetProductPropertyById, request)
    }
    async admin_UpdateProductPropertiesById(request) {
        return axiosPostAuthorize(Admin_UpdateProductPropertiesById, request);
    }
    async admin_AddProductPropertiesById(request) {
        return axiosPostAuthorize(Admin_AddProductPropertiesById, request);
    }
    async Admin_AddProductSafetyDetailsById(request) {
        return axiosPostAuthorize(Admin_AddProductSafetyDetailsById, request);
    }
    async Admin_AddPriceById(request) {
        return axiosPostAuthorize(Admin_AddPriceById, request);
    }

    async getSizes(request) {
        return axiosGetAuthorize(GetAllSizes, request);
    }

    async getAllCurrencies(request) {
        return axiosGetAuthorize(GetAllCurrencies, request);
    }
    async admin_AddStockById(request) {
        return axiosPostAuthorize(Admin_AddStockById, request);
    }
    async admin_GetStockAvailibilityByProductId(request) {
        return axiosGetAuthorize(Admin_GetStockAvailibilityByProductId, request);
    }
    async admin_UpdateProductStockById(request) {
        return axiosPostAuthorize(Admin_UpdateProductStockById, request);
    }
    async getAllStockLocation(request) {
        return axiosGetAuthorize(GetAllStockLocation, request);
    }
    async UpdateProductPriceDetails(request) {
        return axiosPost(UpdateProductPriceDetails, request);
    }
    async admin_GetPriceDetailsByProductId(request) {
        return axiosGetAuthorize(Admin_GetPriceDetailsByProductId, request);
    }
    async admin_AddPriceById(request) {
        return axiosPostAuthorize(Admin_AddPriceById, request);
    }
    async deleteProductPriceById(request) {
        return axiosPostAuthorize(DeleteProductPriceById, request);
    }
    async deleteSafetyDetailsById(request) {
        return axiosPostAuthorize(DeleteSafetyDetailsById, request);
    }
    async deleteStockById(request) {
        return axiosPostAuthorize(DeleteStockById, request);
    }

    async adminGetAllEmptyProductCodeCounts(request) {
        return axiosGet(AdminGetAllEmptyProductCodeCounts, request);
    }

    async updateRandonNumberForProductCode(request) {
        return axiosPost(UpdateRandonNumberForProductCode, request);
    }

    async exportSupplierDirectLink(request){
        return axiosGetMultiParamsFileAuthorizeblob(ExportSupplierDirectLink, request)
    }
}