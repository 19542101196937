//** Componeent  */
import React from 'react';
import Button from '../common/Button';
import DataNotFound from '../DataNotFound';

//*** LIb */
import Table from '../tables/Table';
import { Navigate } from 'react-router-dom';
import { encryptAES } from "../../utils/Encryption";
import { ButtonTypes } from '../../utils/Constants';
import { checkParameters } from "../../utils/Encryption";
import { ImageURLGenerator, ImageTypes } from "../../utils/ImageURLGenerator";

//Services
import baseComponent from "../../shared/baseComponent";
import UserService from '../../services/domainServices/userService';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import ButtonGroup from '../common/ButtonGroup';


export default class UserDetails extends baseComponent {

  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.userService = new UserService();
    this.state = {
      ...this.state,
      pagination: this.userService.pagination,
      addUserDetails: this.userService.dataItem,
      getUserSearch: [],
      getRFQSearch: [],
      allCountries: [],
      dataListData: [],
      getOrderSearch: [],
      getshoppingcart: [],
      shippingAddresses: [],
      billingAddresses: [],
      totalPrice: 0,
      openTab: 1,
      setIsLoad: false
    }
    this.userService.onUpdateState = this.updateState.bind(this);
    this.userService.onActionCall = this.onActionCall.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangedefault = this.handleChangedefault.bind(this);
  }

  componentDidMount() {
    this.userService.onInitService();
    let params = new URLSearchParams(window.location.search);
    let userId = params.get("");
    if (userId && (userId = checkParameters(userId, "Number"))) {
      if (userId > 0) {
        let request = {
          ...this.state.pagination,
          userId: userId
        }
        this.userService.onGetSearchList(request);
        this.userService.onGetUserProfileById(userId);
        this.userService.onGetOrderDetails(request);
        this.userService.ongetUserActivityLogByUserId(userId);
        this.userService.onGetrfqDetails(request);
        this.userService.onGetShoppingCartProductsByUser(userId);
        this.userService.onGetAddress(request);
        this.userService.onGetCountries();
      }
    }
  }

  updateUserDetail = () => {
    let request = {
      userId: this.state.addUserDetails.userId,
      userName: this.state.addUserDetails.userName,
      firstName: this.state.addUserDetails.firstName,
      lastName: this.state.addUserDetails.lastName,
      phoneNo: this.state.addUserDetails.phoneNo,
      designation: this.state.addUserDetails.designation,
      countryId: this.state.addUserDetails.countryId,
      updatedBy: 1,
      isActive: this.state.addUserDetails.isActive,
      companyName: this.state.addUserDetails.companyName,
      updatedDate: ""
    }
    if (this.userService.isValidSubmit(this.state.addUserDetails, this.state.validationRules, this.state.validState)) {
      this.userService.onUpdate(request);
    }
  }
  onResetLink = () => {
    let user = this.state.addUserDetails;
    let tempUser = user
    this.userService.apiService.serviceManager.swalServices.Confirm("Are you sure, you want to reset User Password ?", " " + "", "Yes", "No")
      .then(async confirm => {
        if (confirm) {
          let request = { userName: tempUser.userName }
          this.userService.onResetLink(request);
        }
      });
  };

  handleChange(name, value) {
    let detail = this.state.addUserDetails;
    detail[name] = (name === "firstName" || name === "lastName") ? value.trim().replace(/^\s+/, '') : value;
    this.setState({ addUserDetails: { ...detail } });
  }

  handleChangedefault(e) {
    const { name, checked } = e.target;
    let detail = this.state.addUserDetails;
    detail[name] = checked;
    this.setState({ addUserDetails: { ...detail } });
  }

  addDefaultSrc = (event) => {
    event.target.src = "/NoStructure.png";
  };

  onActionCall = (type, data) => {
    switch (type) {
      case "Edit":
        this.setState({ redirect: '/Order/OrderId?=' + encryptAES(data) });
        break;

      case "RFQEdit":
        this.setState({ redirect: '/RFQDetail/RFQId?=' + encryptAES(data) });
        break;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <>
        <div className="flex h-screen overflow-hidden">
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className='flex items-center justify-between'>
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">User Detail </h1>
                </div>
                <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.userService.onRedirectoList()} />
              </div>
              <div className='px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 p-5 mt-3'>
                <div className="grid sm:gap-3 md:grid-cols-2">
                  {this.userService.formFields.map((Item, index) => (
                    Item.inputType != 'search' ?
                      <>
                        {
                          Item.usercheked === "yes" ? null :
                            <FormFieldGroup
                              column={Item}
                              value={this.state.addUserDetails[Item.fieldName]}
                              error={this.state.validState.error[Item.fieldName]}
                              onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                              onBlur={() => this.userService.validateField(Item.fieldName, this.state.addUserDetails, this.state.validationRules, this.state.validState)}
                              onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                              key={index}
                              options={Item.options ? this.state.allCountries : null}
                              defaultValue={Item.defaultValue === true ? this.state.addUserDetails[Item.fieldName] : null}
                              checked={Item.checked === true ? this.state.addUserDetails[Item.fieldName] : null} />
                        }
                      </>
                      : null
                  ))}
                </div>
                <div>
                  {this.userService.formFieldsCheck.map((Item, index) => {
                    return (
                      <>
                        {Item.checked ? (
                          <FormFieldGroup
                            column={Item}
                            value={this.state.addUserDetails[Item.fieldName]}
                            onChange={(event) => this.handleChange(Item.fieldName, event.target.checked)}
                            checked={this.state.addUserDetails[Item.fieldName]}
                            key={index}
                          />
                        ) : null}
                      </>
                    );
                  }
                  )}
                </div>
                <div className="flex flex-wrap sm:justify-end justify-center">
                  <ButtonGroup
                    onCancelClick={() => this.userService.onRedirectoList()}
                    onUpdateClick={() => this.updateUserDetail()}
                    updateState={1}
                    resetState={2}
                    onResetPass={this.onResetLink}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200">
                <div className='custom-tab-section'>
                  <div>
                    <div className="flex flex-wrap w-full border-b border-[#1e293b] mt-4">
                      <div className="flex 2xl:justify-center lg:justify-center w-full ">
                        <div className="grid lg:grid-cols-6 md:grid-cols-6 grid-cols-12 gap-6 flex-row xl:w-full lg:w-full w-full mobile-view-tab-part" role="tablist">
                          {this.userService.tabsFields.map((Item, index) => {
                            return (
                              <FormFieldGroup
                                state={this.state.openTab}
                                column={Item}
                                onClick={(e) => { this.setState({ openTab: Item.activeTab }) }}
                                key={index} />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className={this.state.openTab === 1 ? "block" : this.state.openTab === 2 ? "block" : this.state.openTab === 3 ? "block"
                      : this.state.openTab === 4 ? "block" : this.state.openTab === 5 ? "block" : this.state.openTab === 6 ? "block" : "hidden"} id="link1">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 ">
                          <div className="user-details-section ">
                            <div className="sm:p-3 p-0 relative h-full w-full min-h-[100px]">
                              {this.state.getRFQSearch.length >= 0 && this.state.openTab === 1 ? (
                                <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                                  <Table
                                    columns={this.userService.RFQgridColmns}
                                    rows={this.state.getRFQSearch}
                                    sortingColumns={'null'}
                                    pagination={'null'}
                                    totalCount={'null'}
                                    totalResultes={'null'}
                                    setPagination={'null'}
                                    customScope={[
                                      {
                                        column: 'rfqId',
                                        renderTableData: this.userService.gridaRFQctions.bind(this)
                                      }
                                    ]} />
                                </div>
                              )
                                : this.state.getOrderSearch.length >= 0 && this.state.openTab === 2 ? (
                                  <>
                                    <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                                      <Table
                                        columns={this.userService.OrdergridColmns}
                                        rows={this.state.getOrderSearch}
                                        sortingColumns={'null'}
                                        pagination={'null'}
                                        totalCount={'null'}
                                        totalResultes={'null'}
                                        setPagination={'null'}
                                        customScope={[
                                          {
                                            column: 'orderId',
                                            renderTableData: this.userService.gridOrderactions.bind(this)
                                          }
                                        ]} />
                                    </div>
                                  </>
                                ) : this.state.getUserSearch.length >= 0 && this.state.openTab === 3 ? (
                                  <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                                    <Table
                                      columns={this.userService.SearchHistorygridColmns}
                                      rows={this.state.getUserSearch}
                                      sortingColumns={'null'}
                                      pagination={'null'}
                                      totalCount={'null'}
                                      totalResultes={'null'}
                                      setPagination={'null'} />
                                  </div>
                                )
                                  : this.state.getshoppingcart.length >= 0 && this.state.openTab === 4 ? (
                                    <div className='mt-5'>
                                      <div className="shopping-cart-table-sec">
                                        <div className="overflow-x-auto">
                                          <table className="w-full bg-white shadow-lg rounded-md fixed_header">
                                            <thead className="text-[15px] capitalize text-white bg-[#1e293b] sidemenucolor rounded-sm">
                                              <tr className="flex">
                                                <th className="p-3 whitespace-nowrap">
                                                  <div className="font-bold text-left">
                                                    Product Image
                                                  </div>
                                                </th>
                                                <th className="p-3 whitespace-nowrap">
                                                  <div className="font-bold text-left">
                                                    Product Details
                                                  </div>
                                                </th>
                                                <th className="p-3 whitespace-nowrap">
                                                  <div className="font-bold text-left">
                                                    Price &#215; Size
                                                  </div>
                                                </th>
                                                <th className="p-3 whitespace-nowrap">
                                                  <div className="font-bold text-left">
                                                    Quantity
                                                  </div>
                                                </th>
                                                <th className="p-3 whitespace-nowrap">
                                                  <div className="font-bold text-left">
                                                    Total Amount
                                                  </div>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="text-sm font-medium ">
                                              {this.state.getshoppingcart.map((e, key) => {
                                                return (
                                                  <tr className="table-tr-custom flex items-center" key={key}>
                                                    <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                      <div className="cart-image1">
                                                        <img src={this.addDefaultSrc} width="200" alt="" />

                                                        <img width="200" alt=''
                                                          src={ImageURLGenerator(
                                                            ImageTypes.ProductDetails,
                                                            e.imageName,
                                                          )}
                                                          onError={this.addDefaultSrc}
                                                        />
                                                        <div className="product-availability">
                                                          <div className="stockStatus in-stock">
                                                            <i className=" icon-sm far fa-check mt-1 backorder"></i>
                                                            {e.availabilityType}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                      <div className='q_section'>
                                                        <div className="text-truncate pointer product-title bold-text mb-2" >
                                                          {e.productName}
                                                        </div>
                                                        <div className="card_details">
                                                          <p className='mb-1'>
                                                            <span>Catalog :</span> {e.aurumCatalogId}</p>
                                                          <p className='mb-1'>
                                                            <span>CAS :</span> {e.casNo}
                                                          </p>
                                                          <p className='mb-1'>
                                                            <span>MDL :</span> {e.mdlNo}
                                                          </p>
                                                          <p className='mb-1'>
                                                            <span>Size :</span> {e.quantity} {e.size}
                                                          </p>
                                                          <p className='mb-1'>
                                                            <span>MW :</span> {e.mw}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                      <div className="font-bold">
                                                        <span> $ {e.price.toFixed(2)}</span> &#215; {e.quantity} {e.size}
                                                      </div>
                                                    </td>
                                                    <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                      <div className="font-bold">
                                                        <span>{e.quantityAmount}</span>
                                                      </div>
                                                    </td>
                                                    <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                      <div className="font-bold">
                                                        $ {e.price.toFixed(2) * e.quantityAmount}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div className='total-cart-amount pt-3 border-b border-[#1e293b]'>
                                        <div className='main-title mb-2 pb-3  border-b border-[#1e293b]'>
                                          <h2 className='font-bold text-[#1e293b] text-[22px]'>
                                            Cart Total
                                          </h2>
                                        </div>
                                        <div className='pr-2'>
                                          <p className='text-[16px] mb-2 flex items-center justify-between'>
                                            <span className='font-bold text-[#1e293b]'>Total Products :</span>
                                            <span className='font-bold text-[#1e293b]'>{this.state.getshoppingcart.length}</span>
                                          </p>
                                          <p className='text-[16px] mb-2 flex items-center justify-between'>
                                            <span className='font-bold text-[#1e293b]'> Total : </span>
                                            <span className='font-bold text-[#1e293b]'> $ {this.state.totalPrice.toFixed(2)}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                    :
                                    this.state.billingAddresses.length >= 0 && this.state.shippingAddresses.length >= 0 && this.state.openTab === 5 ? (
                                      <>
                                        <div className='position-relative mb-4'>
                                          <h4 className="line-title mb-4"><strong>Billing Addresses</strong>  </h4>
                                        </div>
                                        <div className=' grid-col-4 gap-6 address_word_breaking'>
                                          <div className='col-span-4'>
                                            <div className="grid grid-cols-12 gap-2 mt-4 ">
                                              {this.state.billingAddresses != null ? this.state.billingAddresses.map((add, index) => <>
                                                <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
                                                  <div className="p-3">
                                                    <div>
                                                      <ul className="my-1">
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center"> {add.addressName}:</div>
                                                            </div>
                                                          </div>
                                                        </li>
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center">  <i className="icon-sm fal fa-user fw-bold pe-2 addressIcon mr-2"></i>{add.attendantName}</div>

                                                            </div>
                                                          </div>
                                                        </li>
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center"> <i className="icon-sm fal fa-map-marker fw-bold pe-2 addressIcon mr-2"></i>  {add.addressLine1} ,{add.addressLine2},{add.city},{add.stateName},{add.countryName}-{add.zipCode}</div>

                                                            </div>
                                                          </div>
                                                        </li>
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center"><i className="icon-sm fal  fa-phone fw-bold pe-2 addressIcon mr-2"></i> {add.attendantPhoneNo}</div>

                                                            </div>
                                                          </div>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>) : null}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='position-relative mt-4'>
                                          <h4 className="line-title mb-4"><strong>Shipping Addresses</strong>  </h4>
                                        </div>
                                        <div className=' grid-col-4 gap-6 address_word_breaking'>
                                          <div className='col-span-4'>
                                            <div className="grid grid-cols-12 gap-2 mt-4 ">
                                              {this.state.shippingAddresses != null ? this.state.shippingAddresses.map((add, index) => <>
                                                <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
                                                  <div className="p-3">
                                                    <div>
                                                      <ul className="my-1">
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center"> {add.addressName}:</div>
                                                            </div>
                                                          </div>
                                                        </li>
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center">  <i className="icon-sm fal fa-user fw-bold pe-2 addressIcon mr-2"></i>{add.attendantName}</div>

                                                            </div>
                                                          </div>
                                                        </li>
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center"> <i className="icon-sm fal fa-map-marker fw-bold pe-2 addressIcon mr-2"></i>  {add.addressLine1} ,{add.addressLine2},{add.city},{add.stateName},{add.countryName}-{add.zipCode}</div>

                                                            </div>
                                                          </div>
                                                        </li>
                                                        <li className="flex px-2">
                                                          <div className="grow flex items-center border-b border-slate-100 text-sm py-2">
                                                            <div className="grow flex justify-between">
                                                              <div className="self-center"><i className="icon-sm fal fa-phone fw-bold pe-2 addressIcon mr-2"></i> {add.attendantPhoneNo}</div>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) :
                                      this.state.dataListData.length >= 0 && this.state.openTab === 6 ? (
                                        <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                                          <Table
                                            columns={this.userService.gridColmnsDetails}
                                            rows={this.state.dataListData}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.userService.onPageChange.bind(this)}
                                          />
                                        </div>) :
                                        <>
                                          <div className='py-12'>
                                            <DataNotFound />
                                          </div>
                                        </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </>
    )
  }
}

