//** Componeent  */
import React from "react";
//*** LIb */
import { ButtonTypes } from "../../utils/Constants";

const Button = (props) => {
  return props.type === ButtonTypes.Primary ? (
    <button
      className={`btn buttonLeft commanColorbtn text-white ${
        props.isLoading ? "disabled:cursor-not-allowed" : ""
      }`}
      onClick={() => props.buttonClick()}
      disabled={props.isLoading}
    >
      {props.isLoading ? (
        <svg
          className="animate-spin w-4 h-4 fill-current shrink-0"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
      ) : (
        <span className={`${props.isLoading ? "ml-1" : ""}`}>
          {props.title}
        </span>
      )}
    </button>
  ) : props.type === ButtonTypes.Secondary ? (
    <button
      className={`btn border-slate-200 hover:border-[#0f1e35] new-color ${
        props.isLoading ? "disabled:cursor-not-allowed" : ""
      }`}
      onClick={() => props.buttonClick()}
      disabled={props.isLoading}
    >
      {props.isLoading ? (
        <svg
          className="animate-spin w-4 h-4 fill-current shrink-0"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
      ) : (
        <span className={`${props.isLoading ? "ml-1" : ""}`}>
          {props.title}
        </span>
      )}
    </button>
  ) : (
    <button
      className="btn  border-slate-200 hover:border-slate-300 text-slate-600 bcolor Search-height SerchBox-center"
      onClick={() => props.buttonClick()}
    >
      {props.title}
    </button>
  );
};

export default Button;
