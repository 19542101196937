/* Component  */
import React from 'react';
/* Library  */
import { Navigate } from 'react-router-dom';
/* Services  */
import baseComponent from "../../shared/baseComponent";
import ProductdashboardService from '../../services/domainServices/productdashboardService';
import CountUp from 'react-countup';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import Suspended from '../../images/suspended.png';
import Instock from '../../images/instock.png';
import Backorder from '../../images/backorder.png';
import Production from '../../images/production.png';
import { Bars } from 'react-loader-spinner';

export default class ProductDashboard extends baseComponent {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        this.productdashboardService = new ProductdashboardService();
        this.state = {
            ProductCountList: [],
            ProductCount: [],
            ProductInActiveCount: [],
            TotalBackordered: [],
            TotalInProduction: [],
            TotalInStock: [],
            TotalSuspended: [],
            setIsLoad: false
        }
        this.productdashboardService.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.productdashboardService.onGetproductCountList();
        document.title = 'User List';
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (

            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-5 lg:px-5 py-2 w-full max-w-9xl mx-auto">
                            <div className="sm:flex sm:justify-between sm:items-center mb-2">
                                <div className="sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Product Dashboard</h1>
                                </div>
                            </div>

                            <div className='product-form top-1'>

                                <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-4">
                                    {this.productdashboardService.cardFormFields.map((Item, index) => {
                                        return (
                                            <FormFieldGroup
                                                column={Item}
                                                value={this.state.ProductCount[Item.fieldName]} key={index} />
                                        )
                                    })}
                                </section>

                                {/* <header className=" py-4 border-b border-slate-100">
                                <h2 className="font-semibold text-slate-800">Availability</h2>
                            </header> */}

                                <div className='product-form top-1 mt-3 mb-2'>
                                    <div className="mb-5 sm:mb-0 ">
                                        <h3 className="text-slate-800 font-bold text-[20px]">Product Availability</h3>
                                    </div>
                                    {
                                        (this.state.setIsLoad) ?
                                            <Bars
                                                className="row"
                                                wrapperStyle={{ marginTop: '150px' }}
                                                color="#3c4b64"
                                                height={55}
                                                width="100%"
                                                visible={this.state.setIsLoad} />
                                            :
                                            <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm mt-3 productdashboard  activity_details">
                                                <div className="p-5">
                                                    <div className="">
                                                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6'>
                                                            <div className='flex p-6 gap-2 useractivitydetails'>
                                                                <label>Total Backordered : {this.state.TotalBackordered.totalBackordered}</label>
                                                            </div>
                                                            <div className='flex p-6 gap-2 useractivitydetails'>
                                                                <label>Total In Production : {this.state.TotalInProduction.totalInProduction}</label>
                                                            </div>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4'>
                                                            <div className='flex p-6 gap-2 useractivitydetails'>
                                                                <label>Total In Stock : {this.state.TotalInStock.totalInStock}</label>
                                                            </div>
                                                            <div className='flex p-6 gap-2 useractivitydetails'>
                                                                <label>Total Suspended : {this.state.TotalSuspended.totalSuspended} </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                    }

                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
