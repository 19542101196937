import React from 'react'
import Button from '../../components/common/Button';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup'
import SettingServices from '../../services/domainServices/settingService';
import { ButtonTypes } from '../../utils/Constants';
import BaseComponent from "../../shared/baseComponent";
import { checkParameters } from "../../utils/Encryption";
import { Navigate } from 'react-router-dom';
import Table from '../../components/tables/Table';
import { Bars } from 'react-loader-spinner';
import CountUp from 'react-countup';
import ButtonGroup from '../../components/common/ButtonGroup';

export default class IpAddressDetails extends BaseComponent {
    constructor(props) {
        super(props)
        this.settingService = new SettingServices();
        this.state = {
            ...this.state,
            ViewIpDetails: this.settingService.dataItem,
            pdId: 0, UserList: [],
            ProductsCount: [],
            pagination: this.settingService.pagination,

        }
        this.settingService.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.settingService.onInitService();
        let params = new URLSearchParams(window.location.search);
        let ipAddressesId = params.get("");
        if (ipAddressesId && (ipAddressesId = checkParameters(ipAddressesId, "Number"))) {
            if (ipAddressesId > 0) {
                this.settingService.ongetIpAddressById(ipAddressesId);
                this.settingService.ongetProductsSearchByIpAddress(ipAddressesId);
                this.setState({ pdId: ipAddressesId })
            }
        }
    }

    updateUserDetail = () => {
        if (this.settingService.isValidSubmit(this.state.ViewIpDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                iPAddressesId: this.state.pdId,
                country: this.state.ViewIpDetails.country,
                countryCode: this.state.ViewIpDetails.countryCode,
                city: this.state.ViewIpDetails.city,
                isp: this.state.ViewIpDetails.isp,
                regionName: this.state.ViewIpDetails.regionName,
                latitude: this.state.ViewIpDetails.latitude,
                longitude: this.state.ViewIpDetails.longitude,
            }
            this.settingService.onupdateIpAddress(request);
            this.settingService.onRedirectoList()
        }
    }

    handleChange(name, value) {
        let detail = this.state.ViewIpDetails;
        detail[name] = value;
        this.setState({ ViewIpDetails: { ...detail } });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                            <div className='product-form'>
                                <div className="mb-4 sm:mb-0 IpDetails">
                                    <h2 className="text-2xl md:text-3xl text-slate-800 font-bold">Ip Address Details</h2>
                                    <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.settingService.onRedirectoList()} />
                                </div>
                                <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm mt-3 productdashboard  activity_details">
                                    <div className="p-5">
                                        <div className="">
                                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6'>
                                                <div className='flex p-6 gap-2 useractivitydetails'>
                                                    <label>Total Products Searched  : {this.state.ProductsCount}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                            <div className='px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 p-5 mt-5'>
                                <div className='grid sm:gap-4 md:grid-cols-2'>

                                    {this.settingService.formFields.map((Item, index) => (
                                        <>
                                            {
                                                <FormFieldGroup
                                                    column={Item}
                                                    value={this.state.ViewIpDetails[Item.fieldName]}
                                                    error={this.state.validState.error[Item.fieldName]}
                                                    onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                                    onBlur={() => this.settingService.validateField(Item.fieldName, this.state.ViewIpDetails, this.state.validationRules, this.state.validState)}
                                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                    key={index}
                                                    checked={this.state.ViewIpDetails[Item.fieldName]}
                                                    defaultValue={Item.defaultValue === true ? this.state.ViewIpDetails[Item.fieldName] : null}
                                                />
                                            }
                                        </>
                                    ))}
                                </div>
                                <div className="flex flex-wrap sm:justify-end justify-center">
                                    <ButtonGroup
                                        onCancelClick={() => this.settingService.onRedirectoList()}
                                        onUpdateClick={() => this.updateUserDetail()}
                                        updateState={this.state.pdId}
                                        isLoading={this.state.setIsLoad}
                                    />
                                    {/* <div className="m-1.5">
                                        <Button type={ButtonTypes.Primary} buttonClick={() => this.updateUserDetail()} title="Update" />
                                    </div>
                                    <div className="m-1.5">
                                        <Button type={ButtonTypes.Primary} buttonClick={() => this.settingService.onRedirectoList()} title="Cancel" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border border-slate-200">
                                <header className="px-5 py-4 border-b border-slate-100">
                                    <h2 className="font-semibold text-slate-800 col-md-6">User History </h2>
                                </header>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <Table
                                            columns={this.settingService.gridColumn}
                                            rows={this.state.UserList}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.settingService.onPageChange.bind(this)}
                                        />
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
