import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const wizarddetails = {
  title: "",
  componentWizardType: "",
  queryData: "",
  isActive: false,
  size: "",
  height: "",
  informaticsConfigurationId: 0,
  chartType: "",
  componentWizardId: 0,
};

export default class componetWizardServices extends BaseDomainService {
  addInformaticsComponentWizard =
    this.apiService.ComponetWizardServices.addInformaticsComponentWizard;
  getInformaticsComponentWizardByIdList =
    this.apiService.ComponetWizardServices
      .getInformaticsComponentWizardByIdList;
  getInformaticsComponentWizardById =
    this.apiService.ComponetWizardServices.getInformaticsComponentWizardById;
  updateInformaticsComponentWizard =
    this.apiService.ComponetWizardServices.updateInformaticsComponentWizard;
  deleteInformaticsComponentWizard =
    this.apiService.ComponetWizardServices.deleteInformaticsComponentWizard;
  getComponentWizardsList =
    this.apiService.ComponetWizardServices.getComponentWizardsList;

  formFields = [
    {
      inputType: "text",
      tittle: "Title",
      placeholder: "Title",
      fieldName: "title",
      validation: [{ type: "require" }],
      className: "col-span-6",
    },
    {
      inputType: "select",
      tittle: "Component Wizard Type",
      placeholder: "Select Component Wizard Type",
      fieldName: "componentWizardType",
      options: [{ name: " Charts" }, { name: "Counts" }, { name: "Table" }],
      validation: [{ type: "require" }],
      className: "col-span-6",
    },

    {
      inputType: "select",
      tittle: "Chart Type",
      placeholder: "Select Chart Type",
      fieldName: "chartType",
      options: [
        { name: "BarChart", id: 1 },
        { name: "HorizontalBar Chart", id: 2 },
        { name: "Radar Chart", id: 3 },
        { name: "Pie Chart", id: 4 },
        { name: "Line Chart", id: 5 },
        { name: "Bubble Chart", id: 6 },
      ],
      // optionFieldName: "chartType",
      // validation: [{ type: "require" }]
      className: "col-span-6",
    },
    {
      inputType: "select",
      tittle: "Column Size",
      placeholder: "Column Size",
      fieldName: "size",
      options: [{ name: "1" }, { name: "2" }, 
      { name: "3" }
    ],
      validation: [{ type: "require" }],
      className: "col-span-6",
    },
    // {
    //   inputType: "select",
    //   tittle: "Height",
    //   placeholder: "Height",
    //   fieldName: "height",
    //   options: [
    //     { name: "50" },
    //     { name: "100" },
    //     { name: "150" },
    //     { name: "200" },
    //     { name: "300" },
    //     { name: "400" },
    //     { name: "500" },
    //     { name: "600" },
    //   ],
    //   validation: [{ type: "require" }],
    //   className: "col-span-6",
    // },
    {
      inputType: "checkbox",
      tittle: "IsActive",
      fieldName: "isActive",
      checkLabelClass: "ml-2",
      checked: true,
      isUpdate: true,
      className: "col-span-4",
    },
    {
      inputType: "textarea",
      tittle: "SQL Query Data (for startdate and enddate use parameter '@StartDate' and '@EndDate')",
      errorMessage : "Query (* , HashedPassword , PasswordSalt , IsUsedActivationLink , Password , Query)",
      placeholder: "SQL Query Data",
      fieldName: "queryData",
      validation: [{
        type: "sqlvalidation" } , { type: "require" }
      ],
      className: "col-span-12",
    },
  ];

  gridColmns = [
    { name: "title", title: "Title" },
    { name: "ComponentWizardtype", title: "Wizard Type Name" },
    { name: "IsActive", title: "Status" },
    { name: "componentWizardId", title: "Actions" },
  ];

  gridColmnss = [
    { name: "title", title: "Title" },
    { name: "componentWizardId", title: "Title" },
    { name: "componentWizardType", title: "Title" },
    { name: "createdBy", title: "Title" },
    { name: "createdDate", title: "Title" },
    { name: "informaticsConfigurationId", title: "Title" },
    { name: "isActive", title: "Title" },
    { name: "queryData", title: "Title" },
    { name: "size", title: "Title" },
  ];

  dataItem;

  constructor() {
    super();
    this.onPaginationUpdate =
      this.onGetInformaticsComponentWizardByIdList.bind(this);
    this.actionKey = "componentWizardId";
    this.dataItem = { ...wizarddetails };
  }

  onAddInformaticsComponentWizard = (requst, getRequest) => {
    this.apiService.onAPICall(
      "addInformaticsComponentWizard",
      this.addInformaticsComponentWizard,
      requst,
      this.onAPIResponse,
      this.onUpdateState,
      getRequest
    );
  };

  onGetInformaticsComponentWizardByIdList = (requst) => {
    this.apiService.onAPICall(
      "getInformaticsComponentWizardByIdList",
      this.getInformaticsComponentWizardByIdList,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onGetInformaticsComponentWizardById = (requst) => {
    this.apiService.onAPICall(
      "getInformaticsComponentWizardById",
      this.getInformaticsComponentWizardById,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onUpdateInformaticsComponentWizard = (requst, getRequest) => {
    this.apiService.onAPICall(
      "updateInformaticsComponentWizard",
      this.updateInformaticsComponentWizard,
      requst,
      this.onAPIResponse,
      this.onUpdateState,
      getRequest
    );
  };

  onDeleteInformaticsComponentWizard = (requst, getRequest) => {
    this.apiService.onAPICall(
      "deleteInformaticsComponentWizard",
      this.deleteInformaticsComponentWizard,
      requst,
      this.onAPIResponse,
      this.onUpdateState,
      getRequest
    );
  };

  onGetComponentWizardsList = (requst) => {
    this.apiService.onAPICall(
      "getComponentWizardsList",
      this.getComponentWizardsList,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
    let valRule = getValidationRuleList(this.formFields);
    this.validationRules = valRule;
    this.onUpdateState({
      validationRules: valRule,
    });
  }

  onResetData = () => {
    const initialWizardDetails = { ...wizarddetails };
    this.dataItem = { ...initialWizardDetails };
    let resetValidation = {
      isValid: false,
      error: {},
    };
    this.onUpdateState({
      wizarddetails: this.dataItem,
      validState: resetValidation,
    });
  };

  onAPIResponse = (key, isSuccess, responseData, statePagination) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "addInformaticsComponentWizard":
        if (responseData.hasError == false) {
          this.apiService.swalServices.Toaster(
            SuccessMessage.Add_Success.replace("{0}", "Component Wizard")
          );
          this.onUpdateState({
            setOpenModal: false,
            pageLoader: false,
            setIsLoad: false,
          });

          this.onResetData();
          this.onGetInformaticsComponentWizardByIdList(statePagination);
        } else {
          this.apiService.swalServices.Toaster("The column are invalid");
        }
        break;
      case "getInformaticsComponentWizardByIdList":
        let itemList = responseData.itemList;
        if (responseData !== null) {
          this.onUpdateState({
            dataList: itemList,
            setIsLoad: false,
            TotalCount: responseData.totalCount,
          });
        }
        break;
      case "getInformaticsComponentWizardById":
        let DataById = responseData;
        this.onUpdateState({
          wizarddetails: DataById,
          setIsLoad: false,
        });
        break;
      case "updateInformaticsComponentWizard":
        if (responseData.hasError === false) {
          this.apiService.swalServices.Toaster(
            SuccessMessage.Update_Success.replace("{0}", "Component Wizard")
          );
          this.onUpdateState({
            setOpenModal: false,
            pageLoader: false,
            setIsLoad: false,
          });
          this.onResetData();
          this.onGetInformaticsComponentWizardByIdList(statePagination);
        } else {
          this.apiService.swalServices.Toaster(
            responseData.message.DefaultMessage
          );
        }
        break;
      case "deleteInformaticsComponentWizard":
        this.apiService.serviceManager.swalServices.Success(
          SuccessMessage.Delete_Success.replace("{0}", "Component Wizard")
        );
        this.onUpdateState({
          setOpenModal: false,
          pageLoader: true,
          setIsLoad: false,
        });
        this.onGetInformaticsComponentWizardByIdList(statePagination);
        break;
      case "getComponentWizardsList":
        let dailydataList = [];
        let weeklydataList = [];
        let monthlydataList = [];
        if (responseData != null) {
          responseData?.forEach(function (i) {
            if (i.informaticsConfigurationId === 1) {
              const mappedData = {
                title: i.title,
                size: i.size,
                queryData: i.queryData,
                isActive: i.isActive,
                informaticsConfigurationId: i.informaticsConfigurationId,
                height: i.height,
                createdDate: i.createdDate,
                createdBy: i.createdBy,
                componentWizardType: i.componentWizardType,
                componentWizardId: i.componentWizardId,
                chartType: i.chartType,
                dataList: i.dataList.map((chartData) => ({
                  data: chartData.data,
                  label: chartData.label,
                })),
              };
              dailydataList.push(mappedData);
            }
            if (i.informaticsConfigurationId === 2) {
              const mappedData = {
                title: i.title,
                size: i.size,
                queryData: i.queryData,
                isActive: i.isActive,
                informaticsConfigurationId: i.informaticsConfigurationId,
                height: i.height,
                createdDate: i.createdDate,
                createdBy: i.createdBy,
                componentWizardType: i.componentWizardType,
                componentWizardId: i.componentWizardId,
                chartType: i.chartType,
                dataList: i.dataList.map((chartData) => ({
                  data: chartData.data,
                  label: chartData.label,
                })),
              };
              weeklydataList.push(mappedData);
            }
            if (i.informaticsConfigurationId === 3) {
              const mappedData = {
                title: i.title,
                size: i.size,
                queryData: i.queryData,
                isActive: i.isActive,
                informaticsConfigurationId: i.informaticsConfigurationId,
                height: i.height,
                createdDate: i.createdDate,
                createdBy: i.createdBy,
                componentWizardType: i.componentWizardType,
                componentWizardId: i.componentWizardId,
                chartType: i.chartType,
                dataList: i.dataList.map((chartData) => ({
                  data: chartData.data,
                  label: chartData.label,
                })),
              };
              monthlydataList.push(mappedData);
            }
          });
        }
        this.onUpdateState({
          pageLoader: true,
          setIsLoad: false,
          dailydataList: dailydataList,
          weeklydataList: weeklydataList,
          monthlydataList: monthlydataList,
        });
        break;
    }
  };
}
