import baseDomainServices from "./baseDomainServices";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { setAuthProps } from '../../utils/AuthenticationLibrary';
import { ErrorMessage } from '../../utils/Messages';

const userDetails = {
    userName: '',
    password: ''
}

export default class authenticationServices extends baseDomainServices {

    add = this.apiService.authServices.adminLogin;
    constructor() {
        super();
        this.dataItem = { ...userDetails };
    }
    //** Service Event */

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;


        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })

    }
    //  API Calls

    onAdd = (request) => {
        this.apiService.onAPICall("login", this.add, request, this.onAPIResponse, this.onUpdateState);
    }
    dataItem;

    formFields = [
        {
            inputType: "text",
            tittle: "Email Address",
            fieldName: "userName",
            maxLength: 200,
            validation: [{ type: "require" }]
        },
        {
            inputType: "password",
            tittle: "Password",
            fieldName: "password",
            maxLength: 15,
            validation: [{ type: "require" }, { type: "password", message: "PasswordNotValid" }]
        },
    ]
    onAPIResponse = (key, iSuccess, responseData) => {
        if (!iSuccess) {
            if (responseData.statusCode === 401) {
                this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidAuthorization);
                this.onUpdateState({ setIsLoad: false });
            } else {
                this.onUpdateState({ setIsLoad: false });
                return;
            }
        }
        switch (key) {
            case "login":

                // Need to check if user is exist or not.
                let authDetails = responseData;
                if (responseData != null) {
                    setAuthProps(authDetails);
                    this.onUpdateState({ redirect: "/" });
                }
                else {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidAuthorization);
                }
                break;
        }
    }
}
