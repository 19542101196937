/* Component  */
import { axiosGet, axiosPost } from '../AxiosRequests';
import { GetIpAddressList, GetIpAddressById, UpdateIpAddress, GetAllIPAddresses, Admin_GetIPAddressCounts ,Admin_ProductsSearchByIpAddressCounts} from '../ApiEndPoints';

export default class SettingServices {
    // API function

    async getIpAddressList(request) {
        return axiosPost(GetIpAddressList, request);
    }
    async updateIpAddress(request) {
        return axiosPost(UpdateIpAddress, request);
    }
    async getIpAddressById(request) {
        return axiosGet(GetIpAddressById, request);
    }
    async getAllIPAddresses(request) {
        return axiosGet(GetAllIPAddresses, request);
    }
    async admin_GetIPAddressCounts(request) {
        return axiosGet(Admin_GetIPAddressCounts, request);
    }
    async getProductsByIpAddress(request) {
        return axiosGet(Admin_ProductsSearchByIpAddressCounts, request);
    }
}
