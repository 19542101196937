/* eslint-disable default-case */
import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import BaseDomainService from "./baseDomainServices";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";

const addPriceLists = {
    currencyId: '1',
    sizeId: '2',
    price: "",
    quantity: "",
}
const searchHistoryList = {
    searchText: ""
}
export default class PriceDomainService extends BaseDomainService {

    getPriceDetailsByProductId = this.apiService.productService.admin_GetPriceDetailsByProductId;
    updateProductPrice = this.apiService.productService.admin_UpdateProductPriceById;
    deleteProductPriceById = this.apiService.productService.deleteProductPriceById;
    addPrice = this.apiService.productService.admin_AddPriceById;
    getSizes = this.apiService.productService.getSizes;
    getAllCurrencies = this.apiService.productService.getAllCurrencies;
    updateAllPriceList = this.apiService.productService.UpdateProductPriceDetails;


    //** Product Details- Price 

    productPriceForm = [
        {
            inputType: "text",
            tittle: "Quantity",
            placeholder: "Quantity",
            fieldName: "quantity",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "select",
            tittle: "Pack",
            fieldName: "sizeId",
            placeholder: "Select Pack Size",
            options: [],
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Price",
            placeholder: "Price",
            fieldName: "price",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "select",
            tittle: "Currency",
            fieldName: "currencyId",
            placeholder: "Select Currency",
            options: [],
            validation: [{ type: "require" }]
        }
    ]
    productcurrencyId = [
        {
            inputType: "select",
            tittle: "",
            fieldName: "currencyId",
            options: [],
            validation: [{ type: "require" }]
        }
    ]
    productsizeId = [
        {
            inputType: "select",
            tittle: "",
            fieldName: "sizeId",
            options: [],
            validation: [{ type: "require" }],
        },
    ]
    productquantity = [
        {
            inputType: "text",
            tittle: "",
            placeholder: "",
            fieldName: "quantity",
            className: 'w-full',
            validation: [{ type: "require" }],
        },
    ]
    priceItem;
    constructor() {
        super();
        this.onPaginationUpdate = this.onGetPriceDetailsByProductId.bind(this);
        this.priceItem = { ...addPriceLists };
        this.dataItem = { ...searchHistoryList };
    }
    onGetPriceDetailsByProductId = (requst) => {
        this.apiService.onAPICall("getPriceDetailsByProductId", this.getPriceDetailsByProductId, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdateProductPrice = (requst, getRequest) => {
        this.apiService.onAPICall("updatePrice", this.updateProductPrice, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onAddPrice = (requst, getRequest) => {
        this.apiService.onAPICall("addPrice", this.addPrice, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    };
    onGetSizes = (requst) => {
        this.apiService.onAPICall("getSizes", this.getSizes, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetAllCurrencies = (requst) => {
        this.apiService.onAPICall("getAllCurrencies", this.getAllCurrencies, requst, this.onAPIResponse, this.onUpdateState);
    };
    ondeleteProductPriceById = (requst, getRequest) => {
        this.apiService.onAPICall("deleteProductPriceById", this.deleteProductPriceById, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    };
    onUpdateAllPriceList = (requst, pdId) => {
        this.apiService.onAPICall("updateAllPriceList", this.updateAllPriceList, requst, this.onAPIResponse, this.onUpdateState, pdId);
    }

    onInitService() {
        let valRule = getValidationRuleList(this.productPriceForm)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }
    onResetData = () => {
        this.priceItem = { ...addPriceLists }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            addPriceList: this.priceItem,
            validState: resetValidation
        });
    }



    onAPIResponse = (key, isSuccess, responseData, pdId) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getPriceDetailsByProductId":
                const priceListData = responseData;
                priceListData.forEach(function (i) {
                    i.size = i.size ? i.size : "-"
                    i.price = i.price ? i.price : i.price
                    i.quantity = i.quantity ? i.quantity : "-"
                    i.currencyId = i.currencyId ? i.currencyId : "-"
                });
                this.onUpdateState({
                    "priceLists": priceListData,
                    "setIsLoad": false,
                    "setOpenModal": false,
                    "pageLoader": false,
                });
                break;
            case "updatePrice":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Price"));
                this.onUpdateState({
                    "setOpenModal": false,
                    "pageLoader": true,
                    "setIsLoad": false,
                });
                break;

            case "addPrice":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Price Added"));
                this.onUpdateState({
                    "setOpenModal": false,
                    "pageLoader": false,
                    "setIsLoad": false,
                });
                this.onGetPriceDetailsByProductId(pdId)
                this.onResetData()
                break;

            case "deleteProductPriceById":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Price"));
                this.onUpdateState({
                    "setOpenModal": false,
                    "pageLoader": false,
                });
                this.onGetPriceDetailsByProductId(pdId)
                break;

            case "getSizes":
                let allSize = responseData;
                let newDataList = allSize.map(data => ({
                    id: data.sizeId,
                    name: data.size,
                }));
                let indexToMove = newDataList.findIndex(item => item.id === 2);
                if (indexToMove !== -1) {
                    let itemToMove = newDataList.splice(indexToMove, 1)[0];
                    newDataList.unshift(itemToMove);
                }
                let tempData = { ...addPriceLists };
                this.onUpdateState({
                    "size": newDataList,
                    "priceLists": tempData,
                });
                break;

            case "getAllCurrencies":
                let getAllCurrencies = responseData
                let newCurrenciesList = getAllCurrencies.map((data) => ({
                    id: data.currencyId,
                    name: data.currencyName,
                }));
                let CurrenciesData = { ...addPriceLists }
                this.onUpdateState({
                    "getAllCurrencies": newCurrenciesList,
                    "priceLists": CurrenciesData,
                });
                break;
            case "updateAllPriceList":
                if (responseData.errorColumn === '') {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Price"));
                } else {
                    this.apiService.serviceManager.swalServices.Error(ErrorMessage.DefaultMessage)
                }
                this.onUpdateState({
                    "pageLoader": true,
                    "setIsLoad": false,
                });
                this.onGetPriceDetailsByProductId(pdId)
                break;
        }
    }
}

