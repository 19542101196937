/* eslint-disable default-case */
// Component
import React from "react";
import BaseComponent from "../../shared/baseComponent";

import DashboardCard from "../../components/dashboard/DashboardCard";
import Date_Range_Picker from "../../components/DateRange";
import { ErrorMessage } from "../../utils/Messages";

// Library
import moment from "moment";
import { Navigate } from "react-router-dom";
import { encryptAES } from "../../utils/Encryption";
import Table from "../../components/tables/Table";

// Services
import dashboardServices from "../../services/domainServices/dashboardServices";

export default class Dashboard extends BaseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props);
    const today = moment();
    this.dashboardServices = new dashboardServices();
    this.state = {
      dataList: [],
      redirect: "",
      totalOrders: "",
      totalRFQs: "",
      totalUsers: "",
      totalEmailLogs: "",
      startDate: "",
      recentsOrders: [],
      recentContactUs: [],
      setIsLoad: false,
      changeDateValue: {
        startDate: "",
        endDate: "",
      },
    };
    this.dashboardServices.onUpdateState = this.updateState.bind(this);
    this.dashboardServices.onActionCall = this.onActionCall.bind(this);
  }
  componentDidMount() {
    let today = new Date();
    let currentDate = new moment(Date()).format("YYYY-MM-DD ");
    let pastdate = today.setDate(today.getDate() - 7);
    let pDate = moment(pastdate).format("YYYY-MM-DD ");
    this.setState({ start: pDate });
    this.setState({ end: currentDate });
    this.dashboardServices.onGetdashboardCount();
    let request = {
      fromOrderDate: pDate,
      toOrderDate: currentDate,
    };
    this.dashboardServices.onGetRecentRFQ(request);
    this.dashboardServices.onGetRecentOrder(request);
    this.dashboardServices.onGetRecentConatctUs(request);
    this.getDate();
    document.title = "Dashboard";
  }

  //Other function
  getDate = () => {
    let currentDate = new moment(Date()).format("YYYY-MM-DD ");
    let endDate = new Date().setDate(new Date().getDate() - 7);
    let pDte = moment(endDate).format("YYYY-MM-DD ");
    this.setState({ startDate: currentDate });
    this.setState({ endDate: pDte });
  };
  handleLanguage = (changeValue) => {
    this.setState({ changeDateValue: changeValue });
    let request = {
      fromOrderDate: changeValue.startDate,
      toOrderDate: changeValue.endDate,
    };
    this.dashboardServices.onGetRecentRFQ(request);
    this.dashboardServices.onGetRecentOrder(request);
    this.dashboardServices.onGetRecentConatctUs(request);
  };

  onActionCall = (type, data) => {
    let det = this.state.recentsOrders;
    let UserDetails = this.state.dataList;
    switch (type) {
      case "userName":
        let value = det.find((x) => x.userName === data);
        window.open(
          `/UserDetails/userId?=${encryptAES(value.userId)}`,
          "_blank"
        );
        break;
      case "userDisplayName":
        let Emails = UserDetails.find((x) => x.userDisplayName === data);
        if (Emails && Emails.userId !== 0) {
          window.open(
            `/UserDetails/userId?=${encryptAES(Emails.userId)}`,
            "_blank"
          );
        } else {
          this.dashboardServices.apiService.swalServices.Alert(
            ErrorMessage.RFQDetailNotExists
          );
        }
        break;
      case "View":
        localStorage.setItem("RFQURL", "RFQ");
        window.open(`/RFQDetail/RFQId?=${encryptAES(data)}`, "_blank");
        break;
      case "edit":
        localStorage.setItem("OrderUrl", "Orders");
        window.open(`/Order/OrderId?=${encryptAES(data)}`, "_blank");
        break;
      case "editcontactus":
        localStorage.setItem("ContactUrl", "Contact");
        window.open(`/ContactUs?=${encryptAES(data)}`, "_blank");
        break;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-6 py-6 w-full max-w-9xl mx-auto">
              <div className="grid grid-flow-col sm:auto-cols-max sm:justify-end justify-center gap-2 ">
                <div className="relative">
                  <div className="flex items-center dashboard-date-range border-picker">
                    <Date_Range_Picker onSelectDate={this.handleLanguage} />
                  </div>
                </div>
              </div>

              <div className="pt-6 pb-6">
                <DashboardCard
                  totalOrders={this.state.totalOrders}
                  totalRFQs={this.state.totalRFQs}
                  totalEmailLogs={this.state.totalEmailLogs}
                  totalUsers={this.state.totalUsers}
                  totalShoppingCart={this.state.totalShoppingCart}
                />
              </div>
              {/* This is for Charts Start*/}
              {/* <Wizard /> */}
              {/* This is for Charts  End*/}
              <div className="grid grid-cols-12 gap-6">
                <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border border-slate-200">
                  <header className="px-5 py-4 border-b border-slate-100">
                    <h2 className="font-semibold text-slate-800">RFQs</h2>
                  </header>
                  <div className="p-3 relative h-full w-full min-h-[100px]">
                    <>
                      <div className="overflow-x-auto fixed_header_dashboard">
                        {this.state.dataList.length > 50 ? (
                          <>
                            <Table
                              columns={this.dashboardServices.rfqGridColmns}
                              rows={this.state.dataList}
                              sortingColumns={"null"}
                              pagination={"null"}
                              totalCount={"null"}
                              totalResultes={"null"}
                              setPagination={"null"}
                              isLoading={this.state.setIsLoad}
                              customScope={[
                                {
                                  column: "rfqId",
                                  renderTableData:
                                    this.dashboardServices.gridActionViewCol.bind(
                                      this
                                    ),
                                },
                                {
                                  column: "userDisplayName",
                                  renderTableData:
                                    this.dashboardServices.UserRFQEmailactions.bind(
                                      this
                                    ),
                                },
                              ]}
                            />
                            <i
                              className="fas fa-external-link Size1 cursor-pointer ..."
                              onClick={(value) =>
                                this.dashboardServices.backToRFQListPage()
                              }
                            >
                              {" "}
                              Display All
                            </i>
                          </>
                        ) : (
                          <div className="pagination-remove overflow-x-auto  fixed_header_dashboard">
                            <Table
                              columns={this.dashboardServices.rfqGridColmns}
                              rows={this.state.dataList}
                              sortingColumns={"null"}
                              pagination={"null"}
                              totalCount={"null"}
                              totalResultes={"null"}
                              setPagination={"null"}
                              isLoading={this.state.setIsLoad}
                              customScope={[
                                {
                                  column: "rfqId",
                                  renderTableData:
                                    this.dashboardServices.gridActionViewCol.bind(
                                      this
                                    ),
                                },
                                {
                                  column: "userDisplayName",
                                  renderTableData:
                                    this.dashboardServices.UserRFQEmailactions.bind(
                                      this
                                    ),
                                },
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </div>
                <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border border-slate-200">
                  <header className="px-5 py-4 border-b border-slate-100">
                    <h2 className="font-semibold text-slate-800">Orders</h2>
                  </header>
                  <div className="p-3 relative h-full min-h-[100px] w-full">
                    {this.state.recentsOrders.length > 50 ? (
                      <div>
                        <div className="pagination-remove overflow-x-auto fixed_header_dashboard rounded-0   ">
                          <Table
                            columns={this.dashboardServices.orderGridColmns}
                            rows={this.state.recentsOrders}
                            sortingColumns={"null"}
                            pagination={"null"}
                            totalCount={"null"}
                            totalResultes={"null"}
                            setPagination={"null"}
                            isLoading={this.state.setIsLoad}
                            customScope={[
                              {
                                column: "orderId",
                                renderTableData:
                                  this.dashboardServices.gridActionOrderId.bind(
                                    this
                                  ),
                              },
                              {
                                column: "paymentType",
                                renderTableData:
                                  this.dashboardServices.gridActionViewColpayment.bind(
                                    this
                                  ),
                              },
                              {
                                column: "userName",
                                renderTableData:
                                  this.dashboardServices.UserEmailactions.bind(
                                    this
                                  ),
                              },
                            ]}
                          />
                          <i
                            className="fas fa-external-link Size1 cursor-pointer ..."
                            onClick={(value) =>
                              this.dashboardServices.backToOrderListPage()
                            }
                          >
                            {" "}
                            Display All
                          </i>
                        </div>
                      </div>
                    ) : (
                      <div className="Price-forr">
                        <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                          <Table
                            columns={this.dashboardServices.orderGridColmns}
                            rows={this.state.recentsOrders}
                            sortingColumns={"null"}
                            pagination={"null"}
                            totalCount={"null"}
                            totalResultes={"null"}
                            setPagination={"null"}
                            isLoading={this.state.setIsLoad}
                            customScope={[
                              {
                                column: "orderId",
                                renderTableData:
                                  this.dashboardServices.gridActionOrderId.bind(
                                    this
                                  ),
                              },
                              {
                                column: "paymentType",
                                renderTableData:
                                  this.dashboardServices.gridActionViewColpayment.bind(
                                    this
                                  ),
                              },
                              {
                                column: "userName",
                                renderTableData:
                                  this.dashboardServices.UserEmailactions.bind(
                                    this
                                  ),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border border-slate-200">
                  <header className="px-5 py-4 border-b border-slate-100">
                    <h2 className="font-semibold text-slate-800">Contact Us</h2>
                  </header>
                  <div className="p-3 relative h-full min-h-[100px] w-full">
                    {this.state.recentContactUs.length > 50 ? (
                      <div className="pagination-remove overflow-x-auto  fixed_header_dashboard">
                        <Table
                          columns={this.dashboardServices.contactUsGridColmns}
                          rows={this.state.recentContactUs}
                          sortingColumns={"null"}
                          pagination={"null"}
                          totalCount={"null"}
                          totalResultes={"null"}
                          setPagination={"null"}
                          isLoading={this.state.setIsLoad}
                          customScope={[
                            {
                              column: "contactUsId",
                              renderTableData:
                                this.dashboardServices.gridSctionContactUsId.bind(
                                  this
                                ),
                            },
                          ]}
                        />
                        <i
                          className="fas fa-external-link Size1 cursor-pointer my-5 ..."
                          onClick={(value) =>
                            this.dashboardServices.backToContactListPage()
                          }
                        >
                          {" "}
                          Display All
                        </i>
                      </div>
                    ) : (
                      <div className="pagination-remove overflow-x-auto  fixed_header_dashboard">
                        <Table
                          columns={this.dashboardServices.contactUsGridColmns}
                          rows={this.state.recentContactUs}
                          sortingColumns={"null"}
                          pagination={"null"}
                          totalCount={"null"}
                          totalResultes={"null"}
                          setPagination={"null"}
                          isLoading={this.state.setIsLoad}
                          customScope={[
                            {
                              column: "contactUsId",
                              renderTableData:
                                this.dashboardServices.gridSctionContactUsId.bind(
                                  this
                                ),
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
