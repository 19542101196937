//Components
import React from 'react';
import Button from '../../components/common/Button';
import RfqDetail from '../../components/rfq/RfqDetail';
//Library
import { Navigate } from 'react-router';
import { ButtonTypes } from '../../utils/Constants';
import { checkParameters } from "../../utils/Encryption";
//Services
import BaseComponent from '../../shared/baseComponent';
import rfqServices from '../../services/domainServices/rfqServices';

export default class RFQDetail extends BaseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.rfqService = new rfqServices();
    this.rfqService.onUpdateState = this.updateState.bind(this);
    this.state = {
      RfqDetails: [],
      packageList: [],
      setIsLoad: false,
      isMulti: false,
      RFQMultiData: []
    }
  }
  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let RFQId = params.get("");
    if (RFQId && (RFQId = checkParameters(RFQId, "Number"))) {
      this.rfqService.onGetRFQById(RFQId);
      document.title = 'RFQ Details';
    }
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden  bg-[#f8fcff] min-h-screen">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className='flex items-center justify-between mb-4'>
                <div className="">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">RFQ Details </h1>
                </div>
                <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.rfqService.onRedirectoList()} />
              </div>
              <RfqDetail
                isLoading={this.state.setIsLoad}
                RFQDetails={this.state.RfqDetails}
                packageList={this.state.packageList}
                isMulti={this.state.isMulti}
                RFQMultiData={this.state.RFQMultiData}
              />
            </div>
          </main>
        </div>
      </div>
    )
  }
}
