// Component
import React from 'react';
import Table from '../../components/tables/Table';
// Library
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import BaseComponent from '../../shared/baseComponent';
// Services
import SwalServices from "../../services/swalServices/SwalServices";
import DataImportService from '../../services/domainServices/dataImportService';
import ImportDataServices from '../../services/axiosServices/apiServices/ImportDataServices';

export class DataImport extends BaseComponent {

  //** Constructor  and Component method */
  constructor(props) {
    super(props)
    this.swalServices = new SwalServices();
    this.ImportDataServices = new ImportDataServices();
    this.dataImportService = new DataImportService();
    this.state = {
      getImport: [],
      redirect: null,
      setIsLoad: false,
      pagination: this.dataImportService.pagination,
    }
    this.dataImportService.onUpdateState = this.updateState.bind(this);
    this.dataImportService.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.dataImportService.onGetList(this.state.pagination);
    document.title = 'Data Import List';
  }

  // *** Other function **//
  onActionCall = (type, data) => {
    switch (type) {
      case "View":
        window.open("/DataImportInfo/ImportId?=" + data, '_blank');
    }
  }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden bg-[#f8fcff] min-h-screen">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Data Import List </h1>
                </div>
              </div>
              {(this.state.setIsLoad) ?
                <Bars
                  className="row"
                  wrapperStyle={{ marginTop: '150px' }}
                  color="#3c4b64"
                  height={55}
                  width="100%"
                  visible={this.state.setIsLoad} />
                :
                <div className='mt-5'>
                  <Table
                    columns={this.dataImportService.gridColmns}
                    rows={this.state.getImport}
                    sortingColumns={["importTitle", "ImportFileName", "ImportTypeId", "status", "startDate"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.TotalCount}
                    totalCount={this.state.TotalCount}
                    setPagination={this.dataImportService.onPageChange.bind(this)}
                    customScope={[
                      {
                        column: 'importId',
                        renderTableData: this.dataImportService.gridActionViewCol.bind(this)
                      },
                    ]} />
                </div>
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default DataImport;

