//Components
import React from 'react';
import Table from '../../components/tables/Table';
import Button from '../../components/common/Button';
//Library
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { ButtonTypes } from '../../utils/Constants';
import { encryptAES } from "../../utils/Encryption";
//Services
import BaseComponent from '../../shared/baseComponent';
import emailServices from '../../services/domainServices/emailServices';

export default class EmailHistory extends BaseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.emailService = new emailServices();
    this.state = {
      dataList: [],
      setIsLoad: false,
      pagination: this.emailService.pagination
    }
    this.emailService.onUpdateState = this.updateState.bind(this);
    this.emailService.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.emailService.onGetRequests(this.state.pagination)
    document.title = 'Email Template';
  }

  //Other Functions

  onActionCall = (type, data) => {
    switch (type) {
      case "View":
        window.open(`/EmailDetail/emailTemplateId?=${encryptAES(data)}`, '_blank');

        break;
    }
  };

  addEmail = () => {
    this.setState({ redirect: "/EmailTemplate" });
  }
 

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Email Template</h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                  <Button type={ButtonTypes.Primary} title="Add Email" buttonClick={() => this.addEmail()} isLoading={this.state.setIsLoad} />
                </div>
              </div>
              <div className=''>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <Table
                    columns={this.emailService.gridColmns}
                    rows={this.state.dataList}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalCount={this.state.TotalCount}
                    totalResultes={this.state.TotalCount}
                    setPagination={this.emailService.onPageChange.bind(this)}
                    customScope={[
                      {
                        column: "emailTemplateId",
                        renderTableData:
                          this.emailService.gridActionViewCol.bind(this),
                      },
                    ]}
                  />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
