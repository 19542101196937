import BaseDomainService from "./baseDomainServices";
import { DateFromat } from '../../utils/dateFormat';
const searchHistoryList = {
    searchText: ""
}


export default class viewProductService extends BaseDomainService {
    getSearchByUserList = this.apiService.searchHistoryServices.admin_GetAllSearchHistoryListByUser

    gridColmnsUserSearch = [
        { name: 'userName', title: 'User Full Name' },
        { name: 'webURL', title: 'Url' },
        { name: 'catalogId', title: 'Catalog Id' },
        { name: 'productId', title: 'Product Id' },
        { name: 'searchEngine', title: 'Referrer Url' },
        { name: 'isp', title: 'Isp Name' },
        { name: 'crawlerName', title: 'Crawler Name' },
        { name: 'isCrawler', title: 'IsCrawler' },
        { name: 'userIPAddress', title: 'User IP Address' },
        { name: 'country', title: 'Country' },
        { name: 'searchDate', title: 'Search Date' }
    ];
    // Define the state variables
    dataItem;
    formFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.actionKey = "webURL";
        this.ipActionKey = "userIPAddress";
        this.onPaginationUpdate = this.onGetSearchByUser.bind(this);
        this.dataItem = { ...searchHistoryList };
        this.actionActive = "isCrawler";
    }

    onGetSearchByUser = (requst) => {
        this.apiService.onAPICall("getSearchByUserList", this.getSearchByUserList, requst, this.onAPIResponse, this.onUpdateState);
    }


    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getSearchByUserList":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.searchDate = i.searchDate ? DateFromat(i.searchDate) : '-'
                        i.ipAddress = i.ipAddress ? i.ipAddress : '-'
                        i.country = i.country ? i.country : '-'
                        // i.isp = i.isp ? i.isp : '-';
                        // i.isCrawler = i.isCrawler ? i.isCrawler : '-';
                        // i.crawlerName = i.crawlerName ? i.crawlerName : '-';
                    })
                    this.onUpdateState({
                        dataList: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                }
                break;
        }
    }
}

