/* Component  */
import { axiosGet, axiosPost } from '../AxiosRequests';
import { GetBlockIpAddressList} from '../ApiEndPoints';

export default class BlockServices {
    // API function

    async getBlockIpAddressList(request) {
        return axiosPost(GetBlockIpAddressList, request);
    }
}
