import React from "react";
import { camelCaseToSentence } from "../../utils/CamelCaseToSentence";

const QueryDetail = (props) => {

  const { dataOutputList } = props;

  if (dataOutputList && dataOutputList.length > 0 && dataOutputList[0].data) {
    
    const parsedData = JSON.parse(dataOutputList[0].data);
    const label = Array.from(new Set(parsedData.flatMap(Object.keys)));

    return (
      <div className="shadow rounded-lg center-div table-height-fix">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-200 sticky top-[0px]">
            <tr>
              {label.map((key) => (
                <th className="py-2 px-4 text-left font-bold text-gray-700 whitespace-nowrap" key={key}>{camelCaseToSentence(key)}</th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-y-auto db-helth-table">
            {parsedData.map((item, index) => (
              <tr key={index}  className={index % 2 === 0 ? "bg-gray-100" : ""}>
                {label.map((key) => (
                  <td className="py-2 px-4 text-gray-80 text-left" key={key}>{item[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div>
        No data available
      </div>
    );
  }
};

export default QueryDetail;
