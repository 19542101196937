import React from "react";

const SearchField = (request) => {
    let props = request.request;
    let column = props.column;
    return (<>
        <input
          className="form-input w-full text disabled:bg-zinc-300 disabled:cursor-not-allowed"
          type={props.column.inputType}
          onChange={props.onChange}
          name={props.column.fieldName}
          id={props.column.fieldName}
          placeholder={props.column.placeholder}
          value={props.value}
          onKeyPress={props.onKeyPress} />
        {props.column.searchIcon ?
          <button className="absolute inset-0 right-auto group margin-left" type="submit" aria-label="Search"  >
            <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
              <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
            </svg>
          </button> : null
        }
        </>)
}


export default SearchField;
