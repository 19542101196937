//Components
import React from 'react';

const ValidationText = (props) => {
    return (
        props.error ?
            <div className="text-xs mt-1 text-rose-500">{props.error}</div>
            : null
    )
}

export default ValidationText;
