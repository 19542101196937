import React from 'react';
/* Lib  */

//** Service */
import componetWizardServices from '../../../src/services/domainServices/componetWizardServices';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import BaseComponent from '../../shared/baseComponent';
import ModalBasic from '../../components/ModalBasic';
import ButtonGroup from '../../components/common/ButtonGroup';
import Button from '../../components/common/Button';
import { ButtonTypes } from '../../utils/Constants';

export class AddEditComWizardModal extends BaseComponent {
    constructor(props) {
        super(props)
        this.componetWizardServices = props.componetWizardServices;
        this.componetWizardServices = new componetWizardServices();
        this.componetWizardServices.onUpdateState = this.updateState.bind(this);
    }
    componentDidMount() {
    }
    render() {
        return (
            <ModalBasic id="basic-modal_2" modalOpen={this.props.openStateModal} setModalOpen={() => {this.props.onCloseModal()}} title={this.props.wizarddetails.componentWizardId ? "Update Component Wizard " : "Add Component Wizard"}>
                <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                    <div className="border-slate-200">
                        <div className="space-y-4 mt-4 pb-5">
                            <div className="grid gap-5 md:grid-cols-12">
                                {this.componetWizardServices.formFields.map((Item, index) => {
                                    return (
                                        <>
                                            {Item.fieldName === "chartType" && this.props.wizarddetails.componentWizardType !== "Charts" ? null : (
                                                <FormFieldGroup
                                                    column={Item}
                                                    value={this.props.wizarddetails[Item.fieldName]}
                                                    error={this.props.validState.error[Item.fieldName]}
                                                    options={Item.options}
                                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                    onChange={Item.inputType === 'checkbox' ? (event) => this.props.handleChange(Item.fieldName, event.target.checked)
                                                        : Item.inputType === "file" ? (event) => this.props.handleFileChange(event) :
                                                            Item.inputType === "select" || Item.inputType === "text" ? (event) => this.props.handleChange(Item.fieldName, event.target.value)
                                                                : (event) => this.props.handleChange(Item.fieldName, event.target.value)}
                                                    checked={this.props.wizarddetails[Item.fieldName]}
                                                    onBlur={() => {
                                                        this.props.validateField(Item.fieldName, this.props.wizarddetails, this.props.validationRules, this.props.validState)
                                                    }}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                            <div className="flex flex-wrap justify-end mt-4 mb-4 gap-3">
                                {this.props.wizarddetails.componentWizardId ?
                                    <>
                                        <Button
                                            type={ButtonTypes.Primary} title="Update"
                                            buttonClick={(e) => this.props.updateproceed(e)}
                                        />
                                        <Button type={ButtonTypes.Secondary} className="ml-3" title="Cancel" buttonClick={() => this.props.onCloseModal()} />
                                    </>
                                        :
                                        <> <Button
                                            type={ButtonTypes.Primary} title="Add" 
                                            buttonClick={(e) => this.props.proceed(e)}
                                        />
                                        <Button type={ButtonTypes.Secondary} className="ml-3" title="Cancel" buttonClick={() => this.props.onCloseModal()} />
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
        )
    }
}

export default AddEditComWizardModal;