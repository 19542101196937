import { Component } from 'react';
import { DateFromat } from '../../utils/dateFormat';
import BaseDomainService from "./baseDomainServices";
const searchHistoryList = {
    searchText: ""
}
export default class AuditService extends BaseDomainService {
    getAuditList = this.apiService.auditservice.admin_GetAuditLogList;

    AuditgridColmns = [
        { name: "auditLogId", title: "audit Log Id" },
        { name: "action", title: "Action  " },
        { name: "tableName", title: "Table Name " },
        { name: "columnName", title: "Column Name " },
        { name: "remarks", title: "Remarks " },
        { name: "previousValue", title: "Previous Value " },
        { name: "newValue", title: "New Value " },
        { name: "createdDate", title: "Created Date " },
    ]

    // Define the state variables
    dataItem;
    SearchformFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onGetAuditList.bind(this);
        this.dataItem = { ...searchHistoryList };

    }
    //** API Calls

    onGetAuditList = (requst) => {
        this.apiService.onAPICall("Auditlist", this.getAuditList, requst, this.onAPIResponse, this.onUpdateState);
    };

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "Auditlist":
                if (responseData !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : '-';         
                    })
                    this.onUpdateState({
                        getAuditLog: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                }
                break;

        }

    };





}

