/* Component  */
import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router";

/* Library  */
import { getAuthProps } from "./../utils/AuthenticationLibrary";

const Layout = (props) => {
  // Hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
   //** Constructor  and Component method */
  useEffect(() => {
    const authDetails = getAuthProps();
    let params = window.location.pathname;
    if (params === "/UnAuthorized") {
      window.location.href = "/UnAuthorizedg";
    }
    else if (!authDetails) {
      window.location.href = "/login";
    }
  });



  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          componentRoutes={props.componentRoutes}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
