/* Component  */
import React from 'react';
/* Library  */
import { Navigate } from 'react-router-dom';
/* Services  */
import baseComponent from "../../shared/baseComponent";
import UserService from '../../services/domainServices/userService';
import { Bars } from 'react-loader-spinner';
import Table from '../../components/tables/Table';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
export default class UserDashboard extends baseComponent {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        this.userService = new UserService();
        this.state = {
            UserList: [],
            UserCount: [],
            pagination: this.userService.pagination,
        }
        this.userService.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.userService.ongetUserDashboardList(this.state.pagination);
        document.title = 'User List';
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (

            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">
                                <div className="mb-4 sm:mb-0 mb-5">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">User  Dashboard</h1>
                                </div>
                            </div>
                            <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 my-5 mt-5">
                                {this.userService.cardFormFields.map((Item, index) => {
                                    return (
                                        <FormFieldGroup
                                            column={Item}
                                            value={this.state.UserCount[Item.fieldName]} key={index} />
                                    )
                                })}
                            </section>
                            <header className=" py-4 border-b border-slate-100">
                                <h2 className="font-semibold text-slate-800">Frequently Logged Users</h2>
                            </header>
                            <div className='user-list-table countDashboard pagination-remove'>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <Table
                                            columns={this.userService.UserDashListgridColmns}
                                            rows={this.state.UserList}
                                            sortingColumns={'null'}
                                            pagination={'null'}
                                            totalCount={'null'}
                                            totalResultes={'null'}
                                            setPagination={'null'}

                                        />
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
