import React, { Component } from "react";
import Button from "../../components/common/Button";
import { ButtonTypes } from "../../utils/Constants";
import AddEditQueryModal from "../../components/databaseHealth/AddEditQueryModal";
import databaseHealthServices from '../../services/domainServices/databaseHealthServices';
import "./DatabaseHealth.scss";
import QueryDetail from "../../components/databaseHealth/QueryDetail";
import { Navigate } from 'react-router-dom';
import BaseComponent from "../../shared/baseComponent";
import DatabaseHealthServices from "../../services/axiosServices/apiServices/DatabaseHealthServices";
import moment from "moment";
import { saveAs } from 'file-saver';
// import { getAuthProps, isAuthorized } from "../../utils/AuthenticationLibrary";
export default class DatabaseHealth extends BaseComponent {
  constructor(props) {
    super(props);
    this.databaseHealthServices = new databaseHealthServices();
    this.state = {
      ...this.state,
      setOpenModal: false,
      activeIndex: null,
      dbhealth: this.databaseHealthServices.dataItem,
      pagination: {
        ...this.databaseHealthServices.pagination,
        databaseHealthId: 0
      },
      dataList: [],
      isUpdate: false,
      updatedBy: 0,
      deletedBy: 0,
      dataOutputList: [],
      // userId : (isAuthorized()) ? getAuthProps().userId : 0,
    };
    this.databaseHealthServices.onUpdateState = this.updateState.bind(this);
  }


  exportList = new DatabaseHealthServices();

  handleOpenModal = () => {
    this.setState({ setOpenModal: true , activeIndex: null });
  };

  onCloseModal = (event) => {
    this.setState({ setOpenModal: false });
    this.databaseHealthServices.onResetData();
  }

  handleToggle = (index) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index,
    }));
    const value = this.state.dataList[index];
    this.databaseHealthServices.onGetDatabaseHealthWizardOutput(value.databaseHealthId);

  };

  addDbhealth = () => {
    let request = {
      title: this.state.dbhealth.title,
      query: this.state.dbhealth.query,
      isActive: this.state.dbhealth.isActive,
      createdBy: this.state.dbhealth.createdBy,
    }
    if (this.databaseHealthServices.isValidSubmit(this.state.dbhealth, this.state.validationRules, this.state.validState)) {
      this.databaseHealthServices.onAddDatabaseHealthWizard(request, this.state.pagination);
    }
  };

  updateDbhealth = () => {
    let request = {
      title: this.state.dbhealth.title,
      query: this.state.dbhealth.query,
      isActive: this.state.dbhealth.isActive,
      updatedBy: this.state.updatedBy,
      databaseHealthId: this.state.dbhealth.databaseHealthId
    }
    if (this.databaseHealthServices.isValidSubmit(this.state.dbhealth, this.state.validationRules, this.state.validState)) {
      this.databaseHealthServices.onUpdateDatabaseHealthWizard(request, this.state.pagination);
    }
  };

  componentDidMount() {
    this.databaseHealthServices.onInitService();
    this.databaseHealthServices.onGetDatabaseHealthWizardList(this.state.pagination)
  }

  handleChange(name, value) {
    let detail = this.state.dbhealth;
    detail[name] = value;
    this.setState({ dbhealth: { ...detail } });
  }

  handleEdit = (databaseHealthId) => {
    let find = this.state.dataList?.find(f => f.databaseHealthId === databaseHealthId);
    this.setState({
      setOpenModal: true,
      activeIndex: null,
      dbhealth : find
    });
  };

  handleDelete = (databaseHealthId) => {
    this.databaseHealthServices.apiService.swalServices.Confirm("Delete Warning", "Are you sure, you want to Delete?", "Yes", "No")
      .then(async confirm => {
        if (confirm) {
          let request = {
            Id: databaseHealthId,
            deletedBy: this.state.deletedBy
          };
          this.databaseHealthServices.onDeleteDatabaseHealthWizard(request, this.state.pagination);
        }
      });
  };

  handleExport = (databaseHealthId) => {
    let req = [
      databaseHealthId
    ]
    this.exportList.exportDatabaseHealthWizardOutput(req).then((res) => {
      var formattedDate = moment(new Date()).format("YYMMDD")
      var filename = 'Export Data' + formattedDate;
      var blob = new Blob([res.data])
      saveAs(blob, filename + ".xlsx");
    })
  }


  render() {
    const { activeIndex } = this.state;
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8  py-5 w-full max-w-9xl mx-auto">
          <div className="sm:flex sm:justify-between sm:items-center mb-5">
            <div className="mb-4 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                Database Health
              </h1>
            </div>
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
              <Button
                type={ButtonTypes.Primary}
                title="Add"
                buttonClick={this.handleOpenModal}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2">
            {this.state.dataList.map((item, index) => (

              <div className="col-span-6" key={index}>
                <div className="bg-white rounded-sm border border-slate-200 activity_details mb-1">
                  {/* <div className="grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 gap-6"> */}
                  <div className="accordion-section">
                    <div className="w-full mx-auto">
                      <div key={index} className="accordion-header">
                        <div className={`button-title-action ${activeIndex === index && "active-button"}`}>
                          <button
                            className="flex justify-between items-center px-4 py-3 w-full focus:outline-none"
                            onClick={() => this.handleToggle(index)}
                          >
                            <span className="text-gray-600">
                              {activeIndex === index ? (
                                <>
                                  <img
                                    src="./arrowIcon.png"
                                    className="img-fluid transform rotate-[180deg] transition-all w-[15px]"
                                    alt=""
                                  ></img>
                                </>
                              ) : (
                                <>
                                  <img
                                    src="./arrowIcon.png"
                                    className="img-fluid transition-all w-[15px]"
                                    alt=""
                                  ></img>
                                </>
                              )}
                            </span>
                          </button>
                          <div className="title-action">
                            <span className="text-lg font-semibold wizard-title">
                              {item.title}
                            </span>
                            <div className="wizard-count">
                              {/* <h4>Records: <span>{item.recordCount}</span></h4> */}
                              {/* <div className="active-inactive">
                              {item.isActive ? (
                                <span className="is-active activeColor">
                                  Active
                                </span>
                              ) : (
                                <span className="is-active inActiveColor">
                                  In Active
                                </span>
                              )}
                            </div> */}
                              <div className="action-btn">
                                <button
                                  title="Edit Record"
                                  className=""
                                  onClick={() => this.handleEdit(item.databaseHealthId)}
                                >
                                  <i class="far fa-edit"></i>
                                </button>
                                <button
                                  title="Delete Record"
                                  className=""
                                  onClick={() => this.handleDelete(item.databaseHealthId)}
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                                <button
                                  title="Export Excel"
                                  className=""
                                  onClick={() => this.handleExport(item.databaseHealthId)}
                                >
                                  <i class="fal fa-file-export"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {activeIndex === index && (
                          <div className="border-t border-slate-200 px-4 py-3">
                            <p className="text-gray-700">
                              {/* {item.query} */}
                              <div className="mb-3">
                                <label className="font-small">Total Records: <span className="font-bold">{item.recordCount}</span></label>
                              </div>
                              <QueryDetail
                                dataOutputList={this.state.dataOutputList}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <AddEditQueryModal
          openStateModal={this.state.setOpenModal}
          onCloseModal={this.onCloseModal.bind(this)}
          modalOpen={(e) => { this.setState({ setOpenModal: false }) }}
          onClose={(e) => {
            this.setState({ setOpenModal: false })
          }}
          dbhealth={this.state.dbhealth}
          validState={this.state.validState}
          validationRules={this.state.validationRules}
          validateField={this.databaseHealthServices.validateField.bind(this)}
          addDbhealth={this.addDbhealth.bind(this)}
          updateDbhealth={this.updateDbhealth.bind(this)}
          handleChange={this.handleChange.bind(this)}
        />
      </>
    );
  }
}
