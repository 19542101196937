import BaseDomainService from "./baseDomainServices";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { DateFromat } from '../../utils/DateFromat';
const searchHistoryList = {
    searchText: ""
}

export default class blockIpServices extends BaseDomainService {
    getblockIpList = this.apiService.blockServices.getBlockIpAddressList;
    SettingServicesColmns = [
        { name: "ipAddress", title: "IP Address" },
        { name: 'country', title: 'Country' },
        { name: "createdDate", title: "Date & Time" },
    ]
    // Define the state variables
    dataItem;
    SearchformFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]

    constructor() {
        super();
        this.ipActionKey = "ipAddress";
        this.onPaginationUpdate = this.onGetblockIpList.bind(this);
        this.dataItem = { ...searchHistoryList };
    }

    //** Service Event */
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    //** API Calls
    onGetblockIpList = (requst) => {
        this.apiService.onAPICall("getblockIpList", this.getblockIpList, requst, this.onAPIResponse, this.onUpdateState);
    };

    onRedirectoList = () => {
        this.onRedirect("/");
    }
    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "getblockIpList":
                if (responseData !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : '-';
                        i.ipAddress = i.ipAddress ? i.ipAddress : '-'
                        i.country = i.country ? i.country : '-'
                    })
                    this.onUpdateState({
                        getBlockIpList: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                }
                break;
        };
    }
}

