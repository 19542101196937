// Components
import React from 'react';
import OrderDetail from '../../components/order/OrderDetail';
import Button from '../../components/common/Button';
// Library
import { Navigate } from 'react-router-dom';
import { ButtonTypes } from '../../utils/Constants';
import BaseComponent from '../../shared/baseComponent';
import { checkParameters } from "../../utils/Encryption";
// Services
import orderServices from '../../services/domainServices/orderServices';

export default class Order extends BaseComponent {

  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.orderService = new orderServices();
    this.orderService.onUpdateState = this.updateState.bind(this);
    this.state = {
      setIsLoad: false,
      orderDetails: [],
      productTotal: [],
      shippingMethod: [],
      orderProducts: [],
      orderBillingAddress: [],
      orderShippingAddress: [],
      pagination: { ...this.orderService.pagination }
    }
  }

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let OrderId = params.get("")
    if (OrderId && (OrderId = checkParameters(OrderId, "Number"))) {
      this.orderService.onGetOrdersById(OrderId);
      document.title = 'Order Details';
    }
  }
  setUpdatedStatus = (e, index) => {
    let newOrderProducts = this.state.orderProducts
    let { value } = e
    newOrderProducts[index].orderStatusId = value;
    this.setState({
      orderProducts: [...newOrderProducts]
    })
  }
  setOrderStatus = (e) => {
    let newStatus = { ...this.state.orderStatus }
    let value = e.value;
    newStatus = value;
    this.setState({
      orderStatus: newStatus,
    })
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
              <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.orderService.onRedirectoList()} />
              <div className="sm:flex sm:justify-between sm:items-center mb-3">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Order Detail</h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                  <b>Order Id:
                    <div className="btn commanColorbtn  text-white mx-2">
                      <span className=" xs:block ml-2"> {this.state.orderDetails.orderNumber}</span>
                    </div> </b>
                </div>
              </div>
              <OrderDetail orderDetails={this.state.orderDetails}
                orderShippingAddress={this.state.orderShippingAddress}
                orderBillingAddress={this.state.orderBillingAddress}
                orderProducts={this.state.orderProducts}
                shippingMethod={this.state.shippingMethod}
                productTotal={this.state.productTotal}
                setIsLoad={this.state.setIsLoad} />
            </div>
          </main>
        </div>
      </div>
    )
  }
}
