import BaseDomainService from "./baseDomainServices";
import { DateFromat } from "../../utils/DateFromat";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import {  SuccessMessage } from "../../utils/Messages";


const ExceptionUpdate = {
    errorNote: "",
    isSolved: false
}
const searchExceptionList = {
    searchText: ""
}
export default class exceptionService extends BaseDomainService {
    Exception = this.apiService.exceptionServices.getErrorLogList;
    getErrorLogsById = this.apiService.exceptionServices.getErrorLogsById;
    updateErrorLogStatus = this.apiService.exceptionServices.updateErrorLogStatus;
    gridColmns = [
        { name: 'CreatedDate', title: 'Date & Time' },
        { name: 'IPAddress', title: 'IP Address' },
        { name: 'country', title: 'Country' },
        { name: 'CustomErrorMessage', title: 'Custom Error Message' },
        { name: 'ModuleName', title: 'Module Name' },
        { name: 'isSolved', title: 'Solved' },
        { name: 'errorLogId', title: 'Action' }
    ];
    ExceptionformFields = [
        {
            inputType: "text",
            tittle: "Error Note",
            placeholder: "Error Note",
            fieldName: "errorNote",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Solved By",
            placeholder: "Solved By",
            fieldName: "solvedBy",
            validation: [{ type: "require" }]
        },
        {
            inputType: "checkbox",
            tittle: "IsSolved",
            placeholder: "isSolved",
            fieldName: "isSolved",
            checked: true,
            checkLabelClass: 'ml-2',
            validation: [{ type: "require" }]
        },
    ]
    formFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onExceptionList.bind(this);
        this.actionKey = "errorLogId";
        this.ipActionKey = "IPAddress";
        this.actionActive = "isSolved";
        this.dataItem = { ...ExceptionUpdate };
        this.SearchdataItem = { ...searchExceptionList };
    }

    onExceptionList = (requst) => {
        this.apiService.onAPICall("Exception", this.Exception, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdateErrorLogStatus = (requst,errorLogId) => {
        this.apiService.onAPICall("updateErrorLogStatus", this.updateErrorLogStatus, requst, this.onAPIResponse, this.onUpdateState,errorLogId);
    };
    onGetErrorLogsById = (requst) => {
        this.apiService.onAPICall("getErrorLogsById", this.getErrorLogsById, requst, this.onAPIResponse, this.onUpdateState);
    };
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load
        let valRule = getValidationRuleList(this.ExceptionformFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
        })
    }
    onRedirectoList = () => {
        this.onRedirect("/Exception");
    }
    onResetData = () => {
        this.dataItem = { ...ExceptionUpdate };
        this.onUpdateState({ ExceptionUpdate: this.dataItem });
    };

    onAPIResponse = (key, isSuccess, responseData,errorLogId) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "Exception":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : "-";
                        i.ipAddress = i.ipAddress ? i.ipAddress : '-'
                        i.country = i.country ? i.country : '-'
                    });
                }
                this.onUpdateState({
                    dataList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;

            case "getErrorLogsById":
                this.onUpdateState({
                    dataList: responseData,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false,
                    callingMethod: responseData.callingMethod,
                    customErrorMessage: responseData.customErrorMessage,
                    requestedData: responseData.requestedData,
                    trackTrace: responseData.trackTrace,
                    errorLogId: responseData.errorLogId,
                    systemErrorMessage: responseData.systemErrorMessage,
                    createdDate: responseData.createdDate,
                    ExceptionUpdate: {
                        errorNote: responseData.errorNote,
                        isSolved: responseData.isSolved,
                        solvedBy: responseData.solvedBy
                    }
                });
                break;
            case "updateErrorLogStatus":
                this.onUpdateState({
                    setIsLoad: false
                });
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Error Note"));
                this.onGetErrorLogsById(errorLogId);
                break;
        }
    }
}

