import React, { Component } from 'react'
import BaseComponent from "../../shared/baseComponent";
import informaticsConfigurationListServices from '../../services/domainServices/informaticsConfigurationServices';
import { Navigate } from "react-router-dom";
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import Button from '../../components/common/Button';
import { ButtonTypes } from '../../utils/Constants';
import ComponetWizardList from '../ComponentWizard/ComponetWizardList';

export class EditInformaticsConfiguration extends BaseComponent {
    constructor(props) {
        super(props);
        this.informaticsConfigurationListServices = new informaticsConfigurationListServices();
        this.state = {
            ...this.state,
            configurationDetails: this.informaticsConfigurationListServices.dataItem,
            setIsLoad: false,
            activeInformaticsConfigurationId: 0

        };
        this.informaticsConfigurationListServices.onUpdateState = this.updateState.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }



    componentDidMount() {
        this.informaticsConfigurationListServices.onInitService();
        let params = new URLSearchParams(window.location.search);
        let informaticsConfigurationId = params.get("");
        if (informaticsConfigurationId > 0) {
            this.informaticsConfigurationListServices.onGetInformaticsConfigurationById(informaticsConfigurationId)
            this.setState({ activeInformaticsConfigurationId: informaticsConfigurationId })
        }
    }

    handleChange(name, value) {
        let detail = this.state.configurationDetails;
        detail[name] = value;
        this.setState({ configurationDetails: { ...detail } });
    }

    onUpdateDetailsById = () => {
        if (this.informaticsConfigurationListServices.isValidSubmit(this.state.configurationDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                title: this.state.configurationDetails.title,
                isActive: this.state.configurationDetails.isActive,
                informaticsConfigurationId: this.state.configurationDetails.informaticsConfigurationId,
            }
            this.informaticsConfigurationListServices.onUpdateInformaticsConfiguration(request);
        }
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                    <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto pb-0">
                        <div className="flex items-center justify-between">
                            <div className="">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold pl-2">
                                    {this.state.activeInformaticsConfigurationId == 1 ? "Daily Informatics Configurations" : this.state.activeInformaticsConfigurationId == 2 ? "Weekly Informatics Configurations" : "Monthly Informatics Configurations"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <ComponetWizardList
                        activeInformaticsConfigurationId={this.state.activeInformaticsConfigurationId}
                    />
                </div>

            </div>
        );
    }
}

export default EditInformaticsConfiguration