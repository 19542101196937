import React from 'react';
/* Lib  */
import ModalBasic from '../../ModalBasic';
import BaseComponent from '../../../shared/baseComponent';
import FormFieldGroup from '../../FormGroup/FormFieldGroup';

//** Service */
import StockDomainService from '../../../services/domainServices/stockListService';
import ButtonGroup from '../../common/ButtonGroup';

export class StockModal extends BaseComponent {
    constructor(props) {
        super(props)
        this.stockService = props.stockService;
        this.stockServices = new StockDomainService();
        this.stockServices.onUpdateState = this.updateState.bind(this);
    }
    componentDidMount() {
        this.stockServices.onProductStockForm();
    }
    render() {
        return (
            <ModalBasic id="basic-modal_1" modalOpen={this.props.openStateModal} setModalOpen={this.props.modalOpen} title={this.props.updateStock ? "Update Product " : "Add Product"}>
                <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                    <div className="border-slate-200">
                        <div className="space-y-8 mt-8 pb-5">
                            <div className="grid gap-5 md:grid-cols-2">
                                {this.stockServices.productStockForm.map((Item, index) => {
                                    return (
                                        <FormFieldGroup
                                            value={this.props.StockDetails.length >= 1 && this.props.updateStock == true ?
                                                this.props.updateStockState[Item.fieldName] : this.props.product[Item.fieldName]}
                                            error={this.props.validState.error[Item.fieldName]}
                                            onChange={(event) => {
                                                this.props.updateStock === true ?
                                                    this.props.handleChangeupdate(Item.fieldName, event.target.value, this.props.stockIndexList) :
                                                    this.props.handleChange(Item.fieldName, event.target.value, this.props.stockIndexList)
                                            }}
                                            onBlur={() => {
                                                this.props.updateStock == true ? this.props.validateField(Item.fieldName, this.props.updateStockState, this.props.validationRules, this.props.validState)
                                                    : this.props.validateField(Item.fieldName, this.props.product, this.props.validationRules, this.props.validState)
                                            }}
                                            column={Item}
                                            key={index}
                                            options={Item.options ? Item.fieldName === "stockLocationId" ? this.props.stockLocation : null : null}
                                            onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                        />
                                    );
                                })}
                            </div>
                            <div className="flex flex-wrap justify-center mt-3 mb-4">
                                <ButtonGroup
                                    onCancelClick={(e) => this.props.onCloseModal(e)}
                                    onUpdateClick={(e) => this.props.updateStocks(e)}
                                    onAddClick={(e) => this.props.addStock(e)}
                                    updateState={this.props.updateStock}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
        )
    }
}

export default StockModal;