
import ProductServices from '../services/axiosServices/apiServices/ProductServices';
import RFQServices from '../services/axiosServices/apiServices/RFQServices';
import DashboardServices from '../services/axiosServices/apiServices/DashboardServices';
import { ErrorMessage } from '../utils/Messages';
import ManageOrderServices from '../services/axiosServices/apiServices/ManageOrderServices';
import AuthenticationServices from '../services/axiosServices/apiServices/AuthenticationServices';
import SererviceManager from './serviceManager';
import EmailServices from '../services/axiosServices/apiServices/EmailServices';
import contactUsServices from '../services/axiosServices/apiServices/ContactUsServices';
import SearchHistoryServices from '../services/axiosServices/apiServices/SearchHistoryServices';
import CompanyService from '../services/axiosServices/apiServices/CompaniesServices';
import AuditLogServices from '../services/axiosServices/apiServices/AuditLogServices';
import SwalServices from '../services/swalServices/SwalServices';
import ImportDataServices from '../services/axiosServices/apiServices/ImportDataServices';
import ManageUserServices from '../services/axiosServices/apiServices/ManageUserServices';
import ShoppingCartServices from '../services/axiosServices/apiServices/ShoppingCartServices';
import ProfileServices from '../services/axiosServices/apiServices/ProfileServices';
import ExceptionServices from '../services/axiosServices/apiServices/ExceptionServices';
import ActivityService from '../services/axiosServices/apiServices/ActivityService';
import SettingServices from '../services/axiosServices/apiServices/SettingServices';
import BlockServices from '../services/axiosServices/apiServices/BlockServices';
import ProductDashServices from '../services/axiosServices/apiServices/ProductDashServices';
import digestEmailServices from '../services/axiosServices/apiServices/DigestEmailServices';
import InformaticsConfigurationServices from '../services/axiosServices/apiServices/InformaticsConfigurationServices';
import ComponetWizardServices from '../services/axiosServices/apiServices/ComponetWizardServices';
import DatabaseHealthServices from '../services/axiosServices/apiServices/DatabaseHealthServices';

//**
export default class APISererviceManager {
    rfqService = new RFQServices();
    dashboardService = new DashboardServices();
    productService = new ProductServices();
    companyservice = new CompanyService();
    auditservice = new AuditLogServices();
    manageOrderSevices = new ManageOrderServices();
    authServices = new AuthenticationServices();
    serviceManager = new SererviceManager();
    emailServices = new EmailServices();
    swalServices = new SwalServices();
    contactUsService = new contactUsServices();
    searchHistoryServices = new SearchHistoryServices();
    importService = new ImportDataServices();
    manageUserServices = new ManageUserServices();
    shoppingCartServices = new ShoppingCartServices();
    profileServices = new ProfileServices();
    exceptionServices = new ExceptionServices();
    activityService = new ActivityService();
    settingServices = new SettingServices();
    authServices = new AuthenticationServices();
    blockServices = new BlockServices();
    productDashServices = new ProductDashServices();
    digestEmailServices = new digestEmailServices();
    InformaticsConfigurationServices = new InformaticsConfigurationServices();
    ComponetWizardServices = new ComponetWizardServices();
    DatabaseHealthServices = new DatabaseHealthServices();


    constructor() { }

    onAPICall(key, apiFun, data, onCallBack, onUpdateState, getRequest) {
        onUpdateState({
            "setIsLoad": true,
            "isBtnLoading": true
        });

        apiFun(data).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                onCallBack(key, true, response.responseItem.responseContent, getRequest);
            } else {
                onCallBack(key, false, {});
                if (key === "login") {
                    onCallBack(key, false, response, false);
                }
                else {
                    this.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
            }
        });
    }

}
