//** Componeent  */
import React, { useEffect, useState } from "react";
//*** LIb */
import { Bars } from "react-loader-spinner";
import FormFieldGroup from "../FormGroup/FormFieldGroup";
import DataImportService from "../../services/domainServices/dataImportService";
import Button from "../common/Button";
import { ButtonTypes } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";

const DataImportDetails = (props) => {

  const navigate = useNavigate();
  
  const handleBack = () => {
    navigate("/DataImportList");
  };
  const dataImportService = new DataImportService();

  return (
    <div>
      <div className="sm:flex sm:justify-between sm:items-center mb-4 mt-2">
        <div className=" sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Data Import Detail
          </h1>
        </div>
        <Button
          type={ButtonTypes.Primary}
          title="Back"
          buttonClick={handleBack}
        />
      </div>
      {props.isLoading ? (
        <Bars
          className="row"
          wrapperStyle={{ marginTop: "0px" }}
          color="#3c4b64"
          height={55}
          width="100%"
          visible={props.isLoading}
        />
      ) : (
        <>
          <div className="w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 p-3">
            <header className="sm:px-5 py-2 border-b border-slate-100">
              <h2
                className="font-semibold text-slate-800"
                style={{ fontSize: "20px" }}
              >
                Import Stock
              </h2>
            </header>
            <div className=" p-3 grid gap-2 md:grid-cols-3">
              {dataImportService.formFields.map((Item, index) => {
                return Item.detailsField === true ? (
                  Item.formType != true ? (
                    <FormFieldGroup
                      column={Item}
                      value={props.importDetails[Item.fieldName]}
                    />
                  ) : null
                ) : null;
              })}
            </div>
          </div>
          <br />
          <div className="w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 p-3">
            <header className="sm:px-5 py-2 border-b border-slate-100">
              <h2
                className="font-semibold text-slate-800"
                style={{ fontSize: "20px" }}
              >
                Import Process Report
              </h2>
            </header>
            <div className=" p-3 grid gap-2 md:grid-cols-3">
              {dataImportService.formFields.map((Item, index) => {
                return Item.detailsField === true ? (
                  Item.formType === true ? (
                    <FormFieldGroup
                      column={Item}
                      value={props.importDetails[Item.fieldName]}
                    />
                  ) : null
                ) : null;
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataImportDetails;
