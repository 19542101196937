//Components
import { axiosPost, axiosGet , axiosPostAuthorize,axiosGetAuthorize } from '../AxiosRequests';
import { Admin_AddEmailTemplate, Admin_GetEmailList, Admin_GetEmailTemplateById, Admin_UpdateEmailDetailsById } from '../ApiEndPoints';


export default class EmailServices {

    // API function
    async admin_GetEmailList(request) {
     
        return axiosPostAuthorize(Admin_GetEmailList, request);

    }

    async admin_GetEmailTemplateById(request) {
      
        return axiosGetAuthorize(Admin_GetEmailTemplateById, request);

    }

    async admin_UpdateEmailDetailsById(request) {
    
        return axiosPostAuthorize(Admin_UpdateEmailDetailsById, request);

    }

    async admin_AddEmailTemplate(request) {
       
        return axiosPostAuthorize(Admin_AddEmailTemplate, request)

    }
}