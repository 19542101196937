import React from "react";
import BaseComponent from "../../shared/baseComponent";
import informaticsConfigurationListServices from "../../services/domainServices/informaticsConfigurationServices";
import { Navigate } from "react-router-dom";
import Table from "../../components/tables/Table";
import Button from "../../components/common/Button";

/* Services  */
import { Bars } from "react-loader-spinner";
import { ButtonTypes } from "../../utils/Constants";

// const staticData = [
//     { title: "Daily", isActive: true },
//     { title: "weekly", isActive: true },
//     { title: "Monthly", isActive: true },
//     // Add more data as needed
// ];

export default class InformaticsConfigurationList extends BaseComponent {
  constructor(props) {
    super(props);
    this.informaticsConfigurationListServices =
      new informaticsConfigurationListServices();
    this.state = {
      dataList: [],
      setIsLoad: false,
      pagination: this.informaticsConfigurationListServices.pagination,
    };
    this.informaticsConfigurationListServices.onUpdateState =
      this.updateState.bind(this);
    this.informaticsConfigurationListServices.onActionCall =
      this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.informaticsConfigurationListServices.onGetInformaticsConfigurationList(
      this.state.pagination
    );
    // document.title = 'Digest Email History';
  }

  onActionCall = (type, data) => {
    switch (type) {
      case "View":
        window.open(
          `/EditInformaticsConfiguration/informaticsConfigurationId?=${data}`,
          "_blank"
        );
        break;
    }
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Informatics Configurations
                  </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3"></div>
              </div>
              <div className="">
                {this.state.setIsLoad ? (
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: "150px" }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                  />
                ) : (
                  <Table
                    columns={
                      this.informaticsConfigurationListServices.gridColmns
                    }
                    rows={this.state.dataList}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalCount={this.state.TotalCount}
                    totalResultes={this.state.TotalCount}
                    setPagination={this.informaticsConfigurationListServices.onPageChange.bind(
                      this
                    )}
                    customScope={[
                      {
                        column: "informaticsConfigurationId",
                        renderTableData:
                          this.informaticsConfigurationListServices.gridActionViewCol.bind(
                            this
                          ),
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
