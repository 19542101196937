import moment from "moment";


// DateFormat  Request
export const DateFromat = (data) => {

    if (data ) {

        var date = moment(data).format("MM-DD-YYYY h:mm")
        return date;
    }
    return data;
}