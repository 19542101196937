//Components
import { axiosPost } from '../AxiosRequests';
import { Admin_GetAuditLogList } from '../ApiEndPoints';

export default class AuditLogServices {

    // API function
    async admin_GetAuditLogList(request) {
        return axiosPost(Admin_GetAuditLogList, request);
    }
}