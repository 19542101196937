//Components
import React, { useState, useEffect, useRef } from 'react';
import { MenuOptions, MenuType } from './Menu';
import SidebarLinkGroup from './SidebarLinkGroup';
//Library
import { NavLink, useLocation } from 'react-router-dom';

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;
  const trigger = useRef(null);
  const sidebar = useRef(null);
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');
  const [active, seActive] = useState({ index: 0, subindex: 0 })
  const [setshow] = useState(false)
  // const [childSubMenuVisible, setChildSubMenuVisible] = useState(false);
  // const [openSubmenus] = useState([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);

  // const toggleSubmenu = (index) => {
  //   if (openSubmenus.includes(index)) {
  //     // Close the submenu if it's already open
  //     setOpenSubmenus(openSubmenus.filter((item) => item !== index));
  //   } else {
  //     // Open the submenu if it's closed
  //     setOpenSubmenus([...openSubmenus, index]);
  //   }
  // };
  // const toggleChildSubMenu = () => {
  //   setChildSubMenuVisible(!childSubMenuVisible);
  // };
  // Hooks 

  const closeAllMenus = () => {
    seActive({ index: -1, subindex: -1 });
  };

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);


  // *** Event function **//
  const onActive = (index, subindex) => {
    seActive(index, subindex)
  }

  return (
    <div>
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        aria-hidden="true"></div>
      {window.location.pathname !== "/UnAuthorized" ? <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-sidebar p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'
          }`}
      >
        <div className="flex justify-between mt-5 mb-5 pr-3 sm:px-2">
          <button
            ref={trigger}
            className="lg:hidden text-[#fff] hover:text-slate-200"
            onClick={() => setSidebarOpen(false)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          <NavLink end to="/dashboardCard" className="block" activeClassName="themeColor" onClick={closeAllMenus} >
            <button onClick={() => { setshow(true) }}>
              <img className='width' src='../logo-white.png' alt="logo"></img></button>
          </NavLink>
        </div>

        <div className="space-y-8">

          <div>

            <ul className="mt-3">
              {MenuOptions && MenuOptions.map((menu, index) => {
                return (
                  (menu.type === MenuType.SuperMenu) ?
                    <SidebarLinkGroup
                      activecondition={index === active ? pathname : null}>
                      {(handleClick, open) => {
                        return (
                          <React.Fragment>
                            <a
                              href="#0"
                              className={`block text-slate-200 hover:text-white truncate transition duration-150 ${(pathname === '/' || pathname.includes('')) && 'hover:themeColor-hover'
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (activeMenuIndex === index) {
                                  setActiveMenuIndex(-1); // Toggle the same menu to close it
                                } else {
                                  setActiveMenuIndex(index);
                                  if (!sidebarExpanded) {
                                    setSidebarExpanded(true);
                                  }
                                }
                              }}
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  {menu.icon ? <i className={`${menu.icon} ${(index === active ? pathname : null || pathname.includes(active)) && 'themeColor'}`}></i>
                                    :
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" x="0px" y="0px" viewBox="0 0 115.35 122.88" >
                                      <g><path className={`fill-current text-slate-100 ${(pathname === `/${active}` || pathname.includes('OrderList')) && 'themeColor'}`} d={menu.svg} /></g></svg>}
                                  <div className='ml-3'>
                                    {menu.title ? <span className={`${menu.title} ${(index === active ? pathname : null || pathname.includes(active)) && 'themeColor'}`}>{menu.title}</span>
                                      : "-"}</div>
                                </div>
                                {
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-200 ${open && 'transform rotate-180'}`}
                                      viewBox="0 0 12 12">
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                }
                              </div>
                            </a>
                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                              <ul className={`pl-9 mt-1 ${activeMenuIndex === index ? '' : 'hidden'}`}>
                                {menu.subMenus &&
                                  menu.subMenus.map((subMenu, subindex) => {
                                    // const isSubmenuOpen = openSubmenus.includes(subindex);

                                    return (
                                      <li className="mb-1 last:mb-0" key={subindex}>
                                        <NavLink
                                          end
                                          key={subindex}
                                          to={subMenu.link}
                                          className={({ isActive }) =>
                                            'block text-slate-200 hover:themeColor-hover transition duration-150 truncate ' +
                                            (isActive ? 'themeColor ' : '')
                                          }
                                        >
                                          <span
                                            onClick={() => onActive(index, subindex)}
                                            className="text-sm font-size-400 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                                          >
                                            {subMenu.title}
                                          </span>
                                        </NavLink>

                                      </li>
                                    );
                                  })}
                              </ul>

                            </div>
                          </React.Fragment>
                        );
                      }}
                    </SidebarLinkGroup>
                    :
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('inbox') && 'bg-slate-900'}`}>
                      <NavLink
                        end
                        to={menu.link}
                        className={({ isActive }) =>
                          'block text-slate-200 hover:themeColor-hover transition duration-150 truncate ' + (isActive ? 'themeColor' : 'text-white')
                        }
                      >
                        <div className="flex items-center">
                          <i onClick={() => onActive(index)} className={`${menu.icon}`}></i>
                          <span onClick={() => onActive(index)} className="ml-3"><>{menu.title}</></span>
                        </div>
                      </NavLink>
                    </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-[#fff]" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-[#fff]" d="M3 23H1V1h2z" />
              </svg>

            </button>
          </div>
        </div>
      </div> : null}

    </div>
  );
}

export default Sidebar;