import React from 'react';

const CountCard = ({ data, size }) => {
  console.log("props.data", data)

  let colSpan;

  switch (size) {
    case 1:
      colSpan = '4';
      break;
    case 2:
      colSpan = '6';
      break;
    case 3:
      colSpan = '12';
      break;
    default:
      colSpan = '4'; // Default value if size doesn't match any case
  }
  return (
    data.map((items, index) => {
      return <section className={`col-span-${colSpan}`} key={index}>
        {/* <div
          className="py-6 px-4 bg-white shadow rounded-lg center-div my-2 card-bg-light"
          key={index}
        >
          <span className="block text-xl sm:text-2xl text-gray-500">{items.data}</span>
        </div> */}
        <div className='flex py-6 px-4 bg-white shadow rounded-lg center-div card-bg-light'>
          <div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-4 icon-color-chart'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-6 w-6" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
            </svg>
          </div>
          <div className=''>
            <span class="block text-xl sm:text-2xl text-gray-500">{items.data}</span>
            <span class="block text-xl sm:text-2xl font-bold">{items.label}</span>
          </div>
        </div>
      </section>
    })
  );
};

export default CountCard;
