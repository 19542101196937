import React from 'react';
import BaseComponent from '../../shared/baseComponent';
import componetWizardServices from '../../services/domainServices/componetWizardServices';
import { Navigate } from 'react-router-dom';
import Table from '../../components/tables/Table';
import Button from '../../components/common/Button';

/* Services  */
import { Bars } from 'react-loader-spinner';
import { ButtonTypes } from '../../utils/Constants';
import { AddEditComWizardModal } from './AddEditComWizardModal';
import { ErrorMessage } from '../../utils/Messages';

export default class ComponetWizardList extends BaseComponent {

    constructor(props) {
        super(props)
        this.componetWizardServices = new componetWizardServices();
        this.state = {
            ...this.state,
            dataList: [],
            setIsLoad: false,
            pagination: {
                ...this.componetWizardServices.pagination,
                pageSize: 10,
                InformaticsConfigurationId: 0
            },
            wizarddetails: this.componetWizardServices.dataItem,
            isUpdate: false,
            setOpenModal: false,
            // activewizardId : 0

        }
        this.componetWizardServices.onUpdateState = this.updateState.bind(this);
        this.componetWizardServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.componetWizardServices.onInitService();
        let params = new URLSearchParams(window.location.search);
        let informaticsConfigurationId = params.get("");
        if (informaticsConfigurationId > 0) {
            let detail = this.state.pagination;
            detail.InformaticsConfigurationId = informaticsConfigurationId;
            this.setState({ pagination: { ...detail } });
            // this.setState({ pagination : informaticsConfigurationId })
            this.componetWizardServices.onGetInformaticsComponentWizardByIdList(this.state.pagination)
        }

        // if (this.props.activeInformaticsConfigurationId > 0) {
        // this.componetWizardServices.onGetInformaticsComponentWizardByIdList(this.state.pagination)
        // }
    }

    openModal = (event, index, data, value) => {
        this.setState({
            setOpenModal: true,
            isUpdate: true,
            stockIndex: index
        });
    };

    onCloseModal = (event) => {
        this.setState({ setOpenModal: false });
        this.componetWizardServices.onResetData();
    }

    delete = (id) => {
        this.componetWizardServices.apiService.swalServices.Confirm("Delete Warning", "Are you sure, you want to Delete Wizard?", "Yes", "No")
            .then(async confirm => {
                if (confirm) {
                    let request = {
                        Id: id
                    };
                    this.componetWizardServices.onDeleteInformaticsComponentWizard(request, this.state.pagination);
                }
            });
    }

    onActionCall = (type, event, index, value) => {
        switch (type) {
            case "Edit":
                this.componetWizardServices.onGetInformaticsComponentWizardById(value);
                this.setState({
                    setOpenModal: true,
                    isUpdate: true,
                    stockIndex: index
                });
                break;
            case "Delete":
                this.delete(value);
                break;
        }
    }

    handleChange(name, value) {
        let detail = this.state.wizarddetails;
        detail[name] = value;
        this.setState({ wizarddetails: { ...detail } });
    }

    proceed = () => {
        let detail = this.state.wizarddetails;
        let isValid = this.componetWizardServices.isValidSubmit(detail, this.state.validationRules, this.state.validState)
        if (isValid) {
            if (detail.componentWizardType === "Table" || detail.componentWizardType === "Counts") {
                this.addWizard();
            }
            else if (detail.componentWizardType === "Charts") {
                if (detail.chartType === "") {
                    this.componetWizardServices.apiService.serviceManager.swalServices.Error(ErrorMessage.charttype);
                }
                else {
                    this.addWizard();
                }

            }
        }
    }

    updateproceed = () => {
        let detail = this.state.wizarddetails;
        let isValid = this.componetWizardServices.isValidSubmit(detail, this.state.validationRules, this.state.validState)
        if (isValid) {
            if (detail.componentWizardType === "Table" || detail.componentWizardType === "Counts") {
                this.update();
            }
            else if (detail.componentWizardType === "Charts") {
                if (detail.chartType === "") {
                    this.componetWizardServices.apiService.serviceManager.swalServices.Error(ErrorMessage.charttype);
                }
                else {
                    this.update();
                }

            }
        }
    }

    addWizard = () => {
        let request = {
            title: this.state.wizarddetails.title,
            componentWizardtype: this.state.wizarddetails.componentWizardType,
            queryData: this.state.wizarddetails.queryData,
            isActive: this.state.wizarddetails.isActive,
            size: this.state.wizarddetails.size,
            informaticsConfigurationId: this.state.pagination.InformaticsConfigurationId,
            chartType: this.state.wizarddetails.chartType,
        }
        // if (this.componetWizardServices.isValidSubmit(this.state.wizarddetails, this.state.validationRules, this.state.validState)) {
        this.componetWizardServices.onAddInformaticsComponentWizard(request, this.state.pagination);
        // }
    };

    update = () => {
        let request = {
            title: this.state.wizarddetails.title,
            componentWizardtype: this.state.wizarddetails.componentWizardType,
            queryData: this.state.wizarddetails.queryData,
            isActive: this.state.wizarddetails.isActive,
            size: this.state.wizarddetails.size,
            informaticsConfigurationId: this.state.pagination.InformaticsConfigurationId,
            chartType: this.state.wizarddetails.chartType,
            componentWizardId: this.state.wizarddetails.componentWizardId,
        }
        // if (this.componetWizardServices.isValidSubmit(this.state.wizarddetails, this.state.validationRules, this.state.validState)) {
        this.componetWizardServices.onUpdateInformaticsComponentWizard(request, this.state.pagination);
        // }
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex h-screen overflow-hidden">

                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                            <div className="grid grid-flow-col sm:auto-cols-max sm:justify-end gap-3">
                                <Button type={ButtonTypes.Primary} title="Add component wizard" buttonClick={(event) => this.openModal(event, '')} isLoading={this.state.setIsLoad} />
                            </div>
                            <div className='mt-3'>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <Table
                                            columns={this.componetWizardServices.gridColmns}
                                            rows={this.state.dataList}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.componetWizardServices.onPageChange.bind(this)}
                                            customScope={[
                                                {
                                                    column: "componentWizardId",
                                                    column1: true,
                                                    renderTableData:
                                                        this.componetWizardServices.gridAction.bind(this),
                                                },
                                            ]}
                                        />
                                }
                            </div>
                        </div>
                    </main>
                </div>
                <AddEditComWizardModal
                    openStateModal={this.state.setOpenModal}
                    onCloseModal={this.onCloseModal.bind(this)}
                    modalOpen={(e) => { this.setState({ setOpenModal: false }) }}
                    onClose={(e) => {
                        this.setState({ setOpenModal: false })
                    }}
                    wizarddetails={this.state.wizarddetails}
                    validState={this.state.validState}
                    validationRules={this.state.validationRules}
                    validateField={this.componetWizardServices.validateField.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    addWizard={this.addWizard.bind(this)}
                    proceed={this.proceed.bind(this)}
                    updateproceed={this.updateproceed.bind(this)}
                />
            </div>
        )
    }
}
