//Components
import React from 'react';
import Table from '../../components/tables/Table';
//Library
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { encryptAES } from "../../utils/Encryption";
import BaseComponent from '../../shared/baseComponent';
import { ErrorMessage } from '../../utils/Messages';

//Services
import rfqServices from '../../services/domainServices/rfqServices';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';

export class RFQList extends BaseComponent {

  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.rfqService = new rfqServices();
    this.state = {
      pagination: {
        searchCASNo: "",
        searchUserName: "",
        searchRFQNumber: "",
        searchAurumCatalogId: "",
        ...this.rfqService.pagination
      },
      route: null,
      dataList: [],
      setIsLoad: false,
      rfqTypeList: this.rfqService.dataItem
    }
    this.rfqService.onUpdateState = this.updateState.bind(this);
    this.rfqService.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.rfqService.onGetRequests(this.state.pagination);
    document.title = 'RFQ';
  }

  // *** Handle Method **//
  handleChange(name, value) {
    let detail = this.state.pagination;
    detail[name] = value.trim();
    let data = { ...this.state.pagination };
    data.pageNo = 1;
    if (name === "searchRFQNumber") {
      data.searchText = null;
      data.searchAurumCatalogId = null;
      data.searchUserName = null;
      data.searchCASNo = null
    }
    if (name === "searchAurumCatalogId") {
      data.searchText = null;
      data.searchUserName = null;
      data.searchCASNo = null;
      data.searchRFQNumber = null
    }
    if (name === "searchUserName") {
      data.searchText = null;
      data.searchAurumCatalogId = null;
      data.searchCASNo = null;
      data.searchRFQNumber = null

    }
    if (name === "searchCASNo") {
      data.searchText = null;
      data.searchAurumCatalogId = null;
      data.searchUserName = null;
      data.searchRFQNumber = null

    }
    if (name === "searchText") {
      data.searchAurumCatalogId = null;
      data.searchUserName = null;
      data.searchCASNo = null;
      data.searchRFQNumber = null

    }
    this.rfqService.onGetRequests(data);
    this.setState({ pagination: { ...data } });
    this.setState({ rfqTypeList: { ...data } });

  }

  // *** Action Method **//
  onActionCall = (type, data, rfqId) => {
    let det = this.state.dataList;
    switch (type) {
      case "userDisplayName":
        let UserDetails = det.find(x => x.userDisplayName === data);
        if (UserDetails && UserDetails.userId !== 0) {
          window.open(`/UserDetails/userId?=${encryptAES(UserDetails.userId)}`, '_blank');
        } else {
          this.rfqService.apiService.swalServices.Alert(ErrorMessage.RFQDetailNotExists);
        }
        break;
      case "View":
        window.open(`/RFQDetail/RFQId?=${encryptAES(rfqId)}`, '_blank');
        break;
      case "Delete":
        this.rfqService.apiService.serviceManager.swalServices.Confirm("Are you sure, you want delete RFQ ?", " " + "", "Yes", "No")
          .then(async confirm => {
            if (confirm) {
              let request = {
                RFQId: rfqId,
                deletedBy: this.rfqService.authUser.adminUserId
              }
              this.rfqService.onDelete(request);
            }
          });

        break;
    }
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold whitespace-nowrap mr-3">RFQ History </h1>
                </div>
                <div className="grid grid-cols-4 sm:grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3 ">
                  {this.rfqService.formFields.map((Item, index) => {
                    return (<>
                      {Item.inputType === "text" ? (
                        <div className="col-md-12">
                          <FormFieldGroup className='ms-auto relative search-bar-input'
                            column={Item}
                            value={this.state.rfqTypeList[Item.fieldName]}
                            onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && this.handleChange(Item.fieldName, e.target.value)}
                            onBlur={() => null}
                            key={index} />
                        </div>
                      ) : null}</>
                    );
                  })}
                </div>
              </div>
              <div className='table-tr-rfq'>
                {(this.state.setIsLoad) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad} />
                  :
                  <Table
                    columns={this.rfqService.gridColmns}
                    rows={this.state.dataList}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalCount={this.state.TotalCount}
                    totalResultes={this.state.TotalCount}
                    setPagination={this.rfqService.onPageChange.bind(this)}
                    customScope={[
                      {
                        column: "rfqId",
                        isViewRequired: true,
                        isDeleteRequired: true,
                        renderTableData: this.rfqService.gridMainActionsCol.bind(this),
                      },
                      {
                        column: 'userDisplayName',
                        renderTableData: this.rfqService.UserRFQEmailactions.bind(this)
                      },
                    ]} />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default RFQList
