//Components
import { axiosPost, axiosGet ,axiosPostAuthorize,axiosGetAuthorize} from '../AxiosRequests';
import { Admin_GetContactUsList, Admin_GetContactUsById, Admin_AddContactUsResponse } from '../ApiEndPoints';

export default class ContactUsServices {

    // API function
    async admin_GetContactUsList(request) {
        return axiosPostAuthorize(Admin_GetContactUsList, request);
    }

    async admin_GetContactUsById(request) {
        return axiosGetAuthorize(Admin_GetContactUsById, request);
    }

    async admin_AddContactUsResponse(request) {
        return axiosPost(Admin_AddContactUsResponse, request);
    }
}