/* Component  */
import { axiosGet, axiosGetAuthorize, axiosGetMultiParams, axiosPost , axiosGetMultiParamsAuthorize, axiosGetMultiParamsFileAuthorizeblob } from '../AxiosRequests';
import {ExportGetComponentWizardsList, AddInformaticsComponentWizard , GetInformaticsComponentWizardByIdList , GetInformaticsComponentWizardById , GetComponentWizardsList , DeleteInformaticsComponentWizard, UpdateInformaticsComponentWizard } from '../ApiEndPoints';

export default class ComponetWizardServices {

    async addInformaticsComponentWizard(request) {
        return axiosPost(AddInformaticsComponentWizard, request);
    }
    async getInformaticsComponentWizardByIdList(request) {
        return axiosPost(GetInformaticsComponentWizardByIdList, request);
    }
    async getInformaticsComponentWizardById(request) {
        return axiosGetAuthorize(GetInformaticsComponentWizardById, request);
    }
    async updateInformaticsComponentWizard(request) {
        return axiosPost(UpdateInformaticsComponentWizard, request);
    }
    async deleteInformaticsComponentWizard(request) {
        return axiosPost(DeleteInformaticsComponentWizard, request);
    }
    async getComponentWizardsList(request) {
        return axiosGetMultiParams(GetComponentWizardsList, request);
    }
    async exportGetComponentWizardsList(request) {
        return axiosGetMultiParamsFileAuthorizeblob(ExportGetComponentWizardsList, request);
    }

}
