
/* Component  */
import React, { useState } from "react";

/* Library  */
import Datepicker from "react-tailwindcss-datepicker";
import { DateFromat } from "../utils/DateFromat";

function Date_Range_Pickers(props) {
    let today = new Date();
    let pastdate = null;
    let pDate = DateFromat(pastdate)
    let currentDate = DateFromat(Date());
    const [value, setValue] = useState({
        startDate: pDate,
        endDate: currentDate
    });

    /* Event function */

    const handleValueChange = (newValue) => {
        if (newValue.startDate === null || newValue.endDate === null) {
            window.location.reload();
        }
        else {
            setValue(newValue);
            props.onSelectDate(newValue);
        }
    }
    return (
        <>
            <Datepicker
                value={value}
                onChange={handleValueChange}
                showShortcuts={true}
                displayFormat={"MM/DD/YYYY"}
            />
        </>
    )
}

export default Date_Range_Pickers