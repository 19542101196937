import React, { Component } from "react";
import Table from "../../components/tables/Table";
import SettingServices from "../../services/domainServices/settingService";
import { encryptAES } from "../../utils/Encryption";
import { Navigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import BaseComponent from "../../shared/baseComponent";
import FormFieldGroup from "../../components/FormGroup/FormFieldGroup";
import { ErrorMessage } from "../../utils/Messages";

export default class ViewIpAddress extends BaseComponent {
  constructor(props) {
    super(props);
    this.settingService = new SettingServices();
    this.state = {
      ...this.state,
      pagination: this.settingService.pagination,
      getViewIpList: [],
      setIsLoad: false,
      searchList: this.settingService.dataItem,
    };
    this.settingService.onUpdateState = this.updateState.bind(this);
    this.settingService.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.settingService.onInitService();
    this.settingService.ongetIpAddressList(this.state.pagination);
    document.title = "View Ip Address List";
  }

  onActionCall = (type, data) => {
    let det = this.state.getViewIpList;
    switch (type) {
      case "SettingServices":
        window.open(
          `/IpAddressDetails/ipAddressesId?=${encryptAES(data)}`,
          "_blank"
        );
        break;
      case "ipAddress":
        let ipAddressDetails = det.find((x) => x.ipAddress === data);
        if (ipAddressDetails && ipAddressDetails.ipAddressesId !== null) {
          window.open(
            `/IpAddressDetails/ipAddressesId?=${encryptAES(
              ipAddressDetails.ipAddressesId
            )}`,
            "_blank"
          );
        } else {
          this.settingService.apiService.swalServices.Alert(
            ErrorMessage.ipAddressDetailNotExists
          );
        }
        break;
    }
  };
  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  };
  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.settingService.ongetIpAddressList(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  };
  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    View Ip Address
                  </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3 w-[400px]">
                  <div className="relative w-[300px]">
                    {this.settingService.SearchformFields.map((Item, index) => {
                      return (
                        <FormFieldGroup
                          className="ms-auto relative search-bar-input"
                          column={Item}
                          value={this.state.searchList[Item.fieldName]}
                          onChange={(event) =>
                            this.handleChangeEvent(
                              Item.fieldName,
                              event.target.value
                            )
                          }
                          onKeyPress={(e) =>
                            e.key === "Enter" &&
                            this.handleChange(Item.fieldName, e.target.value)
                          }
                          key={index}
                          onBlur={() => {
                            "";
                          }}
                        />
                      );
                    })}
                  </div>
                  <button
                    className="btn commanColorbtn text-white w-[100px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleSearching("searchText", this.state.searchList);
                    }}
                  >
                    <span className="xs:block">Search</span>
                  </button>
                </div>
              </div>

              {this.state.setIsLoad ? (
                <Bars
                  className="row"
                  wrapperStyle={{ marginTop: "150px" }}
                  color="#3c4b64"
                  height={55}
                  width="100%"
                  visible={this.state.setIsLoad}
                />
              ) : (
                <div className="price-formate">
                  <Table
                    columns={this.settingService.SettingServicesColmns}
                    rows={this.state.getViewIpList}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalCount={this.state.TotalCount}
                    totalResultes={this.state.TotalCount}
                    setPagination={this.settingService.onPageChange.bind(this)}
                    customScope={[
                      {
                        column: "ipAddressesId",
                        renderTableData:
                          this.settingService.ViewIpAddress.bind(this),
                      },
                      {
                        column: "ipAddress",
                        renderTableData:
                          this.settingService.ipAddressAction.bind(this),
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    );
  }
}
