// Component
import BaseComponent from "../../shared/baseComponent";
import Table from '../../components/tables/Table';
// library
import { Bars } from 'react-loader-spinner';
// Services 
import { encryptAES } from "../../utils/Encryption";
import { Navigate } from 'react-router-dom';
import FormFieldGroup from "../../components/FormGroup/FormFieldGroup";
import UserActivityService from "../../services/domainServices/UserActivityService";
import Date_Range_Pickers from "../../components/DateRanges";
import { ErrorMessage } from "../../utils/Messages";
export default class UserActivity extends BaseComponent {
    constructor(props) {
        super(props)
        this.userActivityService = new UserActivityService();
        this.state = {
            dataList: [],
            setIsLoad: false,
            pagination: {
                ...this.userActivityService.pagination,
                toDate: "",
                fromDate: ""
            },
            redirect: null,
            searchList: this.userActivityService.dataItem,
            startDate: '',
            changeDateValue: {
                startDate: "",
                endDate: ""
            }
        }
        this.userActivityService.onUpdateState = this.updateState.bind(this);
        this.userActivityService.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.userActivityService.onUserActivityLogs(this.state.pagination)
    }
    handleLanguage = (changeValue) => {
        this.setState({ changeDateValue: changeValue });
        let request = {
            ...this.state.pagination,
            fromDate: changeValue.startDate,
            toDate: changeValue.endDate
        }
        this.setState({ pagination: request });
        this.userActivityService.onUserActivityLogs(request);

    }
    // *** Other function **//
    onActionCall = (type, data) => {
        let det = this.state.dataList;
        switch (type) {
            case "View":
                window.open(`/ActivityDetails/id?=${encryptAES(data)}`, '_blank');
                break;
                case "ipAddress":
                    let ipAddressDetails = det.find(x => x.ipAddress === data);
                    if (ipAddressDetails && ipAddressDetails.ipAddressesId !== null) {
                        window.open(`/IpAddressDetails/ipAddressesId?=${encryptAES(ipAddressDetails.ipAddressesId)}`, '_blank');
                    } else {
                        this.userActivityService.apiService.swalServices.Alert(ErrorMessage.ipAddressDetailNotExists);
                    }
                    break;
        }
    };

    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    }
    handleChange = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        if (name === "searchText") {
            detail[name] = value.trim();
            let data = { ...this.state.pagination };
            data.pageNo = 1;
            this.userActivityService.onUserActivityLogs(data);
            this.setState({ pagination: { ...data } });

        }
        this.setState({ searchList: { ...detail } });
    }
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">User Activity</h1>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3 w-[670px]">
                                    <div className="flex items-center dashboard-date-range">
                                        <Date_Range_Pickers onSelectDate={this.handleLanguage} />
                                    </div>
                                    <div className="relative w-[300px]">
                                        <label htmlFor="action-search" className="sr-only">Search</label>
                                        {this.userActivityService.formFields.map((Item, index) => {
                                            return (
                                                <FormFieldGroup className='ms-auto relative search-bar-input'
                                                    column={Item}
                                                    value={
                                                        this.state.searchList[Item.fieldName]
                                                    }
                                                    onChange={(event) =>
                                                        this.handleChangeEvent(Item.fieldName, event.target.value)
                                                    }
                                                    onKeyPress={(e) =>
                                                        e.key === "Enter" &&
                                                        this.handleChange(Item.fieldName, e.target.value)
                                                    }
                                                    key={index} />
                                            );
                                        })}
                                    </div>
                                    <button className="btn commanColorbtn text-white w-[100px]" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                                        <span className="xs:block">Search</span>
                                    </button>

                                </div>
                            </div>



                            <div className='view-product-table table-tr-width mt-5 shopping-price-text table-tr-pvbu table-tr-pvbu1 table-tr-pvbu2 table-tr-pvbu3 table-tr-width'>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <Table
                                            columns={this.userActivityService.gridColmns}
                                            rows={this.state.dataList}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.userActivityService.onPageChange.bind(this)}
                                            customScope={[
                                                {
                                                    column: 'logId',
                                                    renderTableData: this.userActivityService.gridActionViewCols.bind(this)
                                                },
                                                {
                                                    column: "url",
                                                    renderTableData: this.userActivityService.UrlTooltip.bind(this),

                                                },
                                                {
                                                    column: 'ipAddress',
                                                    renderTableData: this.userActivityService.ipAddressUserActivityAction.bind(this , this.state.dataList)
                                                },
                                            ]}
                                        />
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
