import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  const options = {
    responsive: true,
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 3,
      },
      color: "black",
    },

    plugins: {
      legend: {
        position: "right",
        labels: {
          font: {
            size: 15,
          },
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Horizontal Bar Chart",
        font: {
          size: 15,
        },
        color: "black",
      },
    },

    // canvas: {
    //   // height: props.height || null, // Set canvas height dynamically or use null for default
    //   // width: props.size || null,   // Set canvas width dynamically or use null for default
    // },
  };

  // Extracting labels and data from the array of objects
  const labels = props.data?.map((entry) => entry.label) ?? [];
  const dataValues = props.data?.map((entry) => entry.data) ?? [];

  const data = {
    labels,
    datasets: [
      {
        label: props.title,
        data: dataValues,
        borderColor: "rgba(255, 99, 132, 0.5)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        // height:props.height,
        width: props.size,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar
      data={data}
      options={options}
      // width={props.size}
    />
  );
};

export default BarChart;
