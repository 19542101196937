import React from 'react';

//** Lib */
import Table from '../../tables/Table';
import { Bars } from 'react-loader-spinner';
import SafetyDetailsModal from './SafetyDetails';
import BaseComponent from '../../../shared/baseComponent';
import { checkParameters } from '../../../utils/Encryption';

//** Service */
import SafetyDetialsDomainSevice from '../../../services/domainServices/safetyDetailsService';

export class ProductSafetyList extends BaseComponent {

    constructor(props) {
        super(props)
        this.safetyDetailsService = new SafetyDetialsDomainSevice();
        this.state = {
            ...this.state,
            setOpenModal: false,
            isUpdate: false,
            seaftyProduct: [],
            setIsLoad: false,
            productId: 0,
            saftyindex: "",
            product: this.safetyDetailsService.seaftyProduct,
            updateSafety: {
                singleWord: "",
                hazardStatements: "",
                precautionaryStatements: "",
                tsca: "",
                safetyStatements: "",
                riskStatements: "",
                updatedBy: 0
            }
        }
        this.safetyDetailsService.onUpdateState = this.updateState.bind(this);
        this.safetyDetailsService.onActionCall = this.onActionCall.bind(this);
    }
    componentDidMount() {
        this.safetyDetailsService.onProductSafetyForm();
        let params = new URLSearchParams(window.location.search);
        let pdId = params.get("");
        if (pdId && (pdId = checkParameters(pdId, "String"))) {
            this.safetyDetailsService.onGetSafetyDetailsById(pdId);
            this.setState({ productId: pdId })
        }
    }
    openModal = (event, index) => {
        event.stopPropagation();
        if (index !== '') {
            this.setState({
                setOpenModal: true,
                isUpdate: true,
                saftyindex: index
            });
        }
        else {
            this.setState({
                setOpenModal: true,
                isUpdate: false,
                saftyindex: ''
            });
            this.safetyDetailsService.onResetData();
        }
    }
    onCloseModal = (event) => {
        this.setState({ setOpenModal: false });
        this.safetyDetailsService.onResetData();
    }
    handleChangeupdate(name, value) {
        let detail = this.state.updateSafety
        detail[name] = value;
        this.setState({ updateSafety: detail });
    }
    handleChange(name, value) {
        let detail = this.state.product;
        detail[name] = value;
        this.setState({ product: { ...detail } });
    }
    onActionCall = (type, data, index, safetyId) => {
        switch (type) {
            case "Edit":
                let update = this.state.seaftyProduct.find(c => c.productSafetyDetailId === safetyId);
                const updateSafety = {
                    productSafetyDetailId: update.productSafetyDetailId,
                    productId: this.state.productId,
                    singleWord: update.singleWord,
                    hazardStatements: update.hazardStatements,
                    precautionaryStatements: update.precautionaryStatements,
                    tsca: update.tsca,
                    safetyStatements: update.safetyStatements,
                    riskStatements: update.riskStatements,
                    updatedBy: this.safetyDetailsService.authUser.adminUserId
                };
                this.setState({ updateSafety: updateSafety }, () => {
                    this.openModal(data, index);
                });
                break;
            case "Delete":
                this.delete(safetyId);
                break;
        }
    }

    addSafety = (e) => {
        let request = {
            productId: this.state.productId,
            singleWord: this.state.product.singleWord,
            hazardStatements: this.state.product.hazardStatements,
            precautionaryStatements: this.state.product.precautionaryStatements,
            tsca: this.state.product.tsca,
            safetyStatements: this.state.product.safetyStatements,
            riskStatements: this.state.product.riskStatements,
        }
        if (this.safetyDetailsService.isValidSubmit(this.state.product, this.state.validationRules, this.state.validState)) {
            this.safetyDetailsService.onAddSafety(request, this.state.productId);
        }
    };
    updateSafety = () => {
        if (this.safetyDetailsService.isValidSubmit(this.state.updateSafety, this.state.validationRules, this.state.validState)) {
            this.safetyDetailsService.onUpdate(this.state.updateSafety, this.state.productId);
        }
    }
    delete = (id) => {
        this.safetyDetailsService.apiService.swalServices.Confirm("Delete Warning", "Are you sure, you want to Delete Product Safety?", "Yes", "No")
            .then(async confirm => {
                if (confirm) {
                    let request = {
                        productSafetyDetailId: id,
                        deletedBy: this.safetyDetailsService.authUser.adminUserId,
                    };
                    this.safetyDetailsService.onDeleteSafetyDetailsById(request, this.state.productId);
                }
            });
    }

    render() {
        return (
            <>
                <div className='flex flex-wrap float-right2 mt-3 mb-4'>
                    {this.state.seaftyProduct.length === 0 ?
                        <button className='btn buttonLeft bg-[#00769f] hover:bg-[#11769f] transition-all text-white' onClick={(event) => this.openModal(event, '')} >Add</button>
                        : null}
                </div>
                <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                    {(this.state.setIsLoad) ?
                        <div className='Bars_details'>
                            <Bars
                                className="row"
                                wrapperStyle={{ marginTop: '150px' }}
                                color="#3c4b64"
                                height={55}
                                width="100%"
                                visible={this.state.setIsLoad}
                            />
                        </div>
                        :
                        <Table
                            columns={this.safetyDetailsService.SafetyDetailsTab}
                            rows={this.state.seaftyProduct}
                            sortingColumns={'null'}
                            pagination={'null'}
                            totalCount={'null'}
                            totalResultes={'null'}
                            setPagination={'null'}
                            customScope={[
                                {
                                    column: 'productSafetyDetailId',
                                    column1: true,
                                    renderTableData: this.safetyDetailsService.gridAction.bind(this),
                                }
                            ]} />
                    }
                </div>
                <SafetyDetailsModal
                    safetyDetailsUpdate={this.state.isUpdate}
                    safetyDetails={this.state.seaftyProduct}
                    updateSafetyState={this.state.updateSafety}
                    openStateModal={this.state.setOpenModal}
                    handleChangeupdate={this.handleChangeupdate.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    safetyDetailsService={this.safetyDetailsService}
                    product={this.state.product}
                    saftyindex={this.state.saftyindex}
                    updateSafety={this.updateSafety.bind(this)}
                    addSafety={this.addSafety.bind(this)}
                    productid={this.state.productId}
                    modalOpen={(e) => { this.setState({ setOpenModal: false }) }}
                    onCloseModal={this.onCloseModal.bind(this)}
                    onClose={(e) => { e.stopPropagation(); this.setState({ setOpenModal: false }) }}
                    validState={this.state.validState}
                    validationRules={this.state.validationRules}
                    validateField={this.safetyDetailsService.validateField.bind(this)}
                />
            </>
        )
    }


}


export default ProductSafetyList;