import React from 'react';

//** Lib */
import StockModal from './StockModal';
import Table from '../../tables/Table';
import { Bars } from 'react-loader-spinner';
import BaseComponent from '../../../shared/baseComponent';
import { checkParameters } from '../../../utils/Encryption';
import { getAuthProps } from '../../../utils/AuthenticationLibrary';

//** Service */
import stockService from '../../../services/domainServices/stockListService';

export class StockList extends BaseComponent {
    constructor(state) {
        super(state)
        this.stockService = new stockService();
        this.state = {
            ...this.state,
            setOpenModal: false,
            StockDetails: [],
            addStock: [],
            isUpdate: false,
            setIsLoad: false,
            stockIndex: "",
            product: this.stockService.dataItem,
            productId: 0,
            stockLocation: [],
            updateStock: {
                stockAvailibiltyId: "",
                stockLocationId: "",
                stockAmount: "",
                updatedBy: 0
            }
        }
        this.stockService.onUpdateState = this.updateState.bind(this);
        this.stockService.onActionCall = this.onActionCall.bind(this);
    }
    componentDidMount() {
        this.stockService.onProductStockForm();
        let params = new URLSearchParams(window.location.search);
        let pdId = params.get("");
        if (pdId && (pdId = checkParameters(pdId, "String"))) {
            this.setState({ productId: pdId });
            this.stockService.onGetStockDetailsById(pdId);
        }
        this.stockService.onGetAllStockLocation();
        this.state.authUser = getAuthProps();
    }
    openModal = (event, index) => {
        event.stopPropagation();
        if (index !== '') {
            this.setState({
                setOpenModal: true,
                isUpdate: true,
                stockIndex: index
            });
        }
        else {
            this.setState({
                setOpenModal: true,
                isUpdate: false,
                stockIndex: ''
            });
            // this.stockService.onResetData();
        }
    }
    onCloseModal = (event) => {
        this.setState({ setOpenModal: false });
        this.stockService.onResetData();
    }
    onActionCall = (type, data, index, stockAId) => {
        switch (type) {
            case "Edit":
                let update = this.state.StockDetails.find(c => c.stockAvailibiltyId === stockAId);
                const updatedStock = {
                    stockAvailibiltyId: update.stockAvailibiltyId,
                    stockLocationId: update.stockLocationId,
                    stockAmount: update.stockAmount,
                    updatedBy: this.stockService.authUser.adminUserId
                };
                this.setState({ updateStock: updatedStock }, () => {
                    this.openModal(data, index);
                });
                break;
            case "Delete":
                this.delete(stockAId);
                break;
        }
    }
    handleChangeupdate(name, value) {
        let detail = this.state.updateStock
        detail[name] = value;
        this.setState({ updateStock: detail });
    }
    handleChange(name, value) {
        let detail = this.state.product;
        detail[name] = value;
        this.setState({ product: { ...detail } });
    }
    addStock = () => {
        let request = {
            productId: this.state.productId,
            stockLocationId: this.state.product.stockLocationId,
            stockAmount: this.state.product.stockAmount,
            createdBy: this.state.authUser.adminUserId
        }
        if (this.stockService.isValidSubmit(this.state.product, this.state.validationRules, this.state.validState)) {
            this.stockService.onAdmin_AddStockById(request, this.state.productId);
        }
    };
    updateStock = () => {
        if (this.stockService.isValidSubmit(this.state.updateStock, this.state.validationRules, this.state.validState)) {
            this.stockService.onAdminUpdateProductStockById(this.state.updateStock, this.state.productId);
        }
    };
    delete = (id) => {
        this.stockService.apiService.swalServices.Confirm("Delete Warning", "Are you sure, you want to Delete Stock?", "Yes", "No")
            .then(async confirm => {
                if (confirm) {
                    let request = {
                        stockAvailibiltyId: id,
                        deletedBy: this.stockService.authUser.adminUserId,
                    };
                    this.stockService.onDeleteStockById(request, this.state.productId);
                }
            });
    }

    render() {
        return (
            <>
                <div className='flex flex-wrap float-right2 mt-3 mb-4'>
                    <button className='btn buttonLeft commanColorbtn  text-white ' onClick={(event) => this.openModal(event, '')} >Add</button>
                </div>
                <div className="pagination-remove overflow-x-auto fixed_header_dashboard">
                    {(this.state.setIsLoad) ?
                        <div className='Bars_details'>
                            <Bars
                                className="row"
                                wrapperStyle={{ marginTop: '150px' }}
                                color="#3c4b64"
                                height={55}
                                width="100%"
                                visible={this.state.setIsLoad}
                            />
                        </div>
                        :
                        <Table
                            columns={this.stockService.Stocktab}
                            rows={this.state.StockDetails}
                            sortingColumns={'null'}
                            pagination={'null'}
                            totalCount={'null'}
                            totalResultes={'null'}
                            setPagination={'null'}
                            customScope={[
                                {
                                    column: 'stockAvailibiltyId',
                                    column1: true,
                                    renderTableData: this.stockService.gridAction.bind(this),
                                }
                            ]} />
                    }
                </div>
                <StockModal
                    stockDetails={this.state.product}
                    updateStockState={this.state.updateStock}
                    StockDetails={this.state.StockDetails}
                    stockIndexList={this.state.stockIndex}
                    product={this.state.product}
                    updateStock={this.state.isUpdate}
                    openStateModal={this.state.setOpenModal}
                    stockService={this.stockService}
                    stockLocation={this.state.stockLocation}
                    addStock={this.addStock.bind(this)}
                    updateStocks={this.updateStock.bind(this)}
                    onCloseModal={this.onCloseModal.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    handleChangeupdate={this.handleChangeupdate.bind(this)}
                    modalOpen={(e) => { this.setState({ setOpenModal: false }) }}
                    onClose={(e) => { e.stopPropagation(); this.setState({ setOpenModal: false }) }}
                    validState={this.state.validState}
                    validationRules={this.state.validationRules}
                    validateField={this.stockService.validateField.bind(this)}
                />
            </>
        )
    }
}

export default StockList;