import React, { Component } from 'react'
import Table from '../../components/tables/Table'
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import BaseComponent from "../../shared/baseComponent";
import BlockIpServices from '../../services/domainServices/blockIpServices';
import FormFieldGroup from '../../components/FormGroup/FormFieldGroup';
import { encryptAES } from '../../utils/Encryption';
import { ErrorMessage } from '../../utils/Messages';

export default class blockIp extends BaseComponent {
    constructor(props) {
        super(props)
        this.blockIpServices = new BlockIpServices();
        this.state = {
            ...this.state,
            pagination: this.blockIpServices.pagination,
            getBlockIpList: [],
            setIsLoad: false,
            searchList: this.blockIpServices.dataItem,
        }
        this.blockIpServices.onUpdateState = this.updateState.bind(this);
        this.blockIpServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.blockIpServices.onGetblockIpList(this.state.pagination)
    }
    onActionCall = (type, data) => {
        let det = this.state.getBlockIpList;
        switch (type) {
            case "ipAddress":
                let ipAddressDetails = det.find(x => x.ipAddress === data);
                if (ipAddressDetails.ipAddressesId && ipAddressDetails.ipAddressesId !== null) {
                    window.open(`/IpAddressDetails/ipAddressesId?=${encryptAES(ipAddressDetails.ipAddressesId)}`, '_blank');
                } else {
                    this.blockIpServices.apiService.swalServices.Alert(ErrorMessage.ipAddressDetailNotExists);
                }
                break;
        }
    };

    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    }

    handleChange = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        if (name === "searchText") {
            detail[name] = value.trim();
            let data = { ...this.state.pagination };
            data.pageNo = 1;
            this.blockIpServices.onGetblockIpList(data);
            this.setState({ pagination: { ...data } });

        }
        this.setState({ searchList: { ...detail } });
    }
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Block Ip Address</h1>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3 w-[400px]">
                                    <div className="relative w-[300px]">
                                        <label htmlFor="action-search" className="sr-only">Search</label>
                                        {this.blockIpServices.SearchformFields.map((Item, index) => {
                                            return (
                                                <FormFieldGroup
                                                    className='ms-auto relative search-bar-input'
                                                    column={Item}
                                                    value={
                                                        this.state.searchList[Item.fieldName]
                                                    }
                                                    onChange={(event) =>
                                                        this.handleChangeEvent(Item.fieldName, event.target.value)
                                                    }
                                                    onKeyPress={(e) =>
                                                        e.key === "Enter" &&
                                                        this.handleChange(Item.fieldName, e.target.value)
                                                    }
                                                    key={index} />
                                            );
                                        })}
                                    </div>
                                    <button className="btn commanColorbtn text-white w-[100px]" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                                        <span className="xs:block">Search</span>

                                    </button>

                                </div>
                            </div>

                            {
                                (this.state.setIsLoad) ?
                                    <Bars
                                        className="row"
                                        wrapperStyle={{ marginTop: '150px' }}
                                        color="#3c4b64"
                                        height={55}
                                        width="100%"
                                        visible={this.state.setIsLoad}
                                    />
                                    :
                                    <div className="price-formate">
                                        <Table
                                            columns={this.blockIpServices.SettingServicesColmns}
                                            rows={this.state.getBlockIpList}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.blockIpServices.onPageChange.bind(this)}
                                            customScope={[
                                                {
                                                    column: 'ipAddress',
                                                    renderTableData: this.blockIpServices.ipAddressAction.bind(this)
                                                }
                                            ]}

                                        />
                                    </div>
                            }
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
