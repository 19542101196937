import React, { Component } from 'react'
import baseComponent from "../../shared/baseComponent"
import { ImageURLGenerator, ImageTypes } from '../../utils/ImageURLGenerator';
import shoppingCartService from '../../services/domainServices/shoppingCartService';
import { checkParameters } from "../../utils/Encryption";
import Button from '../../components/common/Button';
import { ButtonTypes } from '../../utils/Constants';
import { Navigate } from 'react-router-dom';
import UserService from '../../services/domainServices/userService';
import { Bars } from 'react-loader-spinner';

export default class ShoppingCartDetails extends baseComponent {
    constructor(props) {
        super(props)
        this.shoppingCartService = new shoppingCartService();
        this.userService = new UserService();
        this.state = {
            ...this.state,
            pagination: this.shoppingCartService.pagination,
            getshoppingcart: [],
            totalPrice: 0,
            setIsLoad: false,
            addUserDetails: this.userService.dataItem,
        }
        this.shoppingCartService.onUpdateState = this.updateState.bind(this);
        this.userService.onUpdateState = this.updateState.bind(this);

    }
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let userId = params.get("");
        if (userId && (userId = checkParameters(userId, "Number"))) {
            if (userId > 0) {
                this.userService.onGetUserProfileById(userId);
                this.shoppingCartService.onGetShoppingCartProductsByUser(userId);
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="flex h-screen overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                        <div className="pagination-remove overflow-x-auto">
                            <div className="mb-4 sm:mb-0 shopping_details">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Shopping Cart Details</h1>
                                <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.shoppingCartService.onRedirectoList()} />

                            </div>
                            < div className="col-span-full xl:col-span-8  shadow-lg rounded-sm  my-4 activity_details">
                                <div className="">
                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 shoppingCartdetails'>
                                        <div className='flex p-4 gap-2 '>
                                            <label><b>Full Name :</b> {this.state.addUserDetails.userDisplayName}</label>
                                        </div>
                                        <div className='flex p-4 gap-2'>
                                            <label><b>Email Address :</b> {this.state.addUserDetails.userName}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.setIsLoad ? (
                                <Bars
                                    className="row"
                                    wrapperStyle={{ marginTop: '150px' }}
                                    color="#3c4b64"
                                    height={55}
                                    width="100%"
                                    visible={this.props.isLoading}
                                />
                            ) :
                                <div className="shopping-cart-table-sec mt-5">
                                    <div className="overflow-x-auto">
                                        <table className="w-full bg-white shadow-lg rounded-md ">
                                            <thead className="text-[15px] capitalize text-white rounded-sm">
                                                <tr className="flex">
                                                    <th className="p-3 whitespace-nowrap">
                                                        <div className="font-bold text-left">
                                                            Product Image
                                                        </div>
                                                    </th>
                                                    <th className="p-3 whitespace-nowrap">
                                                        <div className="font-bold text-left">
                                                            Product Details
                                                        </div>
                                                    </th>
                                                    <th className="p-3 whitespace-nowrap">
                                                        <div className="font-bold text-left">
                                                            Price &#215; Size
                                                        </div>
                                                    </th>
                                                    <th className="p-3 whitespace-nowrap">
                                                        <div className="font-bold text-left">
                                                            Quantity
                                                        </div>
                                                    </th>
                                                    <th className="p-3 whitespace-nowrap">
                                                        <div className="font-bold text-left">
                                                            Total Amount
                                                        </div>
                                                    </th>


                                                </tr>
                                            </thead>

                                            <tbody className="text-sm font-medium ">
                                                {this.state.getshoppingcart.map((e, key) => {
                                                    return (

                                                        <tr className="table-tr-custom flex items-center" key={key}>
                                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                                <div className="cart-image1">

                                                                    <img src={e.imageName} width="200" alt="" />

                                                                    <img width="200" alt=''
                                                                        src={ImageURLGenerator(
                                                                            ImageTypes.ProductDetails,
                                                                            e.imageName
                                                                        )}
                                                                    />

                                                                    <div className="product-availability">
                                                                        <div className="stockStatus in-stock">
                                                                            <i className=" icon-sm far fa-check mt-1 backorder"></i>
                                                                            {e.availabilityType}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                                <div className='q_section'>
                                                                    <div className="text-truncate pointer product-title bold-text mb-2" >
                                                                        {e.productName}
                                                                    </div>
                                                                    <div className="card_details">
                                                                        <p className='mb-1'>
                                                                            <span>Catalog :</span> {e.aurumCatalogId ? e.aurumCatalogId : "-"}                                                              </p>
                                                                        <p className='mb-1'>
                                                                            <span>CAS :</span> {e.casNo ? e.casNo : "-"}
                                                                        </p>
                                                                        <p className='mb-1'>
                                                                            <span>MDL :</span> {e.mdlNo ? e.mdlNo : "-"}
                                                                        </p>
                                                                        <p className='mb-1'>
                                                                            <span>Size :</span> {e.quantity ? e.quantity : "-"} {e.size}
                                                                        </p>
                                                                        <p className='mb-1'>
                                                                            <span>MW :</span> {e.mw ? e.mw.toFixed(2) : e.mw ? e.mw : "-"}
                                                                        </p>

                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                                <div className="font-bold">
                                                                    <span> $ {e.price}</span> &#215; {e.quantity} {e.size}
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                                <div className="font-bold">
                                                                    <span>{e.quantityAmount}</span>
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b]">
                                                                <div className="font-bold">
                                                                    $ {e.price * e.quantityAmount}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            }
                        </div>
                        <div className='total-cart-amount pt-3 border-b border-[#1e293b]'>

                            <div className='pr-2 py-2 border-t border-[#1e293b]'>
                                <p className='text-[16px] mb-2 flex items-center justify-between'>
                                    <span className='font-bold text-[#1e293b]'>No. of Products :</span>
                                    <span className='font-bold text-[#1e293b]'>{this.state.getshoppingcart.length}</span>
                                </p>
                                <p className='text-[16px] mb-2 flex items-center justify-between'>
                                    <span className='font-bold text-[#1e293b]'> Total Amount: </span>
                                    <span className='font-bold text-[#1e293b]'> $ {this.state.totalPrice.toFixed(2)}</span>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        )
    }
}
