
import React from "react";
import { ButtonTypes } from "../../utils/Constants";
import { Bars } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import Button from "../../components/common/Button";
import TinyEceEditor from "../../components/TinyEceEditor";
import FormFieldGroup from "../../components/FormGroup/FormFieldGroup";
import BaseComponent from "../../shared/baseComponent";
import ValidationText from "../../utils/validation/ValidationText";
import digestEmailServices from "../../services/domainServices/digestEmailServices";
import { checkParameters } from "../../utils/Encryption";
import ButtonGroup from "../../components/common/ButtonGroup";

export default class AddDigestEmail extends BaseComponent {
  constructor(props) {
    super(props);
    this.digestEmailServices = new digestEmailServices();
    this.state = {
      ...this.state,
      emailDetails: this.digestEmailServices.dataItem,
      resetValidationRule: {},
      isBtnLoading: false,
      isLoading: false,
      redirect: null,
      setIsLoad: false,
      paramList: [],
      QueryList: {},

    };
    this.digestEmailServices.onUpdateState = this.updateState.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  handleChange(name, value) {

    let detail = this.state.emailDetails;
    if (name === 'toEmail' || name === 'ccEmail') {
      value = value.replace(/ /g, '').replace(/,/g, ';');
    }
    detail[name] = value;
    this.setState({ emailDetails: { ...detail } });
  }

  handleChangeDefault = (e) => {
    const { name, checked } = e.target;
    let detail = this.state.emailDetails;
    detail[name] = checked;
    this.setState({ text: "" });
  };

  onAddDigestEmailTemplate = () => {
    if (this.digestEmailServices.isValidSubmit(this.state.emailDetails, this.state.validationRules, this.state.validState)) {
      this.setState({ isLoading: true });
      let request = {
        digestTemplateName: this.state.emailDetails.digestTemplateName,
        digestSubject: this.state.emailDetails.digestSubject,
        digestEmailBody: this.state.emailDetails.digestEmailBody,
        toEmail: this.state.emailDetails.toEmail,
        ccEmail: this.state.emailDetails.ccEmail ? this.state.emailDetails.ccEmail : "",
        isDailyReport: this.state.emailDetails.isDailyReport ? this.state.emailDetails.isDailyReport : 0,
        isWeeklyReport: this.state.emailDetails.isWeeklyReport ? this.state.emailDetails.isWeeklyReport : 0,
        isMonthlyReport: this.state.emailDetails.isMonthlyReport ? this.state.emailDetails.isMonthlyReport : 0,
        isActive: this.state.emailDetails.isActive ? this.state.emailDetails.isActive : 0,
        createdBy: this.digestEmailServices.authUser.adminUserId
      }
      this.digestEmailServices.onAddDigestEmailDetails(request);
    }
  };

  submitForm = () => { };

  onChange(event) {
    var newContent = event.editor.getData();
    let detail = this.state.emailDetails;
    detail["digestemailBody"] = newContent;
    this.setState({ emailDetails: { ...detail } });
  }

  componentDidMount() {
    this.digestEmailServices.onInitService();
    let params = new URLSearchParams(window.location.search);
    let emailTemplateId = params.get("");
    if (emailTemplateId && (emailTemplateId = checkParameters(emailTemplateId, "Number"))) {
      if (emailTemplateId > 0) {
        this.digestEmailServices.onGetDigestEmailDetailsById(emailTemplateId)

      }
      document.title = 'Digest Email Details';
    }
  }

  onUpdateEmailDetailsById = () => {
    if (this.digestEmailServices.isValidSubmit(this.state.emailDetails, this.state.validationRules, this.state.validState)) {
      let request = {
        digestEmailId: this.state.updateEmail,
        digestTemplateName: this.state.emailDetails.digestTemplateName,
        digestSubject: this.state.emailDetails.digestSubject,
        digestEmailBody: this.state.emailDetails.digestEmailBody,
        toEmail: this.state.emailDetails.toEmail,
        ccEmail: this.state.emailDetails.ccEmail ? this.state.emailDetails.ccEmail : "",
        isDailyReport: this.state.emailDetails.isDailyReport ? this.state.emailDetails.isDailyReport : 0,
        isWeeklyReport: this.state.emailDetails.isWeeklyReport ? this.state.emailDetails.isWeeklyReport : 0,
        isMonthlyReport: this.state.emailDetails.isMonthlyReport ? this.state.emailDetails.isMonthlyReport : "",
        isActive: this.state.emailDetails.isActive,
        queryData: JSON.stringify(this.state.paramList),
        UpdatedBy: this.digestEmailServices.authUser.adminUserId
      }
      this.digestEmailServices.onUpdateDigestEmailDetails(request);
    }
  }
  cancel = () => {
    this.setState({ redirect: "/EmailHistory" });
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  reset = () => {
    this.setState({
      emailDetails: "",
    });
  };

  setDescription = (data) => {
    if (typeof data === "string") {
      let detail = this.state.emailDetails;
      detail["digestEmailBody"] = data;
      this.setState({ emailDetails: { ...detail } });
    }
  };
  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            {this.state.setIsLoad ? (
              <Bars
                className="row"
                wrapperStyle={{ marginTop: "150px" }}
                color="#3c4b64"
                height={55}
                width="100%"
                visible={this.state.setIsLoad}
              />
            ) : (
              <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                  <div className="mb-4 sm:mb-0">
                    {this.state.updateEmail ?
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Edit Digets Email
                      </h1> :
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Add Digets Email
                      </h1>
                    }
                  </div>
                  <Button
                    type={ButtonTypes.Primary}
                    title={<i className="fas fa-arrow-left"></i>}
                    buttonClick={() => this.digestEmailServices.onRedirectoList()}
                  />
                </div>
                <div className="col-span-full xl:col-span-8 border bg-white shadow-lg rounded-sm mb-3 ">
                  <div className="grid mb-3">
                    <div className="col-span-full xl:col-span-8  rounded-sm  ">
                      <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                        <div className="space-y-8">
                          <div>
                            <div className="grid gap-5 md:grid-cols-2">
                              {this.digestEmailServices.formFields.map(
                                (Item, index) => {
                                  return (
                                    <>
                                      {Item.emailTemplate != true ? (
                                        <FormFieldGroup
                                          column={Item}
                                          value={this.state.emailDetails[Item.fieldName]}
                                          error={this.state.validState.error[Item.fieldName]}
                                          onChange={Item.inputType === "checkbox" ?
                                            (event) => this.handleChange(Item.fieldName, event.target.checked)
                                            :
                                            (event) => this.handleChange(Item.fieldName, event.target.value)
                                          }
                                          onBlur={() => this.digestEmailServices.validateField(Item.fieldName, this.state.emailDetails, this.state.validationRules, this.state.validState)}
                                          key={index}
                                          checked={this.state.emailDetails[Item.fieldName]}
                                        />
                                      ) : null}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 bg-white shadow-lg rounded-sm p-8">
                  <div>
                    <Button
                      type={ButtonTypes.Primary}
                      title="Get Query Parameters"
                      buttonClick={() => this.digestEmailServices.onGetQueryParameters()}
                      setIsLoad={false} />
                  </div>
                  <div>
                    <div className="mr-1">
                      <h1 className="text-lg text-slate-800 mb-5">
                        PlaceHolders List:
                      </h1>
                      {this.state.paramList != null ?
                        <>
                          {this.state.paramList.map((list, index) => {
                            return (
                              <span className="mr-2" key={index}>{`#${list}#`},</span>
                            );
                          })}
                        </> : null
                      }
                    </div>
                  </div>
                </div>

                <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm mb-3 mt-5">
                  <div className="grid mb-3">
                    <div className="col-span-full xl:col-span-8  rounded-sm  ">

                      <TinyEceEditor
                        className="rounded-none"
                        key={"fullPageCKEditor"}
                        onChange={(data) => {
                          this.setDescription(data);
                        }}
                        data={this.state.emailDetails.digestEmailBody}
                        onBlur={() => {
                          this.digestEmailServices.validateField("digestEmailBody", this.state.emailDetails.digestEmailBody, this.state.validationRules, this.state.validState);
                        }}
                      />
                      {
                        this.state.emailDetails.digestEmailBody ?
                          null :

                          <ValidationText
                            error={this.state.validState.error.digestEmailBody}
                          />

                      }
                      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="flex flex-wrap mt-4 sm:justify-end justify-center">
                          <ButtonGroup
                            onCancelClick={() => this.digestEmailServices.onRedirectoList()}
                            onUpdateClick={() => this.onUpdateEmailDetailsById()}
                            onAddClick={() => this.onAddDigestEmailTemplate()}
                            updateState={this.state.updateEmail}
                            isLoading={this.state.isLoading}

                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    );
  }
}
