
/* Component  */
import React, { useState } from "react";

/* Library  */
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment'
import { DateFromat } from "../utils/DateFromat";

function Date_Range_Picker(props) {

    let today = new Date();
    let pastdate = today.setDate(today.getDate() - 7);
    let pDate = DateFromat(pastdate)
    let currentDate = DateFromat(Date());
    const [value, setValue] = useState({
        startDate: pDate,
        endDate: currentDate
    });

    /* Event function */

    const handleValueChange = (newValue) => {
        if (newValue.startDate === null || newValue.endDate === null) {
            window.location.reload();
        }
        else {
            setValue(newValue);
            props.onSelectDate(newValue);
        }
    }
    return (
        <>
        <Datepicker
            value={value}
            onChange={handleValueChange}
            showShortcuts={true}
            displayFormat={"MM/DD/YYYY"}
        />
        </>
    )
}

export default Date_Range_Picker