//Components
import { axiosPost ,axiosGet , axiosGetAuthorize} from '../AxiosRequests';
import { Admin_GetUserList, Admin_GetAdminUsers, UpdateUserStatus ,sendForgetPasswordLink,GetUserDetailsByUserId,UpdateUserDetails,AddUserLogin,Admin_GetUsersDashboardCounts} from '../ApiEndPoints';

export default class ManageUserServices {
    // API function

    async admin_GetUserList(request) {
        return axiosPost(Admin_GetUserList, request);
    }

    async admin_getAdminUsers(request) {
        return axiosPost(Admin_GetAdminUsers, request);
    }
    async admin_GetUsersDashboardCounts(request) {
        return axiosGet(Admin_GetUsersDashboardCounts, request);
    }
    async updateUserStatus(request) {
        return axiosPost(UpdateUserStatus, request);
    }
    async sendForgetPasswordLink(request){
        return axiosPost(sendForgetPasswordLink, request);
    }
    async getUserDetailsByUserId(request) {
        return axiosGetAuthorize(GetUserDetailsByUserId, request);
    }
    async updateUserDetails(request) {
        return axiosPost(UpdateUserDetails, request);
    }
    async addUserLogin(request) {
        return axiosPost(AddUserLogin, request);
    }
}
