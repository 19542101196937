import BaseDomainService from "./baseDomainServices";
import { DateFromat } from '../../utils/dateFormat';
const searchHistoryList = {
    searchText: ""
}
export default class searchHistoryServices extends BaseDomainService {
    getList = this.apiService.searchHistoryServices.admin_GetAllSearchHistoryList;
    gridColmns = [{ name: 'searchType', title: 'Search Type' },
    { name: 'UserName', title: 'Email Address' },
    { name: 'searchString', title: 'Search Keyword' },
    { name: 'userIPAddress', title: 'IP Address' },
    { name: 'isp', title: 'Isp Name' },
    { name: 'crawlerName', title: 'Crawler Name' },
    { name: 'isCrawler', title: 'IsCrawler' },
    { name: 'country', title: 'Country' },
    { name: 'searchDate', title: 'Search Date' }
    ];
    // Define the state variables
    dataItem;
    formFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.actionKey = "webURL";
        this.ipActionKey = "userIPAddress";
        this.onPaginationUpdate = this.onGetSearch.bind(this);
        this.dataItem = { ...searchHistoryList };
        this.actionActive = "isCrawler";
    }

    onGetSearch = (requst) => {
        this.apiService.onAPICall("getSearch", this.getList, requst, this.onAPIResponse, this.onUpdateState);
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getSearch":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.searchDate = i.searchDate ? DateFromat(i.searchDate) : '-'
                        i.ipAddress = i.ipAddress ? i.ipAddress : '-'
                        i.country = i.country ? i.country : '-'
                        // i.isp = i.isp ? i.isp : '-';
                        // i.isCrawler = i.isCrawler ? i.isCrawler : '-';
                        // i.crawlerName = i.crawlerName ? i.crawlerName : '-';
                    })
                    this.onUpdateState({
                        dataList: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                }

                break;
        }
    }
}

