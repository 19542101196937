import React from 'react'
import BaseComponent from "../../src/shared/baseComponent";
import ProductDomainService from '../../src/services/domainServices/productDomainService';
import ProductServices from '../../src/services/axiosServices/apiServices/ProductServices';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { ErrorMessage } from '../../src/utils/Messages';
import SwalServices from '../../src/services/swalServices/SwalServices';
import FormFieldGroup from '../components/FormGroup/FormFieldGroup';

export default class ExportLink extends BaseComponent {
    constructor(props) {
        super(props)
        this.productService = new ProductDomainService();
        this.swalServices = new SwalServices();
        this.state = {
            setIsLoad: false,
            exportData: this.productService.dataExport,
        }
        this.productService.onUpdateState = this.updateState.bind(this);
    }

    exportLink = new ProductServices()

    componentDidMount() {
        this.productService.onInitExportService();
    }

    handleChange(name, value) {
        let detail = { ...this.state.exportData };
        detail[name] = value;
        this.setState({ exportData: { ...detail } });
    }

    Export = () => {
        if (this.state.exportData.supplierName === "") {
            this.swalServices.Toaster(ErrorMessage.SelectFieldRequired.replace("{0}", "Export Link"));
        } else {
            this.setState({ setIsLoad: true })
            let req = [
                this.state.exportData.supplierName
            ]
            this.exportLink.exportSupplierDirectLink(req).then((res) => {
                var filename = req[0];
                var blob = new Blob([res.data])
                saveAs(blob, filename + ".zip");
                this.setState({ setIsLoad: false })
            }).catch(error => {
                console.error('Error exporting data:', error);
                this.setState({ setIsLoad: false })
            });
        }
    };

    render() {
        return (
            <div className="flex mt-4">
                <div className="relative flex flex-col flex-1">
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8  py-2 w-full max-w-9xl mx-auto">
                            {/* <div className="sm:flex sm:justify-between sm:items-center mb-2">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-3">Product Code</h1>
                                </div>
                            </div> */}
                            <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200 activity_details">
                                <div className="p-5">
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 gap-6'>
                                        <div className='d-flex p-6 gap-3 useractivitydetails sm:flex sm:justify-between'>
                                            <div className='contents'>
                                                {this.productService.exportformFields.map((Item, index) => {
                                                    return (
                                                        <>
                                                            <FormFieldGroup
                                                                column={Item}
                                                                value={this.state.exportData[Item.fieldName]}
                                                                options={Item.Options ? Item.Options : null}
                                                                onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                                            />
                                                        </>
                                                    );
                                                })}
                                                <div className=''>
                                                    <button className="btn btn-primary sync-button gap-2" onClick={() => this.Export()}>
                                                        {this.state.setIsLoad ? <div className="loader"></div> : <><span>Export</span>
                                                            <i className='fas fa-file-excel'></i></>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
