import BaseDomainService from "./baseDomainServices";
import moment from 'moment';
import { DateFromat } from "../../utils/dateFormat";
import { formatPrice } from "../../utils/PriceFormat";


const contactUsList = {
    searchText: ""
}


export default class dashboardServices extends BaseDomainService {

    //** API Get
    getdashboardCount = this.apiService.dashboardService.admin_GetDashboardCounts;
    getRecentRFQ = this.apiService.dashboardService.admin_GetRecentRFQ;
    getRecentOrder = this.apiService.dashboardService.admin_GetRecentsOrders;
    getRecentConatctUs = this.apiService.dashboardService.admin_GetRecentContactUs;

    //** Table Grid columns
    rfqGridColmns = [
        { name: "rfqNumber", title: "RFQ No" },
        { name: "userDisplayName", title: "Email Address" },
        { name: "userName", title: "Full Name" },
        { name: "noOfCountRFQ", title: "No Of RFQ product" },
        { name: "createdDate", title: "RFQ Date" },
        { name: "rfqId", title: "Actions" },
    ];
    orderGridColmns = [
        { name: "purchaseOrder", title: "PO" },
        { name: "userdisplayname", title: "User Full Name" },
        { name: "userName", title: "Email Address" },
        { name: "phoneno", title: "Phone" },
        { name: "companyname", title: "Company Name" },
        { name: "paymentType", title: "PM" },
        { name: "totalPrice", title: "Total Price" },
        { name: "noOfProducts", title: "#Products" },
        { name: "orderDate", title: "Date" },
        { name: "orderId", title: "Actions" },
    ];
    contactUsGridColmns = [
        { name: 'fullName', title: 'Name' },
        { name: 'emailAddress', title: 'Email Address' },
        { name: 'emailAddress', title: 'Email Address' },
        { name: 'createdDate', title: 'Date Time' },
        { name: 'contactUsId', title: 'View Detail' },
    ];

    //** Constructor 
    constructor() {
        super();
        this.actionKey = "rfqId";
        this.actionOrderId = "orderId";
        this.actionContactUsId = "contactUsId";
    }

    //** Define the state variables
    dataItem;


    //** API Calls
    onGetdashboardCount = (requst) => {
        this.apiService.onAPICall("getdashboardCount", this.getdashboardCount, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetRecentRFQ = (requst) => {
        this.apiService.onAPICall("getRecentRFQ", this.getRecentRFQ, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetRecentOrder = (requst) => {
        this.apiService.onAPICall("getRecentOrder", this.getRecentOrder, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetRecentConatctUs = (requst) => {
        this.apiService.onAPICall("getRecentConatctUs", this.getRecentConatctUs, requst, this.onAPIResponse, this.onUpdateState);
    }

    //** Redirect To
    backToOrderListPage = () => {
        this.setState({ redirect: "/OrderList" });
    }
    backToContactListPage = () => {
        this.setState({ redirect: "/ContactUs" });
    }
    backToRFQListPage = () => {
        this.setState({ redirect: "/RFQList" });
    }

    //** Response Method
    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getdashboardCount":
                if (responseData !== null) {
                    let totalOrders = responseData.totalOrders;
                    let totalRFQs = responseData.totalRFQs;
                    let totalEmailLogs = responseData.totalEmailLogs;
                    let totalUsers = responseData.totalUsers;
                    let totalShoppingCart = responseData.totalShoppingCart;
                    this.onUpdateState({
                        totalOrders: totalOrders,
                        totalRFQs: totalRFQs,
                        totalUsers: totalUsers,
                        totalEmailLogs: totalEmailLogs,
                        totalShoppingCart: totalShoppingCart,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                }
                break;

            case "getRecentRFQ":
                if (responseData !== null) {
                    let itemList = responseData;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : "-";
                    });
                }
                this.onUpdateState({
                    dataList: responseData,
                    totalCount: responseData.totalCount,
                    setIsLoad: false,
                });
                break;

            case "getRecentOrder":
                if (responseData !== null) {
                    let itemList = responseData;
                    itemList.forEach(function (i) {
                        i.orderDate = i.orderDate ? DateFromat(i.orderDate) : "-"
                        i.totalPrice = i.totalPrice ? formatPrice(i.totalPrice) : "-"
                    });
                }
                this.onUpdateState({
                    recentsOrders: responseData,
                    totalCount: responseData.totalCount,
                    setIsLoad: false,
                });
                break;
            case "getRecentConatctUs":
                if (responseData !== null) {
                    let itemList = responseData;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : "-"
                    });
                }
                this.onUpdateState({
                    recentContactUs: responseData,
                    totalCount: responseData.totalCount,
                    setIsLoad: false,
                });
                break;
        }
    }
}

