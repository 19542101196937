//Components
import React from "react";

function DemoPage({}) {
  return (
    <>
      <div className="under-construction-section">
        <div className="card border-0 shadow-lg p-5 m-5">
          <div className="card-body h-full flex flex-col justify-center">
            <img
              className="mx-auto w-[400px]"
              src="./under-construction.png"
              alt="img not found"

            />
            <h1 className="text-center fs-1 text-[35px] mt-7">
              <b>
                {" "}
                This Page is Currently <br />{" "}
                <span className="bg-sidebar-text text-[35px]">Under Construction</span>
              </b>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoPage;
