// Components
import React, { Component } from 'react';
import DemoPage from "../user/DemoPage"


export class AddNewProducts extends Component {

  render() {

    return (
      <>
      <DemoPage />
      </>
    )
  }
}

export default AddNewProducts

