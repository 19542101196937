// Components
import FormPage from "../pages/formPage/FormPage";
import UserList from "../pages/user/UserList";
import UserDashboard from "../pages/user/UserDashboard";
import RFQList from "../pages/rfq-details/RFQList";
import RFQDetail from "../pages/rfq-details/RFQDetail";
import OrderList from "../pages/order/OrderList";
import Order from "../pages/order/Order";
import ProductList from "../pages/product-details/ProductList";
import ProductDetail from "../pages/product-details/ProductDetail";
import ContactUs from "../pages/ContactUs/ContactUs";
import SearchHistorylist from "../pages/searchHistory/SearchHistoryList";
import DashboardCard from "../pages/dashboard/Dashboard";
import EmailHistory from "../pages/email/EmailHistory";
import EmailDetail from "../pages/email/EmailDetail";
import AdminProfile from "../pages/profile/AdminProfile";
import AuditLogs from "../pages/AuditLogs/AuditLogs";
import DataImportList from "../pages/DataImport/DataImportList";
import DataImport from "../pages/DataImport/DataImport";
import EmailTemplate from "../pages/email/EmailTemplate";
import DataImportInfo from "../components/DataImport/DataImportInfo";
import BlockIpAddress from "../pages/blockIpAddress/BlockIpAddress";
import SiteVisitor from "../pages/SiteVisitor/SiteVisitor";
import ContactUsResponse from "../pages/ContactUs/ContactUsResponse";
import SearchHistoryListGrid from "../pages/searchHistory/SearchHistoryListGrid";
import CompaniesList from "../pages/companiesDetails.js/companiesList";
import ContactUsList from "../pages/ContactUs/ContactUsList";
import AdminUserList from "../pages/user/AdminUserList";
import UserDetails from "../components/users/UserDetails";
import AddNewProducts from "../pages/product-details/AddNewProducts";
import DemoPage from "../pages/user/DemoPage";
import UnAuthorized from "../pages/authentication/UnAuthorized";
import ShoppingCart from "../pages/shoppingcarts/ShoppingCart";
import ShoppingCartDetails from "../pages/shoppingcarts/ShoppingCartDetails";
import ViewReports from "../pages/Reports/ViewReports";
import ProductReports from "../pages/Reports/ProductReports";
import ViewProducts from "../pages/searchHistory/ProductDetailByUser";
import Exception from "../pages/Exception/Exception";
import UserActivity from "../pages/user-activity/UserActivity";
import ActivityDetails from "../pages/user-activity/ActivityDetails";
import ViewIpAddress from "../pages/setting/ViewIpAddress";
import IpAddressDetails from "../pages/setting/IpAddressDetails";
import Setting from "../pages/IPSetting/Setting";
import ExceptionDetails from "../pages/Exception/ExceptionDetails";
import blockIp from "../pages/setting/blockIp";
import ProductDashboard from "../pages/product-details/ProductDashboard";
import AddDigestEmail from "../pages/DigestEmail/AddDigestEmail";
import DigestEmailHistory from "../pages/DigestEmail/DigestEmailHistory";
import Informatics from "../pages/Informatics/Informatics";
import InformaticsConfigurationList from "../pages/InformaticsConfiguration/InformaticsConfigurationList";
import EditInformaticsConfiguration from "../pages/InformaticsConfiguration/EditInformaticsConfiguration";
import DatabaseHealth from "../pages/databaseHealth/DatabaseHealth";

const ComponentNavigation = [
  {
    path: "/",
    title: "Dashboard",
    component: DashboardCard,
    hasParams: false,
  },
  {
    path: "UserList",
    title: "UserList",
    component: UserList,
    hasParams: false,
  },
  {
    path: "UserDashboard",
    title: "UserDashboard",
    component: UserDashboard,
    hasParams: false,
  },
  { path: "RFQList", title: "RFQList", component: RFQList, hasParams: false },
  {
    path: "RFQDetail/:RFQId",
    title: "RFQDetail",
    component: RFQDetail,
    hasParams: false,
  },
  {
    path: "OrderList",
    title: "OrderList",
    component: OrderList,
    hasParams: false,
  },
  { path: "Order/:OrderId", title: "Order", component: Order, hasParams: true },
  {
    path: "ProductList",
    title: "ProductList",
    component: ProductList,
    hasParams: false,
  },
  {
    path: "ProductDetail/:productId",
    title: "Product Details",
    component: ProductDetail,
    hasParams: true,
  },
  {
    path: "ContactUs",
    title: "ContactUs",
    component: ContactUs,
    hasParams: false,
  },
  { path: "formPage", title: "Form", component: FormPage, hasParams: false },
  {
    path: "SearchHistorylist/:userId",
    title: "Form",
    component: SearchHistorylist,
    hasParams: true,
  },
  {
    path: "dashboardCard",
    title: "DashboardCard",
    component: DashboardCard,
    hasParams: false,
  },
  {
    path: "EmailHistory",
    title: "EmailHistory",
    component: EmailHistory,
    hasParams: false,
  },
  {
    path: "EmailDetail/:emailTemplateId",
    title: "EmailDetail",
    component: EmailDetail,
    hasParams: false,
  },
  {
    path: "EmailTemplate/",
    title: "EmailTemplate",
    component: EmailTemplate,
    hasParams: false,
  },
  {
    path: "AdminProfile",
    title: "AdminProfile",
    component: AdminProfile,
    hasParams: false,
  },
  {
    path: "AuditLogs",
    title: "AuditLogs",
    component: AuditLogs,
    hasParams: false,
  },
  {
    path: "SiteVisitor",
    title: "SiteVisitor",
    component: SiteVisitor,
    hasParams: false,
  },
  {
    path: "ContactusResponse/:contactUsId",
    title: "ContactUsResponse",
    component: ContactUsResponse,
    hasParams: true,
  },
  {
    path: "DataImport",
    title: "DataImport",
    component: DataImport,
    hasParams: false,
  },
  {
    path: "DataImportInfo/:importId",
    title: "DataImportDetails",
    component: DataImportInfo,
    hasParams: true,
  },
  {
    path: "DataImportList",
    title: "DataImportList",
    component: DataImportList,
    hasParams: false,
  },
  {
    path: "BlockIpAddress",
    title: "BlockIpAddress",
    component: BlockIpAddress,
    hasParams: false,
  },
  {
    path: "SearchHistoryListGrid",
    title: "SearchHistoryListGrid",
    component: SearchHistoryListGrid,
    hasParams: false,
  },
  {
    path: "ViewProducts",
    title: "ProductDetailByUser",
    component: ViewProducts,
    hasParams: false,
  },
  {
    path: "CompaniesList",
    title: "CompaniesList",
    component: CompaniesList,
    hasParams: false,
  },
  {
    path: "ContactUsList",
    title: "ContactUsList",
    component: ContactUsList,
    hasParams: false,
  },
  {
    path: "ContactUsList/:Id",
    title: "ContactUsList",
    component: ContactUsList,
    hasParams: true,
  },
  {
    path: "AdminUserList",
    title: "AdminUserList",
    component: AdminUserList,
    hasParams: false,
  },
  {
    path: "UserDetails/:Id",
    title: "UserDetails",
    component: UserDetails,
    hasParams: true,
  },
  {
    path: "UserDetails",
    title: "UserDetails",
    component: UserDetails,
    hasParams: false,
  },
  {
    path: "AddNewProducts",
    title: "AddNewProducts",
    component: AddNewProducts,
    hasParams: false,
  },
  {
    path: "AddNewProducts",
    title: "AddNewProducts",
    component: AddNewProducts,
    hasParams: false,
  },
  {
    path: "DemoPage",
    title: "DemoPage",
    component: DemoPage,
    hasParams: false,
  },
  {
    path: "UnAuthorized",
    title: "UnAuthorized",
    component: UnAuthorized,
    hasParams: false,
  },
  {
    path: "ShoppingCartDetails/:Id",
    title: "ShoppingCartDetails",
    component: ShoppingCartDetails,
    hasParams: false,
  },
  {
    path: "ShoppingCart",
    title: "ShoppingCart",
    component: ShoppingCart,
    hasParams: false,
  },
  {
    path: "ViewReports",
    title: "ViewReports",
    component: ViewReports,
    hasParams: false,
  },
  {
    path: "ProductReports",
    title: "ProductReports",
    component: ProductReports,
    hasParams: false,
  },
  {
    path: "Exception",
    title: "Exception",
    component: Exception,
    hasParams: false,
  },
  {
    path: "UserActivity",
    title: "UserActivity",
    component: UserActivity,
    hasParams: false,
  },
  {
    path: "ActivityDetails/:id",
    title: "ActivityDetails",
    component: ActivityDetails,
    hasParams: false,
  },
  {
    path: "ExceptionDetails/:id",
    title: "ExceptionDetails",
    component: ExceptionDetails,
    hasParams: false,
  },
  { path: "Setting", title: "Setting", component: Setting, hasParams: false },
  {
    path: "ViewIpAddress",
    title: "View Ip Address",
    component: ViewIpAddress,
    hasParams: false,
  },
  {
    path: "blockIp",
    title: "Block Ip Address",
    component: blockIp,
    hasParams: false,
  },
  {
    path: "ProductDashboard",
    title: "Product Dashboard",
    component: ProductDashboard,
    hasParams: false,
  },
  {
    path: "IpAddressDetails/:id",
    title: "Ip Address Details",
    component: IpAddressDetails,
    hasParams: false,
  },
  {
    path: "DigestEmailHistory",
    title: "DigestEmailHistory",
    component: DigestEmailHistory,
    hasParams: false,
  },
  {
    path: "AddDigestEmail",
    title: "AddDigestEmail",
    component: AddDigestEmail,
    hasParams: false,
  },
  {
    path: "AddDigestEmail/:emailTemplateId",
    title: "AddDigestEmail",
    component: AddDigestEmail,
    hasParams: false,
  },
  {
    path: "Informatics",
    title: "Informatics",
    component: Informatics,
    hasParams: false,
  },
  {
    path: "InformaticsConfigurationList",
    title: "Informatics Configurations",
    component: InformaticsConfigurationList,
    hasParams: false,
  },
  {
    path: "EditInformaticsConfiguration/:id",
    title: "Informatics Configurations",
    component: EditInformaticsConfiguration,
    hasParams: false,
  },

  {
    path: "DatabaseHealth",
    title: "Database Health",
    component: DatabaseHealth,
    hasParams: false,
  },
];

export default ComponentNavigation;
