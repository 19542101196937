//Component
import React, { Component } from 'react';
import Table from '../../components/tables/Table';
//Library
import { Bars } from 'react-loader-spinner';
import moment from 'moment';
//Services
import FormFieldGroup from "../../components/FormGroup/FormFieldGroup"
import baseComponent from "../../shared/baseComponent"
import Auditservice from "../../services/domainServices/AuditServices"

export class AuditLog extends baseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.Auditservices = new Auditservice();
    this.state = {
      pagination: this.Auditservices.pagination,
      getAuditLog: [],
      isLoading: false,
      searchList: this.Auditservices.dataItem,
    }
    this.Auditservices.onUpdateState = this.updateState.bind(this)
  }

  // Other function 
  componentDidMount() {
    this.Auditservices.onGetAuditList(this.state.pagination)
  }

  /* Event function */

  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.Auditservices.onGetAuditList(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  }

  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  }
  render() {
    return (
      <div className="flex h-screen overflow-hidden bg-[#f8fcff] min-h-screen">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>

            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Audit Log History</h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3 w-[400px]">
                  <div className="relative w-[300px]">
                      {this.Auditservices.SearchformFields.map((Item, index) => {
                        return (
                          <>
                            {Item.inputType === "search" ?
                              <FormFieldGroup className='ms-auto'
                                column={Item}
                                value={this.state.searchList[Item.fieldName]}
                                onChange={(event) => this.handleChangeEvent(Item.fieldName, event.target.value)}
                                onBlur={Item.onBlur ? Item.onBlur : null}
                                onKeyPress={(e) =>
                                  e.key === "Enter" &&
                                  this.handleChange(Item.fieldName, e.target.value)
                                }
                                key={index} /> : null}</>)
                      })}
                    </div>
                    <button className="btn commanColorbtn text-white w-[100px]" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                      <span className="xs:block">Search</span>
                    </button>
                </div>
              </div>
              {
                (this.state.isLoading) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.isLoading}
                  />
                  :
                  <Table
                    columns={this.Auditservices.AuditgridColmns}
                    rows={this.state.getAuditLog}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.TotalCount}
                    totalCount={this.state.TotalCount}
                    setPagination={this.Auditservices.onPageChange.bind(this)}
                  />
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default AuditLog

