//Components
import { axiosPost , axiosGet } from '../AxiosRequests';
import { GetInformaticsConfigurationList , GetInformaticsConfigurationById , UpdateInformaticsConfiguration } from '../ApiEndPoints';

export default class InformaticsConfigurationServices {

    async getInformaticsConfigurationList(request) {
        return axiosPost(GetInformaticsConfigurationList, request);
    }
    async getInformaticsConfigurationById(request) {
        return axiosGet(GetInformaticsConfigurationById, request);
    }
    async updateInformaticsConfiguration(request) {
        return axiosPost(UpdateInformaticsConfiguration, request);
    }

}