import BaseDomainService from "./baseDomainServices";
import { DateFromat } from "../../utils/DateFromat";
const searchHistoryList = {
    searchText: ""
}
export default class UserActivityService extends BaseDomainService {

    useractivityLogs = this.apiService.activityService.getUserActivityLogList;
    getUserActivityLogById = this.apiService.activityService.getUserActivityLogById;

    gridColmns = [
        { name: 'userName', title: 'Email Address' },
        { name: 'userDisplayName', title: 'Full Name' },
        { name: 'companyName', title: 'Company Name' },
        { name: 'action', title: 'Action' },
        { name: 'url', title: 'Url' },
        { name: 'timestamp', title: 'Date & Time' },
        { name: 'ipAddress', title: 'Ip Address' },
        { name: 'isp', title: 'Isp Name' },
        { name: 'country', title: 'Country' },
        { name: 'logId', title: 'Action' }
    ];
    UserActivityForm = [
        {
            inputType: "label",
            tittle: "Email Address :",
            fieldName: "userName",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Full Name :",
            fieldName: "userDisplayName",
            className: 'col-md-4',
        },

        {
            inputType: "label",
            tittle: "Company Name :",
            fieldName: "companyName",
            className: 'col-md-4',
        },
    ]
    // Define the state variables
    dataItem;
    formFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onUserActivityLogs.bind(this);
        this.actionKey = "logId";
        this.actionKey = "url";
        this.ipActionKey = "ipAddress";
        this.dataItem = { ...searchHistoryList };
        this.actionActive = "isCrawler";
    }

    onUserActivityLogs = (requst) => {
        this.apiService.onAPICall("useractivityLogs", this.useractivityLogs, requst, this.onAPIResponse, this.onUpdateState);
    }
    ongetUserActivityLogById = (requst) => {
        this.apiService.onAPICall("getUserActivityLogById", this.getUserActivityLogById, requst, this.onAPIResponse, this.onUpdateState);
    };

    onRedirectoList = () => {
        this.onRedirect("/UserActivity");
    }



    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "useractivityLogs":
                let itemListed = responseData.itemList;
                itemListed.forEach(function (i) {
                    i.timestamp = i.timestamp ? DateFromat(i.timestamp) : "-";
                    i.ipAddress = i.ipAddress ? i.ipAddress : '-'
                    i.country = i.country ? i.country : '-';
                    // i.isp = i.isp ? i.isp : '-';
                    // i.isCrawler = i.isCrawler ? i.isCrawler : '-';
                    // i.crawlerName = i.crawlerName ? i.crawlerName : '-';
                }); this.onUpdateState({
                    dataList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false,
                });
                break;
            case "getUserActivityLogById":
                let itemLists = responseData;
                this.onUpdateState({
                    "dataListData": itemLists,
                    "setIsLoad": false,
                    "data": itemLists.data,
                    "userDisplayName": itemLists.userDisplayName,
                    "userName": itemLists.userName,
                    "ipAddress": itemLists.ipAddress,
                    "action": itemLists.action,
                });

                break;

        }
    }
}

