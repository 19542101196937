import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";


const emailDetails = {
  digestTemplateName: "",
  digestSubject: "",
  digestEmailBody: "",
  toEmail: "",
  ccEmail: "",
  isDailyReport: false,
  isWeeklyReport: false,
  isMonthlyReport: false,
  isActive: true,
  queryData: "",
  digestEmailId: 0,
  createdBy: 0
}

export default class digestEmailServices extends BaseDomainService {
  // API Get
  getList = this.apiService.digestEmailServices.admin_GetDigestEmailList;
  addDigestEmailDetails = this.apiService.digestEmailServices.admin_AddDigestEmailTemplate
  getDigestEmailDetailsById = this.apiService.digestEmailServices.admin_GetDigestEmailTemplateById
  updateDigestEmailDetails = this.apiService.digestEmailServices.admin_UpdateDigestEmailDetailsById
  getQueryParameters = this.apiService.digestEmailServices.admin_GetQueryParameters;

  //Table Grid columns
  gridColmns = [
    { name: "digestTemplateName", title: "Email Template Name" },
    { name: "digestSubject", title: "Subject" },
    { name: "isActive", title: "IsActive" },
    { name: "digestEmailId", title: "Actions" },
  ];

  // Define the state variables
  dataItem;

  // define the form fields
  formFields = [
    {
      inputType: "text",
      tittle: "Email Template Name",
      placeholder: "Email Template Name",
      fieldName: "digestTemplateName",
      validation: [{ type: "require" }],
      isUpdate: true

    },
    {
      inputType: "text",
      tittle: "Subject",
      placeholder: "Subject",
      fieldName: "digestSubject",
      validation: [{ type: "require" }],
      isUpdate: true
    },
    {
      inputType: "text",
      tittle: "To Emails",
      placeholder: "To Emails",
      fieldName: "toEmail",
      validation: [{ type: "require" }],
      isUpdate: true
    },
    {
      inputType: "text",
      tittle: "CC Emails",
      placeholder: "CC Emails",
      fieldName: "ccEmail",
      validation: [{}],
      isUpdate: true
    },
    {
      inputType: "checkbox",
      fieldName: "isDailyReport",
      tittle: "Daily",
      checkLabelClass: 'ml-2',
      checked: true,
      isUpdate: true,
      emailTemplate: false
    },
    {
      inputType: "checkbox",
      fieldName: "isWeeklyReport",
      tittle: "Weekly",
      checkLabelClass: 'ml-2',
      checked: true,
      isUpdate: true
    },
    {
      inputType: "checkbox",
      tittle: "Monthly",
      fieldName: "isMonthlyReport",
      checkLabelClass: 'ml-2',
      checked: true,
      isUpdate: true
    },
    {
      inputType: "checkbox",
      tittle: "IsActive",
      fieldName: "isActive",
      checkLabelClass: 'ml-2',
      checked: true,
      isUpdate: true
    },
    {
      inputType: "",
      tittle: "Email Body",
      fieldName: "digestEmailBody",
      validation: [{ type: "require" }],
      emailTemplate: true
    },
  ];


  constructor() {
    super();
    this.onPaginationUpdate = this.onGetRequests.bind(this);
    this.actionKey = "digestEmailId";
    this.dataItem = { ...emailDetails };
  }

  //** API Calls

  onGetRequests = (requst) => { this.apiService.onAPICall("getRequest", this.getList, requst, this.onAPIResponse, this.onUpdateState); };
  onGetDigestEmailDetailsById = (requst) => { this.apiService.onAPICall("getDigestEmailDetailsById", this.getDigestEmailDetailsById, requst, this.onAPIResponse, this.onUpdateState); };
  onAddDigestEmailDetails = (requst) => { this.apiService.onAPICall("addDigestEmailDetails", this.addDigestEmailDetails, requst, this.onAPIResponse, this.onUpdateState); };
  onUpdateDigestEmailDetails = (requst) => { this.apiService.onAPICall("updateDigestEmailDetails", this.updateDigestEmailDetails, requst, this.onAPIResponse, this.onUpdateState); };
  onGetQueryParameters = (requst) => { this.apiService.onAPICall("getQueryParameters", this.getQueryParameters, requst, this.onAPIResponse, this.onUpdateState); };

  //** Service Event *//

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
    let valRule = getValidationRuleList(this.formFields)
    this.validationRules = valRule;
    this.onUpdateState({
      "validationRules": valRule,
    })
  }

  onResetData = () => {
    this.dataItem = { ...emailDetails };
    this.onUpdateState({ emailDetails: this.dataItem });
  };
  //** Methods     */

  onRedirectoList = () => {
    this.onRedirect("/DigestEmailHistory");
  }

  onAPIResponse = (key, isSuccess, responseData) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "getRequest":
        if (responseData.itemList !== null) {
          let itemList = responseData.itemList;
          itemList.forEach(function (i) {
            i.digestTemplateName = i.digestTemplateName ? i.digestTemplateName : "-"
            i.digestSubject = i.digestSubject ? i.digestSubject : "-"
          });
        }
        this.onUpdateState({
          dataList: responseData.itemList,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
        });
        break;
      case "getDigestEmailDetailsById":
        let userDet = ({ ...responseData });
        this.dataItem = { ...userDet };
        if (responseData != null) {
          this.onUpdateState({
            emailDetails: {
              digestTemplateName: userDet.digestTemplateName ? userDet.digestTemplateName : '-',
              digestSubject: userDet.digestSubject ? userDet.digestSubject : '-',
              digestEmailBody: userDet.digestEmailBody ? userDet.digestEmailBody : '-',
              toEmail: userDet.toEmail ? userDet.toEmail : '-',
              ccEmail: userDet.ccEmail ? userDet.ccEmail : '-',
              isDailyReport: userDet.isDailyReport ? userDet.isDailyReport : 0,
              isWeeklyReport: userDet.isWeeklyReport ? userDet.isWeeklyReport : 0,
              isMonthlyReport: userDet.isMonthlyReport ? userDet.isMonthlyReport : 0,
              isActive: userDet.isActive ? userDet.isActive : 0,
            },
            updateEmail: userDet.digestEmailId,
            paramList: JSON.parse(userDet.queryData),
            setIsLoad: false,
            isBtnLoading: false
          });

        }
        else {
          this.apiService.swalServices.Toaster("The column are invalid")
        }
        break;
      case "addDigestEmailDetails":
        if (responseData.hasError == false) {
          this.apiService.swalServices.Toaster(SuccessMessage.Add_Success.replace("{0}", "Email Template"));
          this.onUpdateState({
            addEmailTemplate: responseData,
            setIsLoad: false
          });
          this.onResetData();
          this.onRedirectoList();
        }
        else {
          this.apiService.swalServices.Toaster("The column are invalid")
        }
        break;

      case "updateDigestEmailDetails":
        if (responseData.hasError === false) {
          this.apiService.swalServices.Toaster(SuccessMessage.Update_Success.replace("{0}", "Email Template"));
          //this.onGetDigestEmailDetailsById(responseData.keyId)
          this.onUpdateState({
            emailDetails: responseData,
            setIsLoad: false
          });
          this.onRedirectoList();
        }
        else {
          this.apiService.swalServices.Toaster(responseData.message.DefaultMessage);
        }
        break;
      case "getQueryParameters":
        this.onUpdateState({
          paramList: responseData,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
          queryObj: JSON.stringify(responseData),
          isBtnLoading: false
        });
        break;
    }

  };
}
