//Component
import React, { Component } from 'react'

export default class DataNotFound extends Component {
    render() {
        return (
            <>
                <div className="flex items-center flex-col justify-center h-full w-full absolute left-0 top-0 shadow-lg">
                    <div>
                        <img className='h-[50px]' src='/data-notfound.png' alt=''></img>
                    </div>
                    <div className="p-2">
                        <div className="  font-bold">
                            No Data Found
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
