import APISererviceManager from "../../libs/apiSererviceManager";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";

export default class BaseDomainService {
  pagination = {
    pageNo: 1,
    pageSize: 25,
    orderByColumn: "CreatedDate",
    orderFlag: 1,
    searchText: "",
  };

  apiService;
  serviceManager;
  authUser;
  onUpdateState;
  onActionCall;
  onPaginationUpdate;
  productActionKey;
  actionKey;
  actionOrderId;
  actionContactUsId;

  constructor() {
    this.apiService = new APISererviceManager();
    this.authUser = getAuthProps();
  }

  // Validation
  validateField = (key, data, rules, currentState) => {
    const isValidData = validate(key, data, rules, currentState);
    this.onUpdateState({ validState: isValidData });
  };
  isValidSubmit = (data, rules, currentState) => {
    const isValidData = isValidForm(data, rules, currentState);
    if (data.catalogCleanerFileName === "" || data.cleanerFileName === "") {
      this.onUpdateState({ validState: isValidData });
      return (isValidData.isValid = true);
    } else {
      this.onUpdateState({ validState: isValidData });
      return isValidData.isValid;
    }
  };
  //Redirect to other page
  onRedirect = (redirectUrl) => {
    this.onUpdateState({ redirect: redirectUrl });
  };
  // Pagination Metods
  onPageChange = (newPagination) => {
    this.pagination = newPagination;
    this.onUpdateState({ pagination: newPagination }, () => {
      this.onPaginationUpdate(this.pagination);
    });
  };

  gridEditActionsCol = (element, index, value) => {
    return element !== this.actionKey ? null : (
      <>
        <td className="">
          <i
            className="fa fa-pencil cursor-pointer"
            onClick={() => this.onActionCall("Edit", value)}
          ></i>
        </td>
      </>
    );
  };

  gridActionsCol = (element, index, value, isDeleteRequired = false) => {
    return element !== this.actionKey ? null : (
      <>
        <td className="">
          <i
            className="fa fa-pencil cursor-pointer"
            onClick={() => this.onActionCall("Edit", value)}
          ></i>

          {isDeleteRequired === true ? (
            <>
              <i
                className="fa fa-trash ms-3"
                aria-hidden="true"
                onClick={() => this.onActionCall("Delete", value)}
              ></i>
            </>
          ) : null}
        </td>
      </>
    );
  };

  gridActionViewCol = (element, index, value) => {
    return element !== this.actionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        {this.actionKey === "Actions" ? (
          <a href="">
            {" "}
            <i
              className="fas fa-eye cursor-pointer"
              onClick={() => this.onActionCall("View", index)}
            ></i>
          </a>
        ) : (
          <i
            className="fas fa-eye cursor-pointer"
            onClick={() => this.onActionCall("View", value)}
          ></i>
        )}
      </td>
    );
  };
  gridActionErrorCols = (element, index, value) => {
    return element !== "errorLogId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
        <i
          className="fas fa-eye  cursor-pointer ... d-flex justify-content-center"
          onClick={() => this.onActionCall("View", value)}
        ></i>
      </td>
    );
  };
  gridActionStatusIdCol = (element, value) => {
    return element !== "errorStatusId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer archive_color">
        {value === 2 ? <p>Archive</p> : <p>Open</p>}
      </td>
    );
  };
  gridSctionContactUsId = (element, index, value) => {
    return element !== this.actionContactUsId ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        <i
          className="fas fa-eye cursor-pointer"
          onClick={() => this.onActionCall("editcontactus", value)}
        ></i>
      </td>
    );
  };
  gridActionOrderId = (element, index, value) => {
    return element !== this.actionOrderId ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        <i
          className="fas fa-eye cursor-pointer"
          onClick={() => this.onActionCall("edit", value)}
        ></i>
      </td>
    );
  };
  UserListactions = (element, index, value, userListState = []) => {
    let details = userListState;
    let x = details.find((x) => x.userId === value);
    return element !== "userId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
        <i
          className="fas fa-eye  cursor-pointer ... d-flex justify-content-center username-text mr-3"
          onClick={() => this.onActionCall("UserList", index, value)}
        ></i>
        {x.isActive === true ? (
          <i
            className="fas fa-sign-in-alt cursor-pointer"
            style={{ color: "rgb(32 168 60 / var(--tw-bg-opacity))" }}
            data-toggle="tooltip"
            data-placement="top"
            title={"Login"}
            onClick={() => this.onActionCall("userLogin", index, value)}
          ></i>
        ) : (
          <i
            className="fas fa-times text-red-500"
            data-toggle="tooltip"
            data-placement="top"
            title={"User Not Active"}
          ></i>
        )}
      </td>
    );
  };
  gridaRFQctions = (element, index, value) => {
    return element !== "rfqId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        <i
          className="fas fa-eye themeColor  cursor-pointer ..."
          onClick={() => this.onActionCall("RFQEdit", value)}
        ></i>
      </td>
    );
  };
  gridOrderactions = (element, index, value) => {
    return element !== "orderId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        <i
          className="fas fa-eye  themeColor cursor-pointer ..."
          onClick={() => this.onActionCall("Edit", value)}
        ></i>
      </td>
    );
  };
  gridActionProductView = (element, index, value) => {
    return element !== this.productActionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        <i
          className="fas fa-edit themeColor cursor-pointer"
          onClick={(e) => this.onActionCall("View", index, e)}
        ></i>
      </td>
    );
  };
  UserEmailactions = (element, index, value) => {
    return element !== "userName" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text">
        {value === "userName" ? (
          <i
            className="username-text"
            onClick={() => this.onActionCall("userName", value)}
          >
            {value}
          </i>
        ) : (
          <i
            className="username-text"
            onClick={() => this.onActionCall("userName", value)}
          >
            {value}
          </i>
        )}
      </td>
    );
  };
  UserRFQEmailactions = (element, index, value) => {
    return element !== "userDisplayName" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text">
        {value === "userDisplayName" ? (
          <i
            className="username-text"
            onClick={() => this.onActionCall("userDisplayName", value)}
          >
            {value}
          </i>
        ) : (
          <i
            className="username-text"
            onClick={() => this.onActionCall("userDisplayName", value)}
          >
            {value}
          </i>
        )}
      </td>
    );
  };
  UserEmailRfqactions = (element, index, value) => {
    return element !== "userName" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text">
        {value === "userName" ? (
          <i
            className="username-text"
            onClick={() => this.onActionCall("userNamedetails", value)}
          >
            {value}
          </i>
        ) : (
          <i
            className="username-text"
            onClick={() => this.onActionCall("userNamedetails", value)}
          >
            {value}
          </i>
        )}
      </td>
    );
  };

  gridActionViewUserId = (element, index, value) => {
    return element !== "userId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
        <i
          className="fas fa-eye  cursor-pointer ... d-flex justify-content-center"
          onClick={() => this.onActionCall("View", value)}
        ></i>
      </td>
    );
  };
  Productaction = (element, index, value) => {
    return element !== "productName" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap   productName-text">
        {value ? (
          <i
            className="username-text"
            onClick={() => this.onActionCall("ProductName", value)}
          >
            {value}
          </i>
        ) : (
          "N.A"
        )}
      </td>
    );
  };
  ProductCatlogAction = (element, index, value) => {
    return element !== "aurumCatalogId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap   productName-text">
        {value ? (
          <i
            className="username-text"
            onClick={() => this.onActionCall("Catlog", value)}
          >
            {value}
          </i>
        ) : (
          "N.A"
        )}
      </td>
    );
  };
  gridActionViewColOrder = (element, index, value) => {
    return element !== "orderId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        {this.actionKey === "Actions" ? (
          <a href="" target="_blank">
            {" "}
            <i
              className="fas fa-eye cursor-pointer"
              onClick={() => this.onActionCall("View", index)}
            ></i>
          </a>
        ) : (
          <>
            <i
              className="fas fa-eye cursor-pointer"
              onClick={() => this.onActionCall("View", value)}
            ></i>
            <i
              className="fas fa-trash cursor-pointer m-2"
              onClick={() => this.onActionCall("Delete", value)}
            ></i>
          </>
        )}
      </td>
    );
  };

  gridActionViewColpayment = (element, index, value) => {
    return element !== "paymentType" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
        {value === "PO" || value === "Net30" ? (
          <>PO</>
        ) : (
          <i className="fas fa-money-check  cursor-pointer ... d-flex justify-content-center"></i>
        )}
      </td>
    );
  };
  gridActionViewCols = (element, index, value) => {
    return element !== "logId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
        <i
          className="fas fa-eye  cursor-pointer ... d-flex justify-content-center"
          onClick={() => this.onActionCall("View", value)}
        ></i>
      </td>
    );
  };
  tooltip = (element, index, value) => {
    return element !== this.actionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap tooltip themeColor pointer cursor-pointer ">
        <div className="tooltip">
          <a href="">
            {" "}
            <i
              className="fas fa-info-circle cursor-pointer  "
              onClick={() => this.onActionCall("View", value)}
            ></i>
            <span className="tooltiptext">{value} </span>
          </a>
        </div>
      </td>
    );
  };
  activityTooltip = (element, index, value) => {
    return element !== this.actionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap tooltip themeColor pointer cursor-pointer ">
        <div className="tooltipp">
          <a href="">
            {" "}
            <i
              className="fas fa-info-circle cursor-pointer  "
              onClick={() => this.onActionCall("View", value)}
            ></i>
            <span className="tooltipptext">{value} </span>
          </a>
        </div>
      </td>
    );
  };
  UrlTooltip = (element, index, value) => {
    return element !== this.actionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap tooltip themeColor pointer cursor-pointer ">
        <div className="tooltipp">
          <a>
            {" "}
            <i
              className="fas fa-info-circle cursor-pointer  "
              onClick={() => this.onActionCall("urls", value)}
            ></i>
            <span className="tooltipptext">{value} </span>
          </a>
        </div>
      </td>
    );
  };
  UserProductIdactions = (element, index, value) => {
    return element !== "catalogId" ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text">
        {value === "catalogId" ? (
          <a href="" target="_blank">
            <i
              className="username-text"
              onClick={() => this.onActionCall("productIds", value)}
            >
              {value}
            </i>
          </a>
        ) : (
          <i
            className="username-text"
            onClick={() => this.onActionCall("productIds", value)}
          >
            {value}
          </i>
        )}
      </td>
    );
  };
  ViewIpAddress = (element, index, value) => {
    return element !== this.actionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer username-text">
        <i
          className="fas fa-eye  cursor-pointer ... d-flex justify-content-center username-text"
          onClick={() => this.onActionCall("SettingServices", value)}
        ></i>
      </td>
    );
  };

  gridAction = (element, index, value, isDelete = false) => {
    return element !== this.actionKey ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer d-flex">
        <i
          className="fas fa-edit themeColor cursor-pointer mr-3"
          onClick={(e) => this.onActionCall("Edit", e, index, value)}
        ></i>
        {isDelete === true ? (
          <i
            className="fas fa-solid fa-trash themeColor cursor-pointer"
            onClick={(e) => this.onActionCall("Delete", e, index, value)}
          ></i>
        ) : null}
      </td>
    );
  };

  gridMainActionsCol = (
    element,
    index,
    value,
    isActionRequired = false,
    isViewRequired = false,
    isDeleteRequired = false
  ) => {
    return element !== this.actionKey ? null : (
      <>
        <td className="">
          {isViewRequired === true ? (
            <i
              className="fas fa-eye  cursor-pointer themeColor mr-3"
              onClick={() => this.onActionCall("View", index, value)}
            ></i>
          ) : null}
          {isDeleteRequired === true ? (
            <i
              className="fas fa-solid fa-trash themeColor cursor-pointer"
              onClick={() => this.onActionCall("Delete", index, value)}
            ></i>
          ) : null}
        </td>
      </>
    );
  };

  gridCheckBoxCol = (element, index, value) => {
    return element !== this.actionActive ? null : (
      <td className="text-left ml">
        <div className="form-check">
          <input
            disabled
            className="form-check-input ml-5"
            style={{ position: "relative" }}
            type="checkbox"
            id="inlineFormCheck"
            checked={value}
          />
        </div>
      </td>
    );
  };

  ipAddressAction = (element, index, value) => {
    return element !== this.ipActionKey ? null : (
      <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text">
        <button onClick={() => this.onActionCall(this.ipActionKey, value)}>
          <i className="username-text">{value}</i>
        </button>
      </td>
    );
  };

  ipAddressUserActivityAction = (element, index, value) => {
    // Check if the index matches the ipActionKey
    if (index !== this.ipActionKey) return null;

    // Extracting values from the log object at the specified index
    const { crawlerName, isp, isCrawler, ipAddress } = element[value];

    return (
        <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap tooltip themeColor pointer cursor-pointer ">
            <button className="tooltipp" onClick={() => this.onActionCall(this.ipActionKey, ipAddress)}>
                <i className="username-text">{ipAddress}</i>
                {/* Tooltip */}
                <span className="tooltipptextUserActivity">
                    {/* Display tooltip content */}
                    {/* <div className="flex margin-bottom-0">
                        <p className="flex min-w-[111px] justify-start text-[12px] ">Crawler Name</p> <span className=""> : &nbsp;{crawlerName}</span>
                    </div> */}
                    <div className="flex margin-bottom-0">
                        <p className="flex min-w-[90px] justify-start text-[12px] ">Crawler Name</p> <span className=""> : &nbsp;{crawlerName}</span>
                    </div>
                    <div className="flex margin-bottom-0">
                        <p className="flex min-w-[90px] justify-start text-[12px] ">Is Crawler</p> <span className=""> : &nbsp;{isCrawler}</span>
                    </div>

                </span>
            </button>
        </td>
    );
}

}
