//Components
import LayoutNavigation from "./navigation/LayoutNavigation";
import "./css/style.scss";
import ComponentNavigation from "../src/navigation/ComponentNavigation";
//Library
import { getAuthProps, setAuthProps } from "./utils/AuthenticationLibrary";

const App = () => {
  // Other function
  const authDetails = getAuthProps();
  if (window.location.pathname !== "/login") {
    if (authDetails) {
      ComponentNavigation.forEach((element) => {
        element.authDetails = authDetails;
      });
    } else if (window.location.pathname === "/UnAuthorized") {
    } else {
      window.location.href = "/login";
    }
  }

  return (
    <LayoutNavigation
    componentRoutes={ComponentNavigation}
    authDetails={authDetails}
  />
  );
};

export default App;
