// Components
import React from 'react';
import Table from '../../components/tables/Table';
import baseComponent from "../../shared/baseComponent"

// Library
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
// Services
import AdminuserService from '../../services/domainServices/AdminuserService';

export default class AdminUserList extends baseComponent {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.AdminuserService = new AdminuserService();
    this.state = {
      pagination: this.AdminuserService.pagination,
      setIsLoad: false,
      getAdminUsers: [],
    }
    this.AdminuserService.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.AdminuserService.onGetAdminList(this.state.pagination);
    document.title = 'User List';
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-5 lg:px-5 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Admin Users </h1>
                </div>
              </div>
              {
                (this.state.setIsLoad) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                  />
                  :
                  <Table
                    columns={this.AdminuserService.AdminListgridColmns}
                    rows={this.state.getAdminUsers}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalAdminResultes}
                    totalCount={this.state.TotalCount}
                    setPagination={this.AdminuserService.onPageChange.bind(this)}
                  />
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}
