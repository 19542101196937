/* eslint-disable default-case */
import BaseDomainService from './baseDomainServices';
import { FormFieldTypes } from '../../utils/Constants';
import { ErrorMessage, SuccessMessage } from '../../utils/Messages';
import { getValidationRuleList } from '../../utils/validation/generateValidationRule';

const safetyProduct = {
    singleWord: "",
    hazardStatements: "",
    precautionaryStatements: "",
    tsca: "",
    safetyStatements: "",
    riskStatements: "",
};

const exportData = {
    supplierName: "",
}

const addPriceList = {
    currencyId: 1,
    sizeId: 0,
    price: 0,
    quantity: '',
}
const requestPropertyDetail = {
    mf: "",
    mw: 0,
    rotBonds: 0,
    hdonor: 0,
    hacceptor: 0,
    polarSA: 0
};
const stockDetails = {
    stockLocation: "",
    stockAmount: ""
};
export default class ProductDomainService extends BaseDomainService {

    // API Get
    getProductsList = this.apiService.productService.get_Admin_GetAllProductsList;
    // ############## Product Details API Get ##############
    getProductById = this.apiService.productService.admin_GetProductsDetailsById;
    updateProductById = this.apiService.productService.admin_UpdateProductDetailsById;
    // Delivery Time
    getDeliveryProductById = this.apiService.productService.admin_GetDeliveryAvailibilityByProductId;
    updateDeliveryProduct = this.apiService.productService.admin_updateProductDeliveryTime;
    // Availability
    getAvailability = this.apiService.productService.getAllAvailability;
    // Price
    addPrice = this.apiService.productService.Admin_AddPriceById;
    getSizes = this.apiService.productService.getSizes;
    getAllCurrencies = this.apiService.productService.getAllCurrencies;
    //Property
    updateProductProperties = this.apiService.productService.admin_UpdateProductPropertiesById;
    addProductProperties = this.apiService.productService.admin_AddProductPropertiesById;
    //saftey
    getSafetyDetailById = this.apiService.productService.getSafetyDetailsById;
    //Stock
    admin_AddStockById = this.apiService.productService.admin_AddStockById;
    adminUpdateProductStockById = this.apiService.productService.admin_UpdateProductStockById;
    getAllStockLocation = this.apiService.productService.getAllStockLocation;

    adminGetAllEmptyProductCodeCounts = this.apiService.productService.adminGetAllEmptyProductCodeCounts;
    updateRandonNumberForProductCode = this.apiService.productService.updateRandonNumberForProductCode;

    //Table Grid columns
    gridColmns = [
        { name: 'aurumCatalogId', title: 'Catalog Id' },
        { name: 'productName', title: 'Product Name' },
        { name: 'casNo', title: 'CasNo' },
        { name: 'isActive', title: 'Active' },
        { name: 'Actions', title: 'Actions' }
    ];
    priceTabColumn = [
        { name: "size", title: "Pack Size" },
        { name: "price", title: " Price" },
        { name: "quantity", title: " Quantity" },
        { name: "PackId", title: " Action" },
    ]
    gridColmnsForProperty = [
        { name: "mf", title: "Mol. Formula" },
        { name: "mw", title: " Mol. Weight" },
        { name: "rotBonds", title: "Rot. Bonds" },
        { name: "hacceptor", title: " H Acceptor" },
        { name: "hdonor", title: " H Donor" },
        { name: "polarSA", title: " Polar SA" },
        { name: "productPropertiesId", title: " Action" },
    ]
    SafetyDetailsTab = [
        { name: "singleWord", title: "Signal Word" },
        { name: "hazardStatements", title: " Hazard Statements" },
        { name: "precautionaryStatements", title: "Precautionary Statements" },
        { name: "tsca", title: " TSCA" },
        { name: "safetyStatements", title: "Safety Statements" },
        { name: "riskStatements", title: " Risk Statements" },
        { name: "PackId", title: " Action" },
    ];
    Stocktab = [
        { name: "name", title: "Stock" },
        { name: "stockAmount", title: " Stock Amount" },
        // { name: "size", title: " Size" },
        { name: "PackId", title: " Action" },
    ];
    // define the form fields
    formFields = [
        {
            inputType: "search",
            tittle: "Search Text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none',
            validation: [{ type: "require" }]
        },
    ]
    form = {
        formFields: [
            {
                type: FormFieldTypes.Input,
                title: "Catalog",
                isRequired: false,
                isDisabled: true,
                placeholder: "Catalog",
                key: "catalogId",

            },
            {
                type: FormFieldTypes.Input,
                title: "CAS",
                isRequired: true,
                maxLength: 50,
                isDisabled: false,
                placeholder: "CAS",
                key: "casNo",
                validation: [{ type: "require", message: ErrorMessage.FieldRequired.replace("{0}", "CAS") }]
            },
            {
                type: FormFieldTypes.Input,
                title: "Product Name",
                isRequired: true,
                isDisabled: false,
                placeholder: "Product Name",
                maxLength: 500,
                key: "productName",
                validation: [{ type: "require", message: ErrorMessage.FieldRequired.replace("{0}", "product name") }]
            },
            {
                type: FormFieldTypes.Input,
                title: "MDL",
                isRequired: true,
                isDisabled: false,
                placeholder: "MDL",
                maxLength: 50,
                key: "mdlNo",
                validation: [{ type: "require", message: ErrorMessage.FieldRequired.replace("{0}", "MDL") }]
            },
            {
                type: FormFieldTypes.Select,
                title: "Availability",
                isRequired: true,
                isDisabled: false,
                placeholder: "Select Availability",
                key: "availabilityId",
                options: [],
                validation: [{ type: "require", message: ErrorMessage.SelectRequired.replace("{0}", "availability") }],
                textKey: "availabilityType",
                valueKey: "availabilityId"
            },
            {
                type: FormFieldTypes.Input,
                title: "PURITY",
                isRequired: true,
                isDisabled: false,
                placeholder: "PURITY",
                maxLength: 50,
                key: "purity",
                validation: [{ type: "require", message: ErrorMessage.FieldRequired.replace("{0}", "PURITY") }]
            },
            {
                type: FormFieldTypes.CheckBox,
                title: "Active",
                isDisabled: false,
                key: "isActive"
            },
        ]
    }
    //** Product Details- Property 
    productPropertyForm = [
        {
            inputType: "text",
            tittle: "Mol. Formula",
            placeholder: "Mol. Formula",
            fieldName: "mf",
            className: 'w-full',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Mol. Weight",
            placeholder: "Mol. Weight",
            fieldName: "mw",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Rot. Bonds",
            placeholder: "Rot. Bonds",
            fieldName: "rotBonds",
            pattern: "[0-9]*",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "H Acceptor",
            placeholder: "H Acceptor",
            fieldName: "hacceptor",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "H Donor",
            placeholder: "H Donor",
            fieldName: "hdonor",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Polar SA",
            placeholder: "Polar SA",
            fieldName: "polarSA",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
    ]

    exportformFields = [
        {
            inputType: "select",
            tittle: "Export Link :",
            placeholder: "Select Link",
            fieldName: "supplierName",
            isDefault: true,
            Options: [
                { name: "Sci"},
                { name: "PubChem"},
                { name: "Reax"},
                { name: "Coupa"},
                { name: "Acd"},
            ],
            className: "exportLink flex",
        },
    ]

    //** Service Event */
    onInitService() {
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }
    onInitPropertyService() {
        let valRule = getValidationRuleList(this.productPropertyForm)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })

    }
    onInitExportService(){
        let valRule = getValidationRuleList(this.exportformFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    dataItem;
    seaftyProduct;
    stockDetails;
    dataPropertyItem;
    dataExport;
    constructor() {
        super();
        this.actionKey = "Actions";
        this.productActionKey = "PackId";
        this.dataItem = { ...addPriceList };
        this.onPaginationUpdate = this.onGetProductsList.bind(this);
        this.dataPropertyItem = { ...requestPropertyDetail };
        this.dataExport = { ...exportData };
        this.seaftyProduct = { ...safetyProduct };
        this.stockDetails = { ...stockDetails };
    }

    //** API Calls 
    onGetProductsList = (requst) => {
        this.apiService.onAPICall("getProduct", this.getProductsList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetProductById = (requst) => {
        this.apiService.onAPICall("getProductById", this.getProductById, requst, this.onAPIResponse, this.onUpdateState);
    }

    onUpdateProductById = (requst) => {
        this.apiService.onAPICall("updateProductById", this.updateProductById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetDeliveryProductById = (requst) => {
        this.apiService.onAPICall("deliveryProductById", this.getDeliveryProductById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAvailability = (requst) => {
        this.apiService.onAPICall("getAvailability", this.getAvailability, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdateRandonNumberForProductCode = (requst) => {
        this.apiService.onAPICall("updateRandonNumberForProductCode", this.updateRandonNumberForProductCode, requst, this.onAPIResponse, this.onUpdateState);
    };
    onAdminGetAllEmptyProductCodeCounts = (requst) => {
        this.apiService.onAPICall("adminGetAllEmptyProductCodeCounts", this.adminGetAllEmptyProductCodeCounts, requst, this.onAPIResponse, this.onUpdateState);
    };

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getProduct":

                this.onUpdateState({
                    "dataList": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "setIsLoad": false
                });
                break;
            case "getProductById":
                this.onUpdateState({
                    "productsDetails": responseData,
                    "productId": responseData.productId,
                    "setIsLoad": false
                });
                this.onGetAvailability();
                this.onGetDeliveryProductById(responseData.productId);
                break;
            case "updateProductById":

                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
                else {
                    this.onUpdateState({
                        "isBtnLoading": false,
                        "setIsLoad": false
                    });
                    this.apiService.serviceManager.swalServices.Toaster(SuccessMessage.Update_Success.replace("{0}", "Product"));
                }
                break;
            case "deliveryProductById":
                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
                else {
                    this.onUpdateState({
                        "productStockAndDeliveryTime": responseData,
                        "setIsLoad": false
                    });
                }
                break;
            case "updateDelivery":
                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Product DeliveryTime"));
                }
                break;
            case "getAvailability":
                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
                else {
                    let newForm = { ...this.form };
                    let newFormFields = [...newForm.formFields];
                    newFormFields[newFormFields.findIndex(f => f.key === "availabilityId")].options = responseData;
                    newForm.formFields = [...newFormFields];
                    this.onUpdateState({
                        "availabilities": responseData,
                        "setIsLoad": false
                    });
                }
                break;
            case "updateRandonNumberForProductCode":
                if (responseData.errorType == "EXISTS" && responseData.hasError == true) {
                    this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "Product Code"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Product Code Details"));
                    this.onAdminGetAllEmptyProductCodeCounts()
                }
                this.onUpdateState({
                    setIsLoad: false
                });
                break;
            case "adminGetAllEmptyProductCodeCounts":
                this.onUpdateState({
                    productCodeCount: responseData.emptyProductCodeCounts,
                    setIsLoad: false
                });
                break;
        }
    }
}