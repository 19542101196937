import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function RadarChart(props) {
  const labels = props.data?.map((entry) => entry.label) ?? [];
  const dataValues = props.data?.map((entry) => entry.data) ?? [];

  const data = {
    labels,
    datasets: [
      {
        label: props.title,
        data: dataValues,
        borderColor: "#49ca3d4f",
        backgroundColor: "#49ca3d4f",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    elements: {
      bar: {
        borderWidth: 3,
      },
      color: "black",
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 15,
          },
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Radar Chart",
        font: {
          size: 15,
        },
        color: "black",
      },
    },
    // canvas: {
    //   // height: props.height || null, // Set canvas height dynamically or use null for default
    //   // width: props.size || null,   // Set canvas width dynamically or use null for default
    // },
  };

  return (
    <div className="radar-chart-sec">
      <Radar
        options={options}
        data={data}
        // width={props.size}
      />
    </div>
  );
}

export default RadarChart;
