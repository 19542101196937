//Components
import { axiosGet, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
import { GetRFQList, Admin_GetRFQById, Admin_GetProductPricesById, Admin_AddRFQResponse,Admin_GetUserRFQDetails,DeleteRFQById } from '../ApiEndPoints';

export default class RFQServices {
    // API function
    async getRFQList(request) {
        return axiosPost(GetRFQList, request);
    }

    async admin_GetRFQById(request) {
        return axiosGet(Admin_GetRFQById, request);
    }

    async admin_GetProductPricesById(request) {
        return axiosGet(Admin_GetProductPricesById, request);
    }

    async admin_AddRFQResponse(request) {
        return axiosPost(Admin_AddRFQResponse, request);
    }
    async admin_GetUserRFQDetails(request) {
        return axiosPost(Admin_GetUserRFQDetails, request);
    }
    async DeleteRFQById(request) {
        return axiosPostAuthorize(DeleteRFQById, request);
    }
}