import React from "react";

const SelectField = (request) => {
    let props = request.request;
    let column = props.column;
    return (<>
        <select
          className="form-input w-full AdjustForm"
          value={props.value}
          onChange={props.onChange}
          id={props.column.fieldName}
          onBlur={props.column.onBlur ? props.onBlur : null}
          name={props.column.fieldName}>
          <option value="" disabled>
            {props.column.placeholder}
          </option>
          {props.options ?.map((operationalList, index) => (
            <>
            <option key={index} value={operationalList.id} name={operationalList.name}>
              {operationalList.name}
            </option>
            </>
          ))}
        </select>
        </>)
}


export default SelectField;
