import BaseDomainService from './baseDomainServices';
import { FormFieldTypes } from '../../utils/Constants';
import { SuccessMessage } from '../../utils/Messages';


export default class ProfileDomainServices extends BaseDomainService {

    //** API Get
    getById = this.apiService.profileServices.admin_GetAdminUsersById;
    update = this.apiService.profileServices.admin_UpdateAdminUsersById;
    resetPassword = this.apiService.profileServices.admin_ResetPassword;

    //** Define the form fields
    form = {
        formTitle: "Form",
        formFields: [
            {
                type: FormFieldTypes.Input,
                title: "First Name",
                isRequired: true,
                isDisabled: false,
                maxLength: 50,
                placeholder: "First Name",
                key: "firstName",
                validation: [{ type: "require", message: "Please enter First Name" }]
            },
            {
                type: FormFieldTypes.Input,
                title: "Last Name",
                isRequired: true,
                isDisabled: false,
                maxLength: 50,
                placeholder: "Last Name",
                key: "lastName",
                validation: [{ type: "require", message: "Please enter Last Name" }]
            },
            {
                type: FormFieldTypes.Input,
                title: "Email Id",
                isRequired: true,
                isDisabled: false,
                maxLength: 200,

                placeholder: "Email Id",
                key: "userName",
                validation: [{ type: "require", message: "Please enter Email Id" }]
            },
            {
                type: FormFieldTypes.CheckBox,
                title: "Is Active",
                isRequired: true,
                isDisabled: false,
                key: "isActive",
            },
            {
                type: FormFieldTypes.CheckBox,
                title: "Is SuperAdmin",
                isRequired: true,
                isDisabled: false,
                key: "isSuperAdmin",
            },
        ]
    }
    reSetform = {
        formFields: [
            {
                type: FormFieldTypes.Password,
                title: "Password",
                isRequired: true,
                isDisabled: false,
                placeholder: "Password",
                maxLength: 32,
                key: "password",
                validation: [{ type: "require", message: "Please enter Password" }]
            },
            {
                type: FormFieldTypes.Password,
                title: "Confirm Password",
                isRequired: true,
                isDisabled: false,
                maxLength: 32,
                placeholder: "confirm  Password",
                key: "confirm_password",
                validation: [{ type: "require", message: "Password doesn't match" }]
            },
        ]
    }


    //** API Calls 
    onGetById = (requst) => {
        this.apiService.onAPICall("getById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdate = (requst) => {
        this.apiService.onAPICall("update", this.update, requst, this.onAPIResponse, this.onUpdateState);
    }
    onResetPassword = (requst) => {
        this.apiService.onAPICall("updatePassword", this.resetPassword, requst, this.onAPIResponse, this.onUpdateState);
    }

    //** Methods     */

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getById":
                this.onUpdateState({
                    "getAdminUser": responseData,
                    "formObject": responseData,
                    "setIsLoad": false
                });
                break;
            case "update":
                this.onUpdateState({
                    "setIsLoad": false
                });
                this.apiService.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Your Profile"));
                break;
            case "updatePassword":
                this.onUpdateState({
                    "setIsLoad": false
                });
                this.apiService.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Your Password "));
                break;
        }
    }
}
