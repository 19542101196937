import React, { useEffect, useState } from 'react'
import dashboardServices from '../../services/domainServices/dashboardServices';
import { Link } from 'react-router-dom';

const DashboardCard = () => {
  const [totalOrders, setTotalOrders] = useState('');
  const [totalRFQs, setTotalRFQs] = useState('');
  const [totalUsers, setTotalUsers] = useState('');
  const [totalEmailLogs, setTotalEmailLogs] = useState('');
  const [totalShoppingCart, setTotalShoppingCart] = useState('');

  const dataImportService = new dashboardServices();

  useEffect(() => {
    const onUpdateState = (newState) => {
      setTotalOrders(newState.totalOrders);
      setTotalRFQs(newState.totalRFQs);
      setTotalUsers(newState.totalUsers);
      setTotalEmailLogs(newState.totalEmailLogs);
      setTotalShoppingCart(newState.totalShoppingCart);
    };

    dataImportService.onUpdateState = onUpdateState;
    dataImportService.onGetdashboardCount();

    // Cleanup function to remove the event listener
    return () => {
      dataImportService.onUpdateState = null;
    };
  }, []);

  return (
    <div>
      <section className="grid md:grid-cols-2 xl:grid-cols-5 gap-4">
        
        <Link to="/OrderList"  target="_blank" rel="noopener noreferrer">
          <div className='flex items-center py-6 px-4 bg-white shadow rounded-lg center-div Dashboard-card-count'>
            <div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-4 icon-color-new'>
              <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
              </svg>
            </div>
            <div className=''>
            <span class="block text-gray-500 text-[14px]">Total Orders</span>
              <span class="block text-[16px] font-bold">{totalOrders}</span>
            </div>
          </div>
        </Link>
        <Link to="/ShoppingCart"  target="_blank" rel="noopener noreferrer">
          <div className='flex items-center py-6 px-4 bg-white shadow rounded-lg center-div Dashboard-card-count'>
            <div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-4 icon-color-new'>
              <i className='far text-3xl fa-vial'></i>
            </div>
            <div className=''>
              <span class="block text-gray-500 text-[14px]">Total Shopping Cart</span>
              <span class="block text-[16px] font-bold">{totalShoppingCart}</span>
            </div>
          </div>
        </Link>
        <Link to="/RFQList"  target="_blank" rel="noopener noreferrer">
          <div className='flex items-center py-6 px-4 bg-white shadow rounded-lg center-div Dashboard-card-count'>
            <div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-4 icon-color-new'>
              <i className='far text-3xl  fa-file-invoice'></i>

            </div>
            <div className=''>
              <span class="block text-gray-500 text-[14px]">Total RFQ</span>
              <span class="block text-[16px] font-bold">{totalRFQs}</span>
            </div>
          </div>
        </Link>
        <Link to="/UserList"  target="_blank" rel="noopener noreferrer">
          <div className='flex items-center py-6 px-4 bg-white shadow rounded-lg center-div Dashboard-card-count'>
            <div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-4 icon-color-new'>
              <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
              </svg>
            </div>
            <div className=''>
              <span class="block text-gray-500 text-[14px]">Total Users</span>
              <span class="block text-[16px] font-bold">{totalUsers}</span>
            </div>
          </div>
        </Link>
        <div className='flex items-center py-6 px-4 bg-white shadow rounded-lg center-div Dashboard-card-count'>
          <div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 round-shadow rounded-full mr-4 icon-color-new'>
            <i className='far text-3xl fa fa-envelope'></i>

          </div>
          <div className=''>
            <span class="block text-gray-500 text-[14px]">Email remaining</span>
            <span class="block text-[16px] font-bold">{totalEmailLogs}</span>
          </div>
        </div>
      </section>

    </div>
  )
}

export default DashboardCard;