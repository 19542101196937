import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const dbhealth = {
    title: "",
    query: "",
    isActive: false,
    databaseHealthId: 0,
    createdBy: 0,
    updatedBy: 0,
};

export default class databaseHealthServices extends BaseDomainService {

    addDatabaseHealthWizard = this.apiService.DatabaseHealthServices.addDatabaseHealthWizard;
    getDatabaseHealthWizardList = this.apiService.DatabaseHealthServices.getDatabaseHealthWizardList;
    getDatabaseHealthWizardById = this.apiService.DatabaseHealthServices.getDatabaseHealthWizardById;
    updateDatabaseHealthWizard = this.apiService.DatabaseHealthServices.updateDatabaseHealthWizard;
    deleteDatabaseHealthWizard = this.apiService.DatabaseHealthServices.deleteDatabaseHealthWizard;
    getDatabaseHealthWizardOutput = this.apiService.DatabaseHealthServices.getDatabaseHealthWizardOutput;


    formFields = [
        {
            inputType: "text",
            tittle: "Title",
            placeholder: "Title",
            fieldName: "title",
            validation: [{ type: "require" }],
            className: "col-span-6",
        },
        {
            inputType: "textarea",
            tittle: "SQL Query Data",
            errorMessage: "SQL Query Data (* , HashedPassword , PasswordSalt , IsUsedActivationLink , Password , Query)",
            placeholder: "SQL Query Data",
            fieldName: "query",
            validation: [{
                type: "sqlvalidation"
            }, { type: "require" }
            ],
            className: "col-span-12",
        },
    ];

    dataItem;

    constructor() {
        super();
        this.onPaginationUpdate =
            this.onGetDatabaseHealthWizardList.bind(this);
        this.actionKey = "databaseHealthId";
        this.dataItem = { ...dbhealth };
    }

    onAddDatabaseHealthWizard = (requst, getRequest) => {
        this.apiService.onAPICall(
            "addDatabaseHealthWizard",
            this.addDatabaseHealthWizard,
            requst,
            this.onAPIResponse,
            this.onUpdateState,
            getRequest
        );
    };

    onGetDatabaseHealthWizardList = (requst) => {
        this.apiService.onAPICall(
            "getDatabaseHealthWizardList",
            this.getDatabaseHealthWizardList,
            requst,
            this.onAPIResponse,
            this.onUpdateState
        );
    };

    onGetDatabaseHealthWizardById = (requst) => {
        this.apiService.onAPICall(
            "getDatabaseHealthWizardById",
            this.getDatabaseHealthWizardById,
            requst,
            this.onAPIResponse,
            this.onUpdateState
        );
    };

    onUpdateDatabaseHealthWizard = (requst, getRequest) => {
        this.apiService.onAPICall(
            "updateDatabaseHealthWizard",
            this.updateDatabaseHealthWizard,
            requst,
            this.onAPIResponse,
            this.onUpdateState,
            getRequest
        );
    };

    onDeleteDatabaseHealthWizard = (requst, getRequest) => {
        this.apiService.onAPICall(
            "deleteDatabaseHealthWizard",
            this.deleteDatabaseHealthWizard,
            requst,
            this.onAPIResponse,
            this.onUpdateState,
            getRequest
        );
    };

    onGetDatabaseHealthWizardOutput = (requst) => {
        this.apiService.onAPICall(
            "getDatabaseHealthWizardOutput",
            this.getDatabaseHealthWizardOutput,
            requst,
            this.onAPIResponse,
            this.onUpdateState
        );
    };

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load
        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;
        this.onUpdateState({
            validationRules: valRule,
        });
    }

    onResetData = () => {
        const initialDetails = { ...dbhealth };
        this.dataItem = { ...initialDetails };
        let resetValidation = {
            isValid: false,
            error: {},
        };
        this.onUpdateState({
            dbhealth: this.dataItem,
            validState: resetValidation,
        });
    };

    onAPIResponse = (key, isSuccess, responseData, statePagination) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "addDatabaseHealthWizard":
                if (responseData.hasError == false) {
                    this.apiService.swalServices.Toaster(
                        SuccessMessage.Add_Success.replace("{0}", "Database Health")
                    );
                    this.onUpdateState({
                        setOpenModal: false,
                        pageLoader: false,
                        setIsLoad: false,
                    });

                    this.onResetData();
                    this.onGetDatabaseHealthWizardList(statePagination);
                } else {
                    this.apiService.swalServices.Toaster("The column are invalid");
                }
                break;
            case "updateDatabaseHealthWizard":
                if (responseData.hasError === false) {
                    this.apiService.swalServices.Toaster(
                        SuccessMessage.Update_Success.replace("{0}", "Database Health")
                    );
                    this.onUpdateState({
                        setOpenModal: false,
                        pageLoader: false,
                        setIsLoad: false,
                    });
                    this.onResetData();
                    this.onGetDatabaseHealthWizardList(statePagination);
                } else {
                    this.apiService.swalServices.Toaster(
                        responseData.message.DefaultMessage
                    );
                }
                break;
            case "getDatabaseHealthWizardList":
                let itemList = responseData;
                if (responseData !== null) {
                    this.onUpdateState({
                        dataList: itemList,
                        setIsLoad: false,
                        TotalCount: responseData.totalCount,
                    });
                }
                break;
            case "getDatabaseHealthWizardOutput":
                let itemListOutput = responseData;
                if (responseData !== null) {
                    this.onUpdateState({
                        dataOutputList: itemListOutput,
                        setIsLoad: false,
                        TotalCount: responseData.totalCount,
                    });
                }
                break;
            case "getDatabaseHealthWizardById":
                let DataById = responseData;
                this.onUpdateState({
                    dbhealth: DataById,
                    setIsLoad: false,
                });
                break;
            case "deleteDatabaseHealthWizard":
                this.apiService.serviceManager.swalServices.Success(
                    SuccessMessage.Delete_Success.replace("{0}", "Database Health")
                );
                this.onUpdateState({
                    setOpenModal: false,
                    pageLoader: true,
                    setIsLoad: false,
                });
                this.onGetDatabaseHealthWizardList(statePagination);
                break;
        }
    };

}