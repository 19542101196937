//Components
import React, { Component } from 'react'
import DeleteButton from '../../partials/actions/DeleteButton';
import Table from '../../components/tables/Table';
//Library
import { Bars } from 'react-loader-spinner';
import moment from 'moment';
//Services
import SiteTrackerIPList from '../../services/axiosServices/apiServices/SiteTrackerIPListServices';
import SwalServices from '../../services/swalServices/SwalServices';

export class SiteVisitor extends Component {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.getSiteTrackerIPListServices = new SiteTrackerIPList();
    this.swalServices = new SwalServices();
    this.state = {
      setSidebarOpen: false,
      setSelectedItems: [],
      setBasicModalOpen: false,
      getSiteTrackerIPList: [],
      searchingText: "",
      totalResultes: '',
      totalCount: [],
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      }
    }
  }

  // Event function 

  handleSelectedItems = (selectedItems) => {
    this.setState({ setSelectedItems: selectedItems })
  };

  onBesicModel = () => {
    this.setState({ setBasicModalOpen: true })
  }

  onBesicModelClose = () => {
    this.setState({ setBasicModalOpen: false })
  }

  handleChangeEvent = (value) => {
    this.setState({ isLoading: true });
    this.setState({ searchingText: value })
    this.handleSearching(value)
    if (value === '') {
      this.setState({ isLoading: false });
      this.getSiteTrackerIPList()
    }
  }

  handleSearching = (searchQuery) => {
    if (searchQuery.trim() !== "") {
      this.GetSearch(searchQuery);
    }
    else if (searchQuery.trim() === "") {
      this.getSiteTrackerIPList(this.state.pagination);
    }
  }

  // API function 

  getSiteTrackerIPList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.getSiteTrackerIPListServices.admin_GetSiteTrackerIPList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        const itemList = response.responseItem.responseContent.itemList
        itemList.forEach(function (i) {
          i.SiteTrackerId = i.SiteTrackerId ? i.SiteTrackerId : "-"
          i.IPAddress = i.IPAddress ? i.IPAddress : "-"
          i.ViewDate = i.ViewDate ? moment(i.ViewDate).format('DD-MM-YYYY') : '-'
        })
        this.setState({ getSiteTrackerIPList: itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      }
      else {
        this.swalServices.Toaster(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  GetSearch = (searching) => {
    let request = {
      pageNo: 1,
      pageSize: 10,
      orderByColumn: "CreatedDate",
      orderFlag: 1,
      searchText: searching
    }
    this.setState({ isLoading: true });
    this.getSiteTrackerIPListServices.admin_GetSiteTrackerIPList(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getSiteTrackerIPList: response.responseItem.responseContent.itemList });
      }
      else {
        this.swalServices.Toaster(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  // Other function 

  componentDidMount() {
    this.getSiteTrackerIPList();
  }
  actions = (element, index, value) => {
    return (element !== "Actions") ? null
      : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <i className="fas fa-pencil  cursor-pointer ..."></i>
        <i className="fas fa-trash pl-3  cursor-pointer ..." ></i>
      </td>
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getSiteTrackerIPList()
  }

  render() {
    return (
      <div className="flex h-screen overflow-hidden  bg-gray-100 min-h-screen">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">SiteVisitor</h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                  <DeleteButton selectedItems={this.state.setSelectedItems} />
                  <div className="relative">
                    <label htmlFor="action-search" className="sr-only">Search</label>
                    <input id="action-search" className="form-input pl-9 focus:border-slate-300" type="text" placeholder="Search..."
                      value={this.state.searchingText}
                      onChange={(e) => this.handleChangeEvent(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && this.handleSearching(this.state.searchingText)}
                    />
                    <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search"  >
                      <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                      </svg>
                    </button>
                  </div>
                  <button className="btn bg-[#00769f] hover:bg-[#00769f] text-white" onClick={(e) => { e.stopPropagation(); this.handleSearching(this.state.searchingText) }}>
                    <span className="hidden xs:block ml-2">Search</span>
                  </button>
                </div>
              </div>
              {
                (this.state.isLoading) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.isLoading}
                  />
                  :
                  <Table
                    columns={[{ name: 'SiteTrackerId', title: 'SiteTrackerId' }, { name: 'IPAddress', title: 'IPAddress' }, { name: 'ViewDate', title: 'ViewDate' }]}
                    rows={this.state.getSiteTrackerIPList}
                    sortingColumns={["SiteTrackerId", "IPAddress", "ViewDate"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: 'Actions',
                        renderTableData: this.actions.bind(this)
                      },
                    ]}
                  />
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default SiteVisitor
